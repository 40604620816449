import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './BetContainer.scss';
import SportsBlock from "../../components/SportsBlock/SportsBlock";
import Viewport from "../../components/Viewport/Viewport";
import InstantBlock from "../../instants/intsantContainer/instantContainer";
import {SPORTS_DATA} from "../../services/RequestDatas";
import RequestManager from "../../services/RequestManager";

function BetContainer({activeGameID}) {
  const [activeBlockIndex, setActiveBlockIndex] = useState(0);

  useEffect(() => {
    if (activeGameID) {
      const socket = RequestManager.getInstance().webSocket;
      socket.send(JSON.stringify(SPORTS_DATA));
      socket.addEventListener(RequestManager.SPORTS_DATA_EVENT, reloadSportsData, {once: true});
    }
  }, [])

  const reloadSportsData = ({detail}) => {
    SPORTS_DATA.subID = detail.data?.subid;

    setActiveBlockIndex(1);
  };

  const changeActiveBlock = useCallback((index, isTab = false) => {
    const value = isTab && index ? index + 1 : index;
    setActiveBlockIndex(value);
  }, []);

  const getActiveBlock = useMemo(() => {
    switch (activeBlockIndex) {
      case 0:
        return <SportsBlock changeActiveBlockCallback={changeActiveBlock}/>
      case 1:
        return <Viewport changeActiveBlockCallback={changeActiveBlock}/>
      case 2:
        return <InstantBlock instantSports={[]}/>
      default:
    }
  }, [activeBlockIndex])

  return <div className='bet-container'>
    {getActiveBlock}
  </div>
}

export default BetContainer;