import React from 'react';
import './DonatChart.scss';

function DonutChart({value = 0, size = 75, strokewidth = 10}) {
  const halfSize = size * 0.5;
  const radius = halfSize - strokewidth * 0.5;
  const circumference = 2 * Math.PI * radius;
  const strokeValue = (value * circumference) / 100;
  const dashValue = strokeValue + ' ' + circumference;
  const trackStyle = {strokeWidth: strokewidth};
  const indicatorStyle = {strokeWidth: strokewidth, strokeDasharray: dashValue}
  const rotateValue = 'rotate(-90 ' + halfSize + ',' + halfSize + ')';
  return <svg width={size} height={size} className="donut-chart">
    <defs>
      <linearGradient id="gradient" x1="100%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" stopColor="#31866b"/>
        <stop offset="100%" stopColor="#adde98"/>
      </linearGradient>
    </defs>
    <circle r={radius} cx={halfSize} cy={halfSize} transform={rotateValue} style={trackStyle}
            className="donut-chart-track"/>
    <circle cx={halfSize} cy={halfSize} r={radius} stroke="url(#gradient)" transform={rotateValue}
            style={indicatorStyle} className="donut-chart-indicator"/>
    <text className="donut-chart-text" x={halfSize} y={halfSize + 5} style={{textAnchor: 'middle'}}>
      <tspan className="donut-chart-text-val">{isNaN(value) ? 0 : value.toFixed(0)}</tspan>
      <tspan className="donut-chart-text-percent"> %</tspan>
    </text>
  </svg>
}

export default DonutChart;