import React, {useState} from 'react';
import './CashOutPopUp.scss';
import {useTranslation} from "react-i18next";
import {CASH_OUT_DATA} from "../../../services/RequestDatas";
import RequestManager from "../../../services/RequestManager";

function CashOutPopUp({cashOutData, hideCashOutPopUp}) {
  const {t} = useTranslation();
  const currency = cashOutData['currency'];
  const totalCashOut = cashOutData['total_partial_cashout_amount'];
  const socket = RequestManager.getInstance().webSocket;
  const [warning, setWarning] = useState(null);

  const sendCashOutRequest = () => {
    CASH_OUT_DATA.params.bet_id = cashOutData['id'];
    CASH_OUT_DATA.params.price = cashOutData['amount'];

    socket.send(JSON.stringify(CASH_OUT_DATA));
    socket.addEventListener(RequestManager.CASH_OUT_EVENT, response => handleCashOut(response.detail), {once: true});
  }

  const handleCashOut = response => {
    const data = response.data;

    if (data.result === 'Ok') {
      setWarning(null);
      hideCashOutPopUp();
    } else {
      setWarning(data['result_text']);
    }
  }

  return <div className='cash-out-pop-up'>
    <div className='cash-out-pop-up-container'>
      <div className='pop-up-header'>
        <span>{t('cash-out').toUpperCase()}</span>
        <div className='pop-up-close' onClick={hideCashOutPopUp}/>
      </div>
      <div className='container'>
        <p className='total-cash-out'>{totalCashOut + ' ' + currency}</p>
        <div className="cash-out-button" onClick={sendCashOutRequest}>{t('cash-out').toUpperCase()}</div>
        <div className='cash-out-button cancel' onClick={hideCashOutPopUp}>{t('cancel').toUpperCase()}</div>
        {warning ? <p className='cash-out-warning'>{warning}</p> : null}
      </div>
    </div>
  </div>
}

export default CashOutPopUp;