import React, {memo, useContext, useEffect} from 'react';
import './SportsBlock.scss';
import RequestManager from "../../services/RequestManager";
import {AppContext} from "../../App";
import {SPORTS_DATA, UNSUBSCRIBE} from "../../services/RequestDatas";
import Utils from "../../utils/Utils";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

function SportsBlock({changeActiveBlockCallback}) {
  const {t} = useTranslation();
  const history = useHistory();
  const context = useContext(AppContext);
  const sportsData = context.sportsData.get;
  const socket = RequestManager.getInstance().webSocket;
  const thumbnailBaseURL = process.env.PUBLIC_URL + './assets/providers/sports/';
  let allSportsData = [];

  useEffect(() => {
    socket.send(JSON.stringify(SPORTS_DATA));
    socket.addEventListener(RequestManager.SPORTS_DATA_EVENT, response => initSportsData(response.detail));
    socket.addEventListener(RequestManager.AUTO_GAME_DATA, response => handleGamesDataUpdate(response.detail));
    socket.addEventListener(RequestManager.AUTO_REMOVE_GAME_DATA, response => removeGame(response.detail));

    return () => {
      const currentUrl = history.location.search;
      const splitURL = currentUrl.split("game=");

      if (splitURL && splitURL[1] === '7') {
        unsubscribe(SPORTS_DATA.subID);
      }
    }
  }, []);


  // TODO needs to check.
  /*useEffect(() => {
    if (context.activeGameID.get) {
      changeActiveBlockCallback(1);
    }
  }, [context.sportsData.get])*/

  const initSportsData = response => {
    allSportsData = [];

    const data = response.data?.data?.sport;
    const subId = response.data?.subid;

    if (!data || Object.keys(data).length === 0) {
      return;
    }

    SPORTS_DATA.subID = subId;

    for (let key in data) {
      if (data[key]) {
        const sportData = data[key];
        const regionData = data[key]['region'];

        sportData['game'] = regionData ? Object.values(regionData)[0]['game'] : {}
        sportData['region_id'] = regionData ? Object.values(regionData)[0]['id'] : {}
        allSportsData.push(sportData);
      }
    }

    const orderedSportsData = [...allSportsData];
    updateSportsData(orderedSportsData)
  };

  /**
   * Add or Remove the sports data depending on update type.
   * @param subId
   */
  const unsubscribe = subId => {
    UNSUBSCRIBE.params.subid = subId;

    if (subId) {
      socket.send(JSON.stringify(UNSUBSCRIBE));
    }
  }

  const handleGamesDataUpdate = response => {
    const activeGameID = context.activeGameID.get;
    const currentUrl = history.location.search;
    const currentGameID = currentUrl.split("game=")[1];
    let isUpdatingCurrentGame = false;

    if (response['sport']) {
      Object.keys(response['sport']).map(key => {
        const regionData = response['sport'][key]['region'];
        const gameData = regionData[Utils.firstKey(regionData)];
        isUpdatingCurrentGame = +currentGameID === +key;

        updateGameData(parseInt(key), gameData, isUpdatingCurrentGame);
      })
    } else {
      // TODO needs to check work it?
      updateGameData(activeGameID, response);
    }
  }

  const removeGame = response => {
    // TODO NEEDS TO CHECK REMOVE OR NOT.
    if (Utils.firstKey(response['sport'])) {
      response = response['sport'][Utils.firstKey(response['sport'])];
    }

    if (Utils.firstKey(response['region'])) {
      response = response['region'][Utils.firstKey(response['region'])];
    }

    if (Utils.firstKey(response['competition'])) {
      response = response['competition'][Utils.firstKey(response['competition'])];
    }

    const gameID = Utils.firstKey(response['game']);

    allSportsData.map(sportData => {
      if (response && response['game'] && response['game'][gameID]) {
        // IF IS UPDATE TO REMOVE GAME
        delete sportData['game'][gameID];
      }
    })

    updateSportsData(allSportsData);
  }

  const updateGameData = (gameID, gameData, updatingCurrentGame = false) => {
    const updates = [];
    let matchId = null;

    if (gameData) {
      Object.keys(gameData).map(key => {
        if (gameData[key]) updates.push(gameData[key]);
      });
    }

    // CHECK AND SET GAME UPDATE.
    const currentSportsData = [...allSportsData];
    updates.map(update => {
      Object.keys(update).map(matchID => {
        currentSportsData.map(sportData => {

          if (parseInt(sportData['id']) === gameID) {
            if (update[matchID] === null) {
              // IF IS UPDATE TO REMOVE GAME.
              const bookmarks = Utils.readFromStorage('bookmarkedMarkets');
              if (bookmarks && bookmarks[matchID]) {
                delete bookmarks[matchID];
                Utils.writeStorage('bookmarkedMarkets', bookmarks);
              }
              matchId = matchID;
              delete sportData['game'][matchID];
            } else {
              // IF IS UPDATE TO ADD NEW GAME.
              sportData['game'][matchID] = update[Utils.firstKey(update)];
            }
          }
        })
      })
    })

    if (updatingCurrentGame) {
      updateCurrentGameData(currentSportsData);
    }

    updateSportsData(currentSportsData, matchId);
  }

  const updateCurrentGameData = (sportData) => {
    const currentUrl = history.location.search;
    const gameID = currentUrl.split("game=")[1];
    const activeGame = sportData.filter(el => +el.id === +gameID);

    if (activeGame.length) {
      context.currentActiveGameData.set(activeGame);
    }
  }

  const updateSportsData = (updatedSportsData, matchId = null) => {
    const orderedSportsData = [...updatedSportsData];
    orderedSportsData.sort((a, b) => parseFloat(a.order) - parseFloat(b.order));

    if (matchId) {
      context.completedGameId.set(matchId);
    }

    context.sportsData.set(orderedSportsData);
    sendAvailableSportsList(orderedSportsData);
  }

  const sendAvailableSportsList = sportsData => {
    const availableSportsList = sportsData.map(item => {
      return {
        name: t(item['alias']),
        id: t(item['id'])
      }
    });

    window.parent.postMessage({action: "sportsList", data: JSON.stringify(availableSportsList)}, '*');
  }

  const changeActiveGame = (gameIndex) => {
    context.activeGameID.set(gameIndex);
    changeActiveBlockCallback(1);

    window.parent.postMessage({action: 'setLocation', location: {game: gameIndex.toString()}}, '*');
    history.push('?game=' + gameIndex.toString());
  }

  const getSportsCategories = () => {
    if (sportsData.length === 0) {
      return <p className='empty-data'>SERVER DATA IS EMPTY</p>
    }

    return sportsData.map((sportData, index) => {
      return <div className="sport-block" key={index} onClick={() => changeActiveGame(sportData.id, sportData.alias)}>
        <img src={thumbnailBaseURL + sportData.alias + '.jpg?' + Date.now()} alt=""/>
        <div className='sport-block-title'>{t(sportData.alias)}</div>
      </div>
    })
  }

  return <div className="sports-block">
    {getSportsCategories()}
  </div>
}

export default memo(SportsBlock);