import React, {useState} from "react";
import './instantContainer.scss';
import TennisInstantGame from "../Games/Tennis/TennisInstantGame";


function InstantBlock({instantSports}) {
  const baseURL = process.env.PUBLIC_URL + './assets/providers/';
  const imagesBaseURL = process.env.PUBLIC_URL + './assets/instants/backgrounds/';
  const [activeInstantGameData, setActiveInstantGameData] = useState(null);


  const openInstantGame = (id, name) => {
    if (name === ('Instant Tennis') && id === 5) {
      setActiveInstantGameData({name: name, id: id});
    } else {
      window.parent.postMessage({action: 'setLocation', path: `/provider/LPG`, location: {game: id.toString()}}, '*');
    }
  }
  const getProviderInstants = () => {

    return instantSports.map(({name, id, imgUrl}, index) => {
      return <div className="item" key={index} onClick={() => openInstantGame(id, name)}>
        <img src={name !== 'Instant Tennis' ? imgUrl : baseURL + 'instant/' + id + '.png?' + Date.now()} alt={name}/>
        <div className='item-title'>{name}</div>
      </div>
    })

  }

  const gameStyle = activeInstantGameData ? {
    height: '100vh',
    backgroundImage: `url('${imagesBaseURL}tennis.jpg')`
  } : null

  return <div className="instants-block"
              style={gameStyle}>
    {activeInstantGameData ?
      <TennisInstantGame science={'playNow'} setActiveInstantGameData={setActiveInstantGameData}/> :
      getProviderInstants()
    }
  </div>

}

export default InstantBlock;