import React, {useEffect, useRef, useState} from 'react';
import './LeagueMarket.scss';
import MatchDayBlock from "../MatchdayBlock/MatchdayBlock"
import LeagueMenuTab from "./LeagueMenuTab"
import LiveTables from "../../Viewport/LiveTables/LiveTables";
import RequestManager from "../../../services/RequestManager";

function LeagueMarket() {
  const [roundData, setRoundData] = useState([]);
  const [matchesData, setMatchesData] = useState([]);
  const webSocket = RequestManager.getInstance().webSocket;
  const matchesPrevData = useRef(null)

  useEffect(() => {
    webSocket.addEventListener(RequestManager.AUTO_LEAGUE_SCORE_DATA, scoreUpdate);

    return () => {
      webSocket.removeEventListener(RequestManager.AUTO_LEAGUE_SCORE_DATA, scoreUpdate);
    }
  }, [])

  const leagueRoundDataParser = data => {
    setRoundData(data);
  }

  const scoreUpdate = ({detail}) => {
    const stringifyData = JSON.stringify(detail['game']);

    if (!stringifyData.includes('game_number') && !stringifyData.includes('null')) {
      updatedScoresData(detail['game']);
    }
  }

  const getMatchesData = data => {
    matchesPrevData.current = data;
    setMatchesData(data);
  }

  const updatedScoresData = game => {
    const matchesData = matchesPrevData.current;

    Object.keys(game).map(item => {
      matchesData && matchesData.forEach((el) => {
        if (+el['id'] === +item) {
          if (game[item]['homescore']) {
            el['homescore'] = game[item]['homescore'];
          }
          if (game[item]['awayscore']) {
            el['awayscore'] = game[item]['awayscore'];
          }
        }
      })
    })

    if (matchesData.length) {
      setMatchesData([...matchesData]);
    }
  }

  const matchIsAlreadyRunningInfo = () => {
    return <p className='matches-data-is-empty'>
      The data for this match is empty. Please refresh the page.
    </p>
  }

  const getRoundsMenu = () => {
    if (roundData.length === 0) {
      return matchIsAlreadyRunningInfo();
    }

    return <div className='league-menu'>
      {
        roundData.map(({info, game_number, id}, index) => {
          return <LeagueMenuTab info={info} gameNumber={game_number} gameID={id} key={index}/>
        })
      }
    </div>
  }

  return <div className='league-market'>
    <LiveTables matches={matchesData}/>
    <MatchDayBlock showMatchDayMenuCallback={leagueRoundDataParser} setMatchData={getMatchesData}
                   updateScoresData={updatedScoresData}/>
    {getRoundsMenu()}
  </div>
}

export default LeagueMarket;