import moment from 'moment-timezone';
import ThemeManager, { BBIN_THEME_DATA, VBET_THEME_DATA } from '../theme/ThemeManager';

export default class Utils {
  static DEV_MODE = process.env.NODE_ENV === 'development';
  static STAGING_MODE = window.document.domain.indexOf('vs-stage') !== -1;

  static IS_BBIN = document.referrer.indexOf('betcoapps') !== -1;
  static IS_VBET = document.referrer.indexOf('.vbet') !== -1;

  static ANDROID = 'Android';
  static WINDOW_PHONE = 'Windows Phone';
  static IOS = 'iOS';
  static UNKNOWN = 'unknown';
  static TEXT = 'text';
  static NUMBER = 'number';

  static blockInvalidNumbers = e => {
    if ([ 'e', 'E', '+', '-' ].includes(e.key)) {
      e.preventDefault();
    }
  }

  static isNumber(val) {
    return val === '.' || /^[0-9]+$/.test(val);
  }

  static correctBetInputValue(e) {
    let result = e.currentTarget.value.toString();

    if (result && result.length > 1) {
      if (result[0] === '0' && result[1] !== '.') {
        result = result.substr(1);
      } else if (result.includes('.')) {
        const splitValue = result.split('.');

        if (splitValue.length === 2) {
          const beforeDot = result.split('.')[0];
          let afterDot = result.split('.')[1];

          if (afterDot.length > 2) {
            afterDot = afterDot[0] + afterDot[1]
          }
          result = beforeDot + '.' + afterDot;
        }
      }
    }
    return result;
  }

  static validInputValue(value) {
    const inputValue = value.toString()
    const lastChar = inputValue.slice(-1)
    const pointIndex = inputValue.indexOf('.')
    if (lastChar) {
      if (lastChar !== '.') {
        value = pointIndex !== -1 ? inputValue.slice(0, pointIndex + 3) : inputValue
      }
      if (inputValue[0] === '0' && inputValue[1] !== '.') {
        value = inputValue.slice(1)
      }
    } else {
      value = ''
    }

    return value;
  }

  static getOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let type = this.UNKNOWN;

    if (/windows phone/i.test(userAgent)) {
      type = this.WINDOW_PHONE;
    } else if (/android/i.test(userAgent)) {
      type = this.ANDROID;
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      type = this.IOS;
    }

    return type;
  }

  static blockInvalidPastedNumbers = e => {
    const cbValue = e.clipboardData.getData('Text');

    if (cbValue.includes('E') || cbValue.includes('e') || cbValue.includes('+') || cbValue.includes('-')) {
      e.preventDefault();
    }
  }

  static validateNumericInputValue = (e, value) => {
    return ((e.key === '.' && value.includes('.'))
        || (e.key === ',' && value.includes(','))
        || !this.isNumber(e.key))
      && e.preventDefault();
  }

  static getUniqNumber() {
    return (Date.now() * Math.floor(Math.random() * 100)) * Math.random();
  }

  /**
   *
   * @param timestamp
   * @param bySeconds format of result
   * @param hardFormat ignore bySeconds param and hard set param value.
   * @returns {string} time in "10:30" format
   */
  static timestampToLocalTime(timestamp, bySeconds, hardFormat) {
    const offset = new Date(timestamp).getTimezoneOffset();
    const utcString = new Date(timestamp * 1000 - offset).toUTCString();
    const format = hardFormat ? hardFormat : bySeconds ? 'HH:mm:ss' : 'HH:mm';

    return moment.utc(utcString).local().format(format);
  }

  /**
   * @returns object first key
   */
  static firstKey(object) {
    return object ? Object.keys(object)[0] : null;
  }

  /**
   * @param coef coefficient value.
   * @param coefType coefficient type.
   */
  static getFormattedCoef = (coef, coefType) => {
    if (coefType === 'decimal') return coef;

    let formattedCoef = coef;

    switch (coefType) {
      case 'american':
        formattedCoef = (coef >= 2 ? '+' : '-') + Math.floor(coef >= 2 ? (coef - 1) * 100 : -100 / (coef - 1));
        break;
      case 'hongkong':
        formattedCoef = coef - 1;
        break;
      case 'malay':
        formattedCoef = (coef >= 2 ? '' : '-') + (coef >= 2 ? (-1 / (coef - 1)) : (coef - 1));
        break;
      case 'indo':
        formattedCoef = (coef >= 2 ? '' : '-') + (coef >= 2 ? (coef - 1) : 1 / (coef - 1));
        break;
      case 'fractional':
        formattedCoef = this.numberToFraction(formattedCoef - 1);
        return formattedCoef;
      default:
    }

    formattedCoef = formattedCoef ? formattedCoef.toString() : '';
    const cropIndex = formattedCoef.indexOf('.') > 0 ? formattedCoef.indexOf('.') + 3 : 5;

    return formattedCoef.substring(0, cropIndex);
  }

  static getBinomCoef = (m, n) => {
    const mFact = this.factorial(m);
    const nFact = this.factorial(n);
    const minusFact = this.factorial(n - m);
    const temp = mFact * minusFact;

    return nFact / temp;
  }

  static getSubs = (arr, m) => {
    if (m === 0) {
      return [];
    }

    const n = arr.length;
    const a = [];
    const result = [];

    for (let i = 0; i < m; i++) {
      a[i] = i + 1;
    }

    let p = m;
    while (p >= 0) {
      let r = 1;
      for (let j = 0; j < a.length; j++) {
        r = r * arr[a[j] - 1];
      }

      result.push(r);
      p = a[m - 1] === n ? p - 1 : m - 1;

      if (p >= 0) {
        for (let i = m - 1; i >= p; i--) {
          a[i] = a[p] + i - p + 1
        }
      }
    }

    return result;
  }

  static formatPeriodDate = value => {
    const currentTime = new Date();
    const utc_timestamp = Date.UTC(currentTime.getUTCFullYear(), currentTime.getUTCMonth(), currentTime.getUTCDate(),
      currentTime.getUTCHours(), currentTime.getUTCMinutes(), currentTime.getUTCSeconds(), currentTime.getUTCMilliseconds());

    const hoursList = [ 0, 1, 2, 3, 6, 12, 24, 48, 72 ];
    const hour = hoursList[parseInt(value)];
    const oneHourInMilliseconds = 3600000;

    return utc_timestamp - hour * oneHourInMilliseconds
  }


  /**
   * Write data to local storage by parameters.
   * @param key local storage key.
   * @param data local storage data.
   * @param expirationSeconds local storage data expiration time by seconds.
   */
  static writeStorage = (key, data, expirationSeconds) => {
    try {
      localStorage.setItem(key, JSON.stringify(data));

      if (expirationSeconds) {
        const date = new Date();
        const schedule = Math.round((date.setSeconds(date.getSeconds() + expirationSeconds)) / 1000);

        localStorage.setItem(key + '_expiration', JSON.stringify(schedule));
      }
    } catch {
      console.log('ERROR: Write to local storage by key:', key);
    }
  }

  static getStorageData = (key, widthExpiration) => {
    try {
      if (widthExpiration) {
        const currentTime = Math.round(Date.now() / 1000);
        const storedTime = parseInt(localStorage.getItem(key + '_expiration'));

        if (storedTime && currentTime > storedTime) {
          localStorage.setItem(key, null);
          return [];
        }
      }

      return JSON.parse(localStorage.getItem(key));
    } catch {
      console.log('ERROR: Get from local storage by key:', key);
    }
  }

  static removeFromStorage = key => {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.log('>> ERROR: Write to local storage:', e);
    }
  }

  static factorial = n => {
    return n !== 1 ? n * this.factorial(n - 1) : 1;
  }

  static numberToFraction = (amount) => {
    amount = amount.toFixed(2);

    const gcd = function (a, b) {
      if (b < 0.0000001) {
        return a;
      }

      return gcd(b, Math.floor(a % b));
    };

    const len = amount.toString().length - 2;
    let denominator = Math.pow(10, len);
    let numerator = amount * denominator;
    const divisor = gcd(numerator, denominator);

    numerator /= divisor;
    denominator /= divisor;

    amount = Math.floor(numerator) + '/' + Math.floor(denominator);
    return amount;
  };

  static readFromStorage = (name, parse = true) => {
    return localStorage.getItem(name) ? parse ? JSON.parse(localStorage.getItem(name)) : localStorage.getItem(name) : null;
  }

  static shadeColor(color, percent) {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;

    let RR = ((R.toString(16).length === 1) ? '0' + R.toString(16) : R.toString(16));
    let GG = ((G.toString(16).length === 1) ? '0' + G.toString(16) : G.toString(16));
    let BB = ((B.toString(16).length === 1) ? '0' + B.toString(16) : B.toString(16));

    return '#' + RR + GG + BB;
  }

  static getBetSlipItemBgColorByPartnerID = (partnerId) => {
    let color = '#D22782';
    if (partnerId === 18750356) {
      color = '#FFB600';
    } else if (partnerId === 1875968 || partnerId === 1052) {
      color = '#3266E0'
    } else if (partnerId === 1871391) {
      color = '#0C314D'
    }

    return color

  }

  static correctNumericInputValue = event => {
    let inputValue = event.currentTarget.value;
    const regExp = new RegExp(/^\d+$/);

    if (regExp.test(inputValue) === false) {
      event.currentTarget.value = inputValue.slice(0, inputValue.length - 1);
      inputValue = event.currentTarget.value;
    }

    event.currentTarget.value = inputValue;
  }

  static applyApplicationTheme = (themeData, partnerId) => {
    if (Utils.IS_BBIN) {
      ThemeManager.applyTheme(BBIN_THEME_DATA);
    } else if (Utils.IS_VBET) {
      ThemeManager.applyTheme(VBET_THEME_DATA);
    } else if (themeData) {
      ThemeManager.applyTheme({
        mainBG: themeData['background-color'],
        color0: themeData['color0'],
        color1: themeData['color1'],
        color2: themeData['color2'],
        color3: themeData['color3'],
        color4: themeData['color4'],
        color5: themeData['color5'],
        color6: themeData['color6'],
        color7: themeData['color7'],
        color7hover: themeData['color7hover'],
        color7active: themeData['color7active'],
        listInfoItemColor: themeData['listInfoItemColor'],
        listInfoActiveItemColor: themeData['listInfoActiveItemColor'],
        betSlipPopUpBg: this.getBetSlipItemBgColorByPartnerID(partnerId),
        asset1: themeData['line-color'],
        asset2: themeData['place-bet'],
        asset3: themeData['place-bet-light'],
        asset4: themeData['text-buttons-color'],
      });
    }
  }

  static correctLanguageKey(value) {
    const LANGS = {
      'en': 'eng',
      'ru': 'rus',
      'fa': 'fas',
      'hy': 'arm',
      'uk': 'ukr',
      'pt': 'por',
      'es': 'spa',
      'fr': 'fra',
      'tr': 'tur',
      'ka': 'geo',
      'zhh': 'chn',
      'zs': 'zhh',
      'ar': 'arb',
      'de': 'ger',
      'ko': 'kor',
      'pl': 'pol',
      'ja': 'jpn',
      'pb': 'por_2',
    }

    return LANGS[value] ? LANGS[value] : value;
  }
}