import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ProvidersBlock from './components/ProvidersBlock/ProvidersBlock';
import RequestManager from './services/RequestManager';
import {
  AUTHORIZATION_DATA,
  CHANGE_LANG_DATA,
  LOGIN_DATA,
  LOGOUT_DATA,
  PARTNER_CONFIG_DATA,
  PROFILE_DATE,
  RE_CAPTCHA_DATA, RE_CAPTCHA_MAX_DATA,
  SWARM_LOGIN_DATA
} from './services/RequestDatas';
import i18next from 'i18next';
import './i18n';
import './App.scss';
import Utils from './utils/Utils';
import ReactGA from 'react-ga';

export const AppContext = React.createContext({});

function App() {
  const storedBetsData = Utils.getStorageData('betsData', true) || [];
  const storedBookmarks = Utils.getStorageData('bookmarkedMarkets', true) || {};
  const storedUserBetType = Utils.getStorageData('userBetType') || 0;

  const [ URLParams, setURLParams ] = useState();
  const [ activeGameID, setActiveGameID ] = useState(null);
  const [ activeVideoID, setActiveVideoID ] = useState(null);
  const [ bookMarks, setBookMarks ] = useState(storedBookmarks);
  const [ show, setShow ] = useState(false);
  const [ language, setLanguage ] = useState('arm');
  const [ reCaptchaData, setReCaptchaData ] = useState(null);
  const [ partnerConfig, setPartnerConfig ] = useState();
  const [ sportsData, setSportsData ] = useState([]);
  const [ tennisData, setTennisData ] = useState(null);
  const [ userLoggedIn, setUserLoggedIn ] = useState(false);
  const [ userPreferBetType, setUserPreferBetType ] = useState(storedUserBetType);
  const [ applicationReady, setApplicationReady ] = useState(false);
  const [ oddType, setOddType ] = useState(0);
  const [ loggedData, setLoggedData ] = useState('no');
  const [ siteID, setSiteID ] = useState(1);
  const [ currency, setCurrency ] = useState('AMD');
  const [ tennisForceUpdate, setTennisForceUpdate ] = useState(false);
  const [ tennisUpdateState, setTennisUpdateState ] = useState(0);
  const [ instants, setInstants ] = useState([]);
  const [ completedGameId, setCompletedGameId ] = useState(null);
  const [ deletedTennisMarket, setDeletedTennisMarket ] = useState(null);
  const [ playerStatus, setPlayerStatus ] = useState(false);
  const [ currentActiveGameData, setCurrentActiveGameData ] = useState(null);
  const [ balance, setBalance ] = useState(1000);
  const [ availableSportsId, setAvailableSportsId ] = useState([]);
  const [ betsData, setBetsData ] = useState(storedBetsData);
  const [ activeStakeInput, setActiveStakeInput ] = useState(null);

  let userData = useRef(null);
  let socket = useRef();
  let requestStatus = useRef();

  const store = {
    currency: { get: currency, set: setCurrency },
    language: { get: language, set: setLanguage },
    reCaptchaData: { get: reCaptchaData, set: setReCaptchaData },
    activeGameID: { get: activeGameID, set: setActiveGameID },
    tennisData: { get: tennisData, set: setTennisData },
    partnerConfig: { get: partnerConfig, set: setPartnerConfig },
    sportsData: { get: sportsData, set: setSportsData },
    activeVideoID: { get: activeVideoID, set: setActiveVideoID },
    coefType: { get: oddType, set: setOddType },
    betsData: { get: betsData, set: setBetsData },
    balance: { get: balance, set: setBalance },
    bookMarks: { get: bookMarks, set: setBookMarks },
    showBlock: { get: show, set: setShow },
    userLoggedIn: { get: userLoggedIn, set: setUserLoggedIn },
    userPreferBetType: { get: userPreferBetType, set: setUserPreferBetType },
    logging: { get: loggedData, set: setLoggedData },
    tennisForceUpdate: { get: tennisForceUpdate, set: setTennisForceUpdate },
    tennisUpdateState: { get: tennisUpdateState, set: setTennisUpdateState },
    instantsData: { get: instants, set: setInstants },
    completedGameId: { get: completedGameId, set: setCompletedGameId },
    deletedTennisMarket: { get: deletedTennisMarket, set: setDeletedTennisMarket },
    videoPlayerStatus: { get: playerStatus, set: setPlayerStatus },
    currentActiveGameData: { get: currentActiveGameData, set: setCurrentActiveGameData },
    availableSportsId: { get: availableSportsId, set: setAvailableSportsId },
    activeStakeInput: { get: activeStakeInput, set: setActiveStakeInput }
  };

  useEffect(() => {
    if (Utils.DEV_MODE && applicationReady === false) {
      initDevelopmentMode();
    } else {
      if (document.location.href.includes('token')) {
        if (URLParams) {
          initSportsBookAPIMode();
        } else {
          initCasinoAPIMode();
        }
      } else {
        window.addEventListener('message', parsePostMessage);
        window.parent.postMessage({ action: 'appReady' }, '*');
      }
    }

    if (applicationReady) {
      ReactGA.initialize('GTM-MP95GVZ2');
      ReactGA.pageview('Betconstruct Virtual Sport');
    }

    return () => {
      window.removeEventListener('message', parsePostMessage);
    }
  }, [ applicationReady ]);

  useEffect(() => {
    if (reCaptchaData && reCaptchaData.siteKey) {
      initReCaptchaScript();
    }
  }, [ reCaptchaData ]);

  const initDevelopmentMode = () => {
    setUserLoggedIn(true);
    initAuthorization(language, siteID, oddType);
  }

  const initSportsBookAPIMode = () => {
    const userID = URLParams['userid'] || 1;
    const gameID = URLParams['gameId'] || 0;

    if (gameID) {
      openCasinoChosenGame(gameID);
    }

    sendLoginRequest(userID, URLParams['token']);
    changeAppLanguage(Utils.correctLanguageKey(URLParams['language']));

    setSiteID(Number(URLParams['partnerid']));
  }

  const openCasinoChosenGame = gameID => {
    const casinoIDs = {
      'VirtualFootball': 1,
      'PenaltyKicks': 2,
      'KinsleyPark': 3,
      'SantaAnitaPark': 4,
      'Velodrome': 6,
      'WorldLeague': 7,
      'DragRacing': 9,
      'MarbleRace': 12,
      'VirtualTennis': 14
    };

    const chosenGameID = casinoIDs[gameID];
    if (chosenGameID) {
      setActiveGameID(chosenGameID);
    }
  }

  const initCasinoAPIMode = () => {
    const iFrameURL = new URL(document.location.href);
    const params = Object.fromEntries([ ...iFrameURL.searchParams.entries() ]);

    initAuthorization(params['language'], params['partnerid']);
    setURLParams(params);
  }

  const initReCaptchaScript = () => {
    const script = document.createElement('script');
    script.src = 'https://www.recaptcha.net/recaptcha/api.js?render=' + reCaptchaData.siteKey;
    script.addEventListener('load', handleReCaptchScriptLoaded);

    document.body.appendChild(script);
  }

  const handleReCaptchScriptLoaded = () => {
    window.grecaptcha.ready(data => {
      window.grecaptcha.execute(reCaptchaData.siteKey, { action: 'do_bet' }).then(token => RE_CAPTCHA_DATA.params.g_recaptcha_response = token);
      window.grecaptcha.execute(reCaptchaData.siteKey, { action: 'get_max_bet' }).then(token => RE_CAPTCHA_MAX_DATA.params.g_recaptcha_response = token);
    });
  }

  const initAuthorization = (language, siteId, oddType) => {
    const siteID = +siteId;
    socket.current = RequestManager.getInstance(siteID).webSocket;

    socket.current.onopen = () => {
      if (language === 'pt-br') {
        AUTHORIZATION_DATA.params.language = 'por_2';
      } else if (language === 'es-pe') {
        AUTHORIZATION_DATA.params.language = 'spa'
      } else {
        AUTHORIZATION_DATA.params.language = Utils.correctLanguageKey(language);
      }

      AUTHORIZATION_DATA.params.site_id = siteID;
      socket.current.send(JSON.stringify(AUTHORIZATION_DATA));
    }

    socket.current.addEventListener(RequestManager.AUTHORIZATION_EVENT, response => handleAuthorization(response), { once: true });
    socket.current.addEventListener(RequestManager.SOCKET_CLOSED_EVENT, handleSocketClosing, { once: true });

    sendSwarmLanguageChangeRequest(language);

    setLanguage(language);
    setOddType(oddType);
    setSiteID(siteID);
  }

  const sendSwarmLanguageChangeRequest = language => {
    CHANGE_LANG_DATA.params.language = language;

    if (socket && socket.current && socket.current.readyState === 1) {
      socket.current.send(JSON.stringify(CHANGE_LANG_DATA));
      socket.current.addEventListener(RequestManager.CHANGE_LANG_EVENT, () => changeAppLanguage(language), { once: true });
    }
  }

  const changeAppLanguage = lang => {
    setLanguage(lang);

    if (lang === 'pt-br') {
      lang = 'por_2';
    } else if (lang === 'es-pe') {
      lang = 'spa'
    }

    i18next.changeLanguage(lang, error => error ? console.log('something went wrong: ', error) : null).then();
  }

  const handleReCaptchData = data => {
    // ReCaptcha v3.0
    if (data['recaptcha_enabled']) {
      const recaptchaData = {
        version: data['recaptcha_version'],
        siteKey: data['site_key'],
        siteID: data['sid']
      }

      setReCaptchaData(recaptchaData);
    }
  }

  const handleAuthorization = ({ detail }) => {
    getPartnerConfig();
    handleReCaptchData(detail.data);

    if (Utils.DEV_MODE) {
      socket.current.send(JSON.stringify(SWARM_LOGIN_DATA));
      changeAppLanguage(language);
    }
  }

  const handleSocketClosing = () => {
    RequestManager.getInstance().setupWebSocket(siteID);
    setApplicationReady(false);
  }

  const getPartnerConfig = () => {
    socket.current.send(JSON.stringify(PARTNER_CONFIG_DATA));
    socket.current.addEventListener(RequestManager.PARTNER_DATA_EVENT,
      response => parsePartnerConfigData(response.detail.data.data.partner), { once: true });
  }

  const sendProfileRequest = res => {
    if (res.detail && res.detail.data.auth_token) {
      socket.current.send(JSON.stringify(PROFILE_DATE));
      socket.current.addEventListener(RequestManager.PROFILE_DATA_EVENT, response => parseProfileData(response.detail.data.data.profile), { once: true });
      socket.current.addEventListener(RequestManager.AUTO_BALANCE_DATA, response => updateUserBalance(response.detail.profile));
    } else {
      requestStatus.current = 0;
    }
  }

  const parseProfileData = response => {
    requestStatus.current = 0;
    response = response[Utils.firstKey(response)];
    const userCurrency = response['currency'] ? response['currency'] : '';
    const bonus = response['casino_bonus'] ? response['casino_bonus'] : 0;
    const balance = response['casino_balance'] === null ? response['balance'] + bonus : response['casino_balance'] + bonus;

    setBalance(balance);
    setCurrency(userCurrency);
  }

  const updateUserBalance = response => {
    response = response[Utils.firstKey(response)];
    setBalance(response['casino_balance']);
  }

  const sendLoginRequest = (userID, authToken) => {
    if (userData.current && !authToken) {
      userID = userData.current['user_id'];
      authToken = userData.current['auth_token'];
    }

    if (userID && authToken) {
      LOGIN_DATA.params.user_id = userID;
      LOGIN_DATA.params.auth_token = authToken;
      LOGIN_DATA.rid = Utils.getUniqNumber().toString();

      socket.current.send(JSON.stringify(LOGIN_DATA));
      socket.current.addEventListener(RequestManager.LOGIN_DATA_EVENT, response => sendProfileRequest(response), { once: true });

      requestStatus.current = 1;
      setUserLoggedIn(true);
    }
  }

  const sendLogoutRequest = () => {
    Utils.removeFromStorage('betsData');

    setBetsData([]);

    LOGOUT_DATA.rid = Utils.getUniqNumber().toString();
    socket.current.send(JSON.stringify(LOGOUT_DATA));
    requestStatus.current = 0;
  }

  const manageRouteData = routData => {
    if (routData['game']) {
      const possibleGameIDS = [ 1, 2, 3, 4, 6, 7, 9, 12, 14 ];
      const parameterGameID = parseInt(routData['game']);

      if (possibleGameIDS.includes(parameterGameID)) {
        setActiveGameID(parameterGameID);
      }
    }
  }

  const parsePostMessage = ({ data }) => {
    if (data && (data.action || data.data)) {
      const messageType = data.action;
      const messageData = data.data;

      switch (messageType) {
        case 'initialConfig':
          changeAppLanguage(messageData['lang'] ? messageData['lang'] : language);
          initAuthorization(messageData['lang'], messageData['site_id'], messageData['oddType']);

          if (applicationReady === false) {
            socket.current.addEventListener(RequestManager.AUTHORIZATION_EVENT, sendLoginRequest, { once: true });
          }
          break;
        case 'restore_login':
          userData.current = { ...messageData };

          if (applicationReady && requestStatus.current !== 1) {
            sendLoginRequest(messageData['user_id'], messageData['auth_token']);
          }
          break;
        case 'setConfig':
          if (messageData['lang']) {
            sendSwarmLanguageChangeRequest(messageData['lang']);
          }

          if (messageData['oddType']) {
            setOddType(messageData['oddType']);
          }
          break;
        case 'setRouteState':
          manageRouteData(data['data']);
          break;
        case 'logout':
          sendLogoutRequest();
          setUserLoggedIn(false);
          break;
        default:
          //console.log('>> NOT DETECTED POST MESSAGE:', JSON.stringify(messageType));
          break;
      }
    }
  }

  const parsePartnerConfigData = data => {
    data = data[Utils.firstKey(data)];
    const availableSportsId = data['available_sports'].map(item => item.id);
    setAvailableSportsId(availableSportsId);

    if (data['theme_colors']) {
      Utils.applyApplicationTheme(data['theme_colors'], data['partner_id']);
    }

    setPartnerConfig(data);
    setCurrency(data['currency']);
    setApplicationReady(true);
  }

  const getAppContent = useMemo(() => {
    return <ProvidersBlock activeGameID={ activeGameID }/>
  }, [ activeGameID ])

  return applicationReady
    ? <BrowserRouter>
      <AppContext.Provider value={ store }>
        { getAppContent }
      </AppContext.Provider>
    </BrowserRouter>
    : <div className="app-loader"/>
}

export default App;
