import React from 'react';
import './ProvidersBlock.scss';
import BetContainer from "../../containers/BetContainer/BetContainer";

function ProvidersBlock({activeGameID}) {
  
  return <div className="providers-block">
    <div className="content">
      <BetContainer activeGameID={activeGameID}/>
    </div>
  </div>
}

export default ProvidersBlock;