import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import './Viewport.scss';
import {AppContext} from '../../App';
import Utils from "../../utils/Utils";
import Select from "../Native/Select/Select";
import {useTranslation} from "react-i18next";
import VideoPlayer from "./VideoPlayer/VideoPlayer";
import MarketBlock from "../MarketBlock/MarketBlock";
import BetSlipBlock from "../BetSlipBlock/BetSlipBlock";
import BetSlipHint from "../BetSlipBlock/BetSlipHint/BetSlipHint";
import MiniBetSlip from "../BetSlipBlock/MiniBetSlip/MiniBetSlip";
import HistoryPopUp from "../BetSlipBlock/HistoryPopUp/HistoryPopUp"
import CashOutPopUp from "../BetSlipBlock/CashOutPopUp/CashOutPopUp";
import ResultsPopUp from '../BetSlipBlock/ResultsPopUp/ResultsPopUp';

const videoIDS = {
  'VirtualFootballLeague': 16,
  'VirtualFootball': 9,
  'PenaltyKicks': 10,
  'VirtualGreyhounds': 11,
  'VirtualBicycle': 15,
  'VirtualHorseRacing': 13,
  'DragRacing': 21,
  'MarbleRacing': 20,
  'WorldWrestling': 22,
  'VirtualTennis': 23
}

let isAlreadyOpenedMiniBetSlip = false;

function Viewport({changeActiveBlockCallback}) {
  const {t} = useTranslation();
  const context = useContext(AppContext);
  const betsCount = context.betsData.get ? context.betsData.get.length : 0;
  const sportsData = context.sportsData.get;
  const activeGameID = context.activeGameID.get;
  const activeGameData = sportsData.find(a => a.id === activeGameID);
  const activeGame = activeGameData?.alias;
  const iconBaseURL = process.env.PUBLIC_URL + './assets/icons/';

  const storedShowHintValue = Utils.getStorageData('showBetSlipHint') !== false;

  const [betSlipOpened, setBetSlipOpened] = useState(false);
  const [historyOpened, setHistoryOpened] = useState(false);
  const [resultsOpened, setResultsOpened] = useState(false);
  const [cashOutPopUpShown, setCashOutPopUpShown] = useState(false);
  const [cashOutPopUpData, setCashOutPopUpData] = useState({});
  const [showBetSlipHint, setShowBetSlipHint] = useState(storedShowHintValue);
  const [showMiniBetSlip, setShowMiniBetSlip] = useState(false);
  const [animateBetSlip, setAnimateBetSlip] = useState(false);

  useEffect(() => {
    context.currentActiveGameData.set([activeGameData]);
  }, [context.activeGameID.get])

  useEffect(() => {
    const needsToShowMiniBetSlip = !isAlreadyOpenedMiniBetSlip && context.betsData.get.length;
    setShowMiniBetSlip(needsToShowMiniBetSlip);

    if (!context.betsData.get.length) {
      isAlreadyOpenedMiniBetSlip = false
    }

  }, [context.betsData.get])

  useEffect(() => {
    if (showMiniBetSlip) {
      isAlreadyOpenedMiniBetSlip = true
    }
  }, [showMiniBetSlip])

  const showCashOutPopUp = useCallback(data => {
    setCashOutPopUpData(data)
    setCashOutPopUpShown(true);
  }, [cashOutPopUpData])

  const changeActiveGame = (value) => {
    context.activeGameID.set(sportsData[value].id);
    window.parent.postMessage({action: 'setLocation', location: {game: sportsData[value].id.toString()}}, '*');
  }

  const showOrHideBetSlipBlock = (e) => {
    context.showBlock.set(e);
    setBetSlipOpened(e)
  }

  const clearBetsData = () => {
    Utils.writeStorage('betsData', []);
    context.betsData.set([]);
  }

  const closeBetSlip = useCallback(() => {
    showOrHideBetSlipBlock(false)
  }, [betSlipOpened])

  const getBetSlipBlock = () => {
    return betSlipOpened || context.showBlock.get ?
      <BetSlipBlock closeBetSlip={closeBetSlip} clearBetsCallback={clearBetsData}
                    showCashOutPopUp={showCashOutPopUp} forceUpdate={!cashOutPopUpShown}/> : null;
  }

  const getBetsCountBlock = () => {
    const miniBetsSlipHeight = showMiniBetSlip && document.getElementsByClassName("mini-bet-slip")[0]?.clientHeight + 10 + "px";
    const style = {
      "--bets-counter-position": miniBetsSlipHeight || "17vh"
    }

    return <div className={`bets-counter ${animateBetSlip ? "animate" : ""}`} style={style}
                onClick={() => showOrHideBetSlipBlock(true)} onAnimationEnd={() => setAnimateBetSlip(false)}>
      <div className="images">
        <img className='betSlipIcon' src={iconBaseURL + 'betSlipIcon.png'} alt=""/>
      </div>
      {betsCount > 0 ? <p>{betsCount}</p> : null}
    </div>
  }

  const getVideoContainer = () => {
    return betSlipOpened ? null : <div className='video-container'>
      <VideoPlayer VideoIDS={videoIDS}/>
    </div>
  }

  const hideHistoryPopUp = useCallback(() => {
    setHistoryOpened(false)
  }, [historyOpened])

  const hideResultsPopUp = useCallback(() => {
    setResultsOpened(false)
  }, [resultsOpened])

  const hideCashOutPopUp = useCallback(() => {
    setCashOutPopUpShown(false);
  }, [cashOutPopUpShown])

  const handleActiveBlockCallback = useCallback(() => {
    changeActiveBlockCallback(0)
  }, [])

  const handleHistoryOpened = () => {
    setHistoryOpened(historyOpened => !historyOpened)
  }

  const handleResultsOpened = () => {
    setResultsOpened(resultsOpened => !resultsOpened)
  }

  const addBetItemAnimation = () => {
    setAnimateBetSlip(true);
  }

  const getViewPortContent = () => {
    return <div className='viewport-content'>
      {/*<div className="game-title">{t(activeGame)}</div>*/}
      {getVideoContainer()}
      <MarketBlock activeGameID={activeGameID} sportsData={sportsData} addBetItemAnimation={addBetItemAnimation}/>
      {getBetSlipBlock()}
    </div>
  }

  const getContent = () => {
    return <>
      {historyOpened ? <HistoryPopUp hideHistoryPopUp={hideHistoryPopUp}/> : null}
      {resultsOpened ? <ResultsPopUp hideResultsPopUp={hideResultsPopUp} sportsData={sportsData}/> : null}
      {getViewPortContent()}
    </>
  }

  const closeBetSlipHint = value => {
    if (value === true) {
      Utils.writeStorage('showBetSlipHint', false);
    }

    setShowBetSlipHint(false);
  }

  const hideMiniBetSlip = () => {
    setTimeout(() => {
      setShowMiniBetSlip(false)
    }, 1000)
  }

  const getHeader = useMemo(() => {
    const resultsIconURL = process.env.PUBLIC_URL + './assets/icons/results.svg';
    const historyIconURL = process.env.PUBLIC_URL + './assets/icons/history.svg';
    const sportsList = sportsData.map(sportData => t(sportData['alias']));

    return <div className="viewport-header">
      <div className="back-to-sports-block" onClick={ handleActiveBlockCallback }>&#8249;</div>
      <div className="custom-select">
        <Select list={ sportsList } onChangeCallback={ changeActiveGame }
                defaultValue={ sportsList.indexOf(t(activeGame)) }/>
      </div>
      <div className="results-icon" onClick={ handleResultsOpened }>
        <img src={ resultsIconURL } alt=""/>
      </div>
      <div className="history-icon" onClick={ handleHistoryOpened }>
        <img src={ historyIconURL } alt=""/>
      </div>
    </div>
  }, [ activeGame, betsCount ])


  return <div className={ showMiniBetSlip && !betSlipOpened ? 'viewport-container long' : 'viewport-container'}>
    {getHeader}
    {getBetsCountBlock()}
    {cashOutPopUpShown ? <CashOutPopUp hideCashOutPopUp={hideCashOutPopUp} cashOutData={cashOutPopUpData}/> : null}
    {showBetSlipHint && !showMiniBetSlip && !betSlipOpened && !historyOpened? <BetSlipHint closeHint={closeBetSlipHint}/> : null}
    {context.sportsData.get.length ? getContent() : null}
    {showMiniBetSlip && !betSlipOpened && !historyOpened ?
      <MiniBetSlip hideMiniBetSlip={hideMiniBetSlip} addBetItemAnimationEnd={addBetItemAnimation}/> : null}
  </div>
}

export default Viewport;
