import React, {useEffect, useState} from 'react';
import './MyBetElement.scss';
import {useTranslation} from "react-i18next";
import Utils from "../../../../utils/Utils";
import ReactTooltip from 'react-tooltip';
import RequestManager from "../../../../services/RequestManager";


function MyBetElement({betData, showCashOutPopUp}) {
  const {t} = useTranslation();
  const socket = RequestManager.getInstance().webSocket;
  const betTypes = [t('single'), t('multiple'), t('system')];
  const clockIconURL = process.env.PUBLIC_URL + './assets/icons/clock.png';

  const [openedID, setOpenedId] = useState(false);
  const [cashOutAmount, setCashOutAmount] = useState(betData['total_partial_cashout_amount']);

  useEffect(() => {
    socket.addEventListener(RequestManager.AUTO_CASH_OUT_DATA, response => handleCashOutUpdate(response));
  }, [])

  const handleCashOutUpdate = response => {
    const cashOutUpdates = response.detail['cashout'];

    if (cashOutUpdates && Object.keys(cashOutUpdates).length) {
      Object.keys(cashOutUpdates).map(betID => {
        if (parseInt(betID) === betData['id']) {
          setCashOutAmount(Number(cashOutUpdates[betID]['amount']).toFixed(2));
        }
      })
    }
  }

  const getCashOutBlock = () => {
    if (betData['events'].length !== 1 || !betData['total_partial_cashout_amount']) {
      return;
    }

    return <div className="cash-out-block" onClick={() => showCashOutPopUp(betData)}>
      <div className='cash-out-button'>
        <p>{t('cash-out').toUpperCase()}</p>
        <p>{cashOutAmount + ' ' + betData['currency']}</p>
      </div>
    </div>
  }

  const getItem = item => {
    return <div className="bet-type bet-info-item">
      <div className='game-info'>
        <p className='betItem title'>{item['competition_name']}</p>
        <p className="betItem-name">{item['team2'] ? item['team1'] + " VS " + item['team2'] : item['team1']}</p>
        {
          item['type'] !== 1 ? <div className='betItem-multi'>
              <p className='betItem'>{item['market_name']}</p>
            </div> :
            <p className='betItem'>{item['market_name']}</p>
        }
        {
          item['type'] !== 1 ? <div className='betItem-multi'>
              <p className='betItem'>{item['event_name']}</p>
              <div className='coef-value'>{item['selection_price']}</div>
            </div> :
            <p className='betItem'>{item['event_name']}</p>
        }
      </div>
    </div>
  }

  const openCurrentItem = id => {
    const selectedId = id === openedID ? null : id;
    setOpenedId(selectedId);

  }
  const getOpenBets = betData => {
    return <div className={betData['id'] === openedID ? "history-bet-item opened" : "history-bet-item"}
                onClick={() => openCurrentItem(betData['id'])}>
      <div className="data-id bet-info-item">
        <div className="open-bet-title">
          <p className='bet-type-name'>
            {betData['type'] === 3 ? `${betTypes[betData['type'] - 1]}  -   ${betData['system_min_count']} / ${betData['events'].length} ` : betTypes[betData['type'] - 1]}</p>
          <p className="date-and-time">{Utils.timestampToLocalTime(betData['date_time'])}</p>
          <img src={clockIconURL} alt="" className="clock-icon" data-tip={t("unsettled")}/>
          <ReactTooltip/>
        </div>
        {
          betData['id'] === openedID ? betData['events'].map((item, ind) => {
              return <div key={ind + 1}>
                <div className='bet-item-more-info'> {getItem(item)} </div>
                <div className='bet-item-more-info-status'
                     key={ind}> {item['status'] ? item['status'] : item['outcome_name']} </div>
              </div>
            })
            : null
        }

        <div className='win-and-coef-info'>
          <p>{t('stake')}</p>
          <div className="bet-stake">{betData['amount'] + ' ' + betData['currency']}</div>
        </div>
        <div className='odd-info'>
          <p>{t('possible win')}</p>
          <div className="coef-value">{betData['possible_win'].toFixed(3) + ' ' + betData['currency']}</div>
        </div>
        {
          betData['outcome'] === 3 ? <div className='odd-info'>
            <p>{betData['outcome']}</p>
            <div className="coef-value win">{betData['payout'] + ' ' + betData['currency']}</div>
          </div> : null
        }
      </div>
    </div>
  }

  return <>
    {getOpenBets(betData)}
    {getCashOutBlock()}
  </>
}

export default MyBetElement;