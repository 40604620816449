import React, { useContext, useState } from 'react';
import './HistoryBetItem.scss';
import {useTranslation} from "react-i18next";
import Utils from "../../../../utils/Utils";
import { AppContext } from '../../../../App';

function HistoryBetItem({itemData, itemKey, oddType}) {
  const {t} = useTranslation();
  const betTypes = [t('all'), t('single'), t('multiple'), t('system')];
  const type = itemData['type'];
  const outcome = itemData['outcome'];
  const [openedID, setOpenId] = useState('');
  const context = useContext(AppContext);

  const openCurrentItem = id => {
    const selectedId = id === openedID ? null : id;
    setOpenId(selectedId);
  }

  const getItem = item => {
    return <div className="bet-type bet-info-item result">
      <div className='game-info'>
        <p className='betItem title'>{item['competition_name']}</p>
        <p className='betItem title'>{item['game_name']}</p>
        {
          type !== 1 ? <div className='betItem-multi'>
              <p className='betItem'>{item['market_name']}</p>
              <div className={`coef-value ${getOutCome(item['outcome'])}`}>{t(getOutCome(item['outcome']))}</div>
            </div> :
            <p className='betItem'>{item['market_name']}</p>
        }
        {
          type !== 1 ? <div className='betItem-multi'>
              <p className='betItem'>{item['event_name']}</p>
              <div className='coef-value'>{Utils.getFormattedCoef(item['selection_price'], context.coefType.get) }</div>
            </div> :
            <p className='betItem'>{item['event_name']}</p>
        }
      </div>
      <div className='game-info'>
        <p className='betItem title'>Result</p>
        <p className='betItem score'>{item['game_info']}</p>
      </div>
    </div>
  }

  const getOutCome = value => {
    const outComeStatus = {
      0: 'accepted',
      1: 'lost',
      2: 'return',
      3: 'win',
      5: 'cashouted',
      6: 'winReturn',
      7: 'loseReturn'
    };

    return outComeStatus[value] ?? 'accepted';
  }

  return <div className='history-bet-item-container' onClick={() => openCurrentItem(itemKey)}>
    <div className={itemKey === openedID ? "history-bet-item opened" : "history-bet-item"}>
      <div className="data-id bet-info-item">
        <div className="bet-type bet-info-item">
          <div className='row'>
            <p className='bet-type-name'>{betTypes[itemData['type']]}</p>
            <p className={`bet-result-info ${getOutCome(outcome)}`}>
              {t(getOutCome(itemData['outcome']))} </p>
          </div>
          <div className='row'>
            <p className="id">ID: {itemData['id']}</p>
            <p className="date-and-time">{Utils.timestampToLocalTime(itemData['date_time'], false, 'YYYY-MM-DD HH:mm')}</p>
          </div>
          <div className='row'>
            <p className="id">{ t('match') }</p>
            <p className="date-and-time">ID: { itemData?.events[0]?.game_id }</p>
          </div>
        </div>
        {
          itemKey === openedID ? itemData['events'].map((item, ind) => {
              return <div className='bet-item-more-info' key={ind}> {getItem(item)} </div>
            })
            : null
        }
        <div className='win-and-coef-info'>
          <p>{t('stake')}</p>
          <div className="bet-stake">{itemData['amount'] + ' ' + itemData['currency']}</div>
        </div>
        {
          itemData['cash_out'] ? <div className='win-and-coef-info'>
            <p>{t('cash-out')}</p>
            <div className="bet-stake win">{itemData['cash_out'] + ' ' + itemData['currency']}</div>
          </div> : null
        }
        <div className='odd-info'>
          <p>{t('odds')}</p>
          <div className="coef-value">{Utils.getFormattedCoef(itemData['k'], oddType)}</div>
        </div>
        {
          itemData['outcome'] === 3 ? <div className='odd-info'>
            <p>{t(getOutCome(itemData['outcome']))}</p>
            <div className="coef-value win">{itemData['payout'] + ' ' + itemData['currency']}</div>
          </div> : null
        }
      </div>
    </div>
  </div>
}

export default HistoryBetItem;
