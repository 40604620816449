import React, {useContext, useEffect, useState} from 'react';
import './ResultsPopUp.scss';
import { REGION_DATA, GAME_RESULTS_DATA, REGION_RID, GAME_MATCH_ID_RESULT } from '../../../services/RequestDatas';
import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker";
import RequestManager from "../../../services/RequestManager";
import 'react-datepicker/dist/react-datepicker.css';
import Select from "../../Native/Select/Select";
import {useTranslation} from "react-i18next";
import Utils from '../../../utils/Utils';
import {AppContext} from "../../../App";
import {lang} from "../../../i18n";
import ResultItem from './ResultItem';

function ResultsPopUp({hideResultsPopUp, sportsData}) {
  const {t} = useTranslation();
  const context = useContext(AppContext);
  const socket = RequestManager.getInstance().webSocket;
  const userLoggedIn = context.userLoggedIn.get;
  const iconBaseURL = process.env.PUBLIC_URL + './assets/icons/';
  const sportsList = sportsData.map(({ name }) => name);

  const [startDate, setStartDate] = useState(() => Utils.formatPeriodDate(4));
  const [endDate, setEndDate] = useState(() => Utils.formatPeriodDate(0));
  const [activeGameID, setActiveGameID] = useState(context.activeGameID.get);
  const [matchIdData, setMatchIdData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [gameResults, setGameResults] = useState([]);
  const [matchId, setMatchId] = useState(null);
  const regionsData = [];

  registerLocale("en-GB", lang[context.language.get]);
  setDefaultLocale("en-GB");

  useEffect(() => {
    getResultsData();
  }, []);

  const initGameResults = ({ detail }) => {
    if (!detail.data.games) {
      return;
    }

    const results = detail.data.games.game;
    setGameResults(results);
    setShowLoader(false);
  };

  const getGameResults = ({ detail }) => {
    const regionData = detail.data.data.region;

    for (let key in regionData) {
      if (
        regionData.hasOwnProperty(key) &&
        Object.keys(regionData[key].competition).length !== 0
      ) {
        regionsData.push(regionData[key]);
      }
    }

    const selectedRegionData = regionsData[0];
    const competitionObjectKey = Object.keys(selectedRegionData.competition)[0];

    let fromDate = parseInt(startDate / 1000);
    let toDate = parseInt(endDate / 1000);
    if (toDate === fromDate) {
      fromDate -= 36000;
    }

    GAME_RESULTS_DATA.params.sport_id = activeGameID;
    GAME_RESULTS_DATA.params.competition_id = selectedRegionData.competition[competitionObjectKey].id;
    GAME_RESULTS_DATA.params.from_date = fromDate;
    GAME_RESULTS_DATA.params.to_date = toDate;

    socket.send(JSON.stringify(GAME_RESULTS_DATA));
    socket.addEventListener(
      RequestManager.GAME_RESULTS_EVENT,
      initGameResults,
      { once: true }
    );
  };

  const initMatchIdGameResults = ({ detail }) => {
    const list = detail.data.lines.line;

    setMatchIdData(list);
    setShowLoader(false);
  };

  const getResultByMatchId = () => {
    GAME_MATCH_ID_RESULT.params.game_id = Number(matchId);
    GAME_MATCH_ID_RESULT.rid = Utils.getUniqNumber().toString()

    socket.send(JSON.stringify(GAME_MATCH_ID_RESULT));
    socket.addEventListener(
      RequestManager.GAME_MATCH_ID_RESULTS_EVENT,
      initMatchIdGameResults,
      { once: true }
    );
  };

  const getRegionData = () => {
    REGION_DATA.params.where.sport.id = activeGameID;
    REGION_DATA.rid = REGION_RID;

    socket.send(JSON.stringify(REGION_DATA));
    socket.addEventListener(RequestManager.REGION_DATA_EVENT, getGameResults, {
      once: true,
    });
  };

  const getResultsData = () => {
    setShowLoader(showLoader => !showLoader);
    setMatchIdData([]);
    setGameResults([]);

    if (matchId) {
      getResultByMatchId();
    } else {
      getRegionData();
    }
  }

  const changeResultsDate = (date, dateType) => {
    const selectedDateTimeStamp = date.valueOf();

    if (dateType === 'start') {
      setStartDate(selectedDateTimeStamp);
    } else if (dateType === 'end') {
      setEndDate(selectedDateTimeStamp);
    }
  }

  const getBetsList = () => {
    if (!showLoader) {
      if (!gameResults?.length && !matchIdData?.length) {
        return null;
      }

      return <div className='bets-list-container'>
        <div className='bets-list'>
          { gameResults.length ?
            gameResults.map((itemData) => {
              return <ResultItem data={itemData}/>
            }) : matchIdData?.map((itemData) => {
              return <ResultItem data={itemData} isMatchResult={true}/>
            })
          }
        </div>
      </div>
    }
  }

  const preloader = () => !showLoader ? null : <div className='backGround'>
    <div className='video-preloader'/>
  </div>

  const getResultsHeader = () => {
    return <div className="history-block-header">
      <p>{t("results")}</p>
      <div className="close-history-block" onClick={() => hideResultsPopUp()}>&#x2715;</div>
    </div>
  }

  const handleCalendarStatus = (isOpen, name) => {
    const inp = document.getElementsByClassName(`calendar-input ${name}`)[0];

    inp.blur();
    if (inp?.style) {
      inp.style.pointerEvents = isOpen ? 'none' : 'unset';
    }
  }

  const changeGame = (selectedValue) => {
    setActiveGameID(sportsData[selectedValue].id);
  };

  const getResultsContent = () => {
    return <div className='history-block-content'>
      <div className="history-filters">
        <div className="history-filter filter-bet-period">
          <p>{ t('sport') }</p>
          <div className="custom-select">
            <Select list={ sportsList } onChangeCallback={ changeGame } matchId={ matchId }/>
          </div>
          <p>{`${t('match')} (ID)`}</p>
          <input
            type="text"
            className="matchId-input"
            placeholder='ID'
            onChange={ e => {
              Utils.correctNumericInputValue(e);
              setMatchId(e.target.value)
            } }
            onKeyDown={ e => Utils.blockInvalidNumbers(e, true) }
            onPaste={ e => Utils.blockInvalidPastedNumbers(e, true) }
          />
        </div>
        { !matchId ?
          <div className="history-filter filter-bet-range">
            <p>{ t('range') }</p>
            <div className="filter-calendars">
              <div className="search-calendar">
                <DatePicker className="calendar-input start" selected={ startDate } maxDate={ endDate }
                            onCalendarOpen={ () => handleCalendarStatus(true, 'start')}
                            onCalendarClose={() => handleCalendarStatus(false, 'start')}
                            timeFormat="HH:mm" onChange={date => changeResultsDate(date, 'start')}
                />
              </div>
              <img className="calendar-icon" src={iconBaseURL + 'calendar.svg'} alt=''/>
            </div>
            <div className='filter-calendars'>
              <div className="search-calendar">
                <DatePicker className='calendar-input end' selected={endDate} minDate={startDate}
                            onCalendarOpen={() => handleCalendarStatus(true, 'end')}
                            onCalendarClose={() => handleCalendarStatus(false, 'end')}
                            timeFormat="HH:mm" onChange={date => changeResultsDate(date, 'end')}
                />
              </div>
              <img className="calendar-icon" src={iconBaseURL + 'calendar.svg'} alt=''/>
            </div>
          </div> : null
        }
        <div className="show-button"
             onClick={userLoggedIn ? () => getResultsData() : null}>{t('show').toUpperCase()}</div>
      </div>
      {getBetsList()}
    </div>
  }

  const openSignInPopUp = () => {
    window.parent.postMessage({action: "openSlider", tab: "login"}, '*');
  }

  const openRegistrationPopUp = () => {
    window.parent.postMessage({action: "openSlider", tab: "register"}, '*');
  }

  const getLoginHint = () => {
    if (userLoggedIn) {
      return null;
    }
    const warningURL = process.env.PUBLIC_URL + './assets/icons/warning.png';
    return <div className='login-hint-block'>
      <div className='bet-warning-icon' style={{backgroundImage: `url(${warningURL})`}}/>
      <div className='bet-login-warning'> {t('to place bets')}
        <span className='text-button' onClick={() => openSignInPopUp()}> {t("sign in")}</span>
        <span> {t("or")} </span>
        <span className='text-button' onClick={() => openRegistrationPopUp()}>  {t("register")}</span></div>
    </div>
  }

  return <div className='history-pop-up'>
    {preloader()}
    {getResultsHeader()}
    {getResultsContent()}
    {getLoginHint()}
  </div>
}

export default ResultsPopUp;