import React, {useContext, useEffect, useMemo, useState} from 'react';
import './UpcomingGames.scss';
import {MARKET_DATA, UNSUBSCRIBE} from "../../../services/RequestDatas"
import RequestManager from "../../../services/RequestManager"
import Utils from "../../../utils/Utils"
import {AppContext} from "../../../App"


function UpcomingGames({marketDataParser, updateMarketData}) {
  let className;
  const [activeTimeIndex, setActiveTimeIndex] = useState(0);
  const appContext = useContext(AppContext);
  const activeGameID = appContext.activeGameID.get;
  const webSocket = RequestManager.getInstance().webSocket;


  useEffect(() => {
    return () => {
      unsubscribe(MARKET_DATA.subID);
    }
  }, [activeTimeIndex])

  useEffect(() => {
    setActiveTimeIndex(0);
  }, [appContext.activeGameID.get])

  const memoGamesTimes = useMemo(() => {
    const activeSportData = appContext.sportsData.get.find(data => data.id === activeGameID);
    const gameTimesData = [];
    if (activeSportData) {
      for (let key in activeSportData.game) {
        if (activeSportData.game.hasOwnProperty(key)) {
          const timeData = {};
          timeData.id = key;
          timeData.startTime = activeSportData.game[key] ? activeSportData.game[key]['start_ts'] : 0;
          if (timeData.startTime) {
            gameTimesData.push(timeData);
          }
        }
      }
    }
    return gameTimesData

  }, [activeGameID, appContext.currentActiveGameData.get, appContext.sportsData.get])

  const activeTime = memoGamesTimes[activeTimeIndex] ? memoGamesTimes[activeTimeIndex].startTime : 0;

  useEffect(() => {
    const activeSportData = appContext.sportsData.get.find(data => data.id === activeGameID);

    try {
      if (memoGamesTimes.length) {
        const gameID = memoGamesTimes.find(data => data.startTime === activeTime).id;
        MARKET_DATA.params.where.game.id = parseInt(gameID);
        MARKET_DATA.params.where.sport.id = parseInt(activeSportData.id);
        MARKET_DATA.params.where.region.id = parseInt(activeSportData.region_id);

        webSocket.send(JSON.stringify(MARKET_DATA));
        webSocket.addEventListener(RequestManager.MARKET_DATA_EVENT, initData);
        webSocket.addEventListener(RequestManager.AUTO_MARKET_DATA, updateMarket);
      }
    } catch (e) {
      console.log('>> UPCOMING CASE', e);
    }


  }, [activeTimeIndex, appContext.language.get, appContext.activeVideoID.get, appContext.currentActiveGameData.get]);

  const updateMarket = ({detail}) => {
    updateMarketData(detail, memoGamesTimes[activeTimeIndex]['id'])
  }

  const initData = ({detail}) => {
    const {data: marketData, subid} = detail.data;

    MARKET_DATA.subID = subid;
    marketData['event_time'] = timestampToTime(activeTime);

    let data = marketData['sport'];
    if (Object.keys(data).length) {
      data = data[Utils.firstKey(data)].region;
      data = data[Utils.firstKey(data)].competition;
      const game = data[Utils.firstKey(data)].game;
      marketData['sportName'] = game[Utils.firstKey(game)].team1_name + ' VS ' + game[Utils.firstKey(game)].team2_name;
    }
    marketDataParser(marketData);
  }

  const unsubscribe = (subId) => {
    UNSUBSCRIBE.params.subid = subId;
    if (subId) {
      webSocket.send(JSON.stringify(UNSUBSCRIBE));
    }
  }

  const timestampToTime = timestamp => {
    return Utils.timestampToLocalTime(timestamp);
  }

  return <div className='upcoming-games'>
    <div className='upcoming-games-content'>
      <div className='upcoming-games-times'>
        {
          memoGamesTimes.map(({id, startTime}, index) => {
            if (memoGamesTimes[activeTimeIndex] && memoGamesTimes[activeTimeIndex].startTime && startTime) {
              className = startTime === memoGamesTimes[activeTimeIndex].startTime ? 'game-start-time active' : 'game-start-time';
              return <div className={className} key={index} onClick={() => setActiveTimeIndex(index)}>
                {timestampToTime(startTime)}
              </div>
            }
          })
        }
      </div>
    </div>
  </div>
}

export default UpcomingGames