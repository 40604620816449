import React, { useContext, useEffect, useState } from 'react';
import './BetSlipBlock.scss';
import { AppContext } from '../../App';
import BetItem from './BetItem/BetItem';
import MaxBet from './MaxBet/MaxBet';
import {
  BET_CHECK_DATA,
  CASH_OUT_SUBSCRIBE_DATA,
  OPENED_BETS_DATA,
  PLACE_BETS_DATA,
  UNSUBSCRIBE
} from '../../services/RequestDatas';
import RequestManager from '../../services/RequestManager';
import Select from '../Native/Select/Select';
import { useTranslation } from 'react-i18next';
import Utils from '../../utils/Utils';
import MyBetElement from './OpenBets/MyBetElement/MyBetElement';

function BetSlipBlock({ closeBetSlip, clearBetsCallback, showCashOutPopUp, forceUpdate }) {
  const { t } = useTranslation();

  const betTypeText = [ 'single', 'multiple', 'system' ];

  const SELECT = t('select');
  const SINGLE_BET = t('single');
  const MULTIPLE_BET = t('multiple');
  const SYSTEM_BET = t('system');
  const betTypes = [ SINGLE_BET, MULTIPLE_BET, SYSTEM_BET ];
  const socket = RequestManager.getInstance().webSocket;

  const context = useContext(AppContext);
  const userBetType = +context.userPreferBetType.get;
  const isTaxCase = context.partnerConfig.get['tax_integration_type'] === 1;
  const betsData = context.betsData.get;

  const [ betCoef, setBetCoef ] = useState({ names: [], coef: [], itemPrices: [] });
  const [ selectedOptionIndex, setSelectedOptionIndex ] = useState(0);
  const [ isFailed ] = useState(false);
  const [ betType, setBetType ] = useState(userBetType ? t(betTypeText[userBetType]) : t('single'));
  const [ currentOpt, setCurrentOpt ] = useState(null);
  const userLoggedIn = context.userLoggedIn.get;
  const [ activeTabIndex, setActiveTabIndex ] = useState(0);
  const [ openBets, setOpenBets ] = useState([]);
  const [ betResult, setBetResult ] = useState(null);
  const [ placeProcess, setPlaceProcess ] = useState(false);
  const [ betSetting, setBetSetting ] = useState('');

  useEffect(() => {
    setBetType(userBetType ? t(betTypeText[userBetType]) : t('single'));

    if (+userBetType === 2) {
      updateSystemBetCoef(betsData.length)
    }
  }, [ context.language.get ])

  useEffect(() => {
    const betCount = betsData.length;
    const isBetTypeManuallyChanged = Utils.getStorageData('isBetTypeManuallyChanged');

    if (betCount < 2) {
      changeBetType(0);
      setIsBetTypeManually(false);
      return;
    }

    if (betType === SINGLE_BET && betCount > 1 && !isBetTypeManuallyChanged) {
      changeBetType(1);
    }
  }, [])

  useEffect(() => {
    return () => {
      unsubscribe(BET_CHECK_DATA.subID);
      unsubscribe(CASH_OUT_SUBSCRIBE_DATA.subID);
    }
  }, [])

  useEffect(() => {
    if (betType === SYSTEM_BET) {
      updateSystemBetCoef(betsData.length);
      changeBetCoefOpt('0');
    }
  }, [context.betsData.get])

  useEffect(() => {
    if (userLoggedIn && activeTabIndex === 1) {
      CASH_OUT_SUBSCRIBE_DATA.rid = Utils.getUniqNumber().toString();
      socket.send(JSON.stringify(OPENED_BETS_DATA));
      socket.send(JSON.stringify(CASH_OUT_SUBSCRIBE_DATA));

      socket.addEventListener(RequestManager.OPENED_BETS_EVENT, response => updateOpenedBets(response.detail), { once: true });
      socket.addEventListener(RequestManager.CASH_OUT_SUBSCRIBE_EVENT, data => handleCashOutSubscribe(data.detail), { once: true });
    }

  }, [ activeTabIndex, betType, forceUpdate ])

  useEffect(() => {
    updateMyBets();
  }, [ openBets, context.completedGameId.get, context.deletedTennisMarket.get ])

  const handleCashOutSubscribe = ({ data }) => {
    CASH_OUT_SUBSCRIBE_DATA.subID = data['subid'];
  }

  const updateOpenedBets = ({ data }) => {
    if (data && data.bets) {
      setOpenBets(data.bets);
    }
  }

  const updateMyBets = () => {
    const gameId = context.completedGameId.get;
    const tennisMarket = context.deletedTennisMarket.get;

    for (let i = 0; i < openBets.length; i++) {
      if (openBets[i].events.length) {
        if (openBets[i].type === 2 || openBets[i].type === 3) {
          for (let j = 0; j < openBets[i].events.length; j++) {

            if (openBets[i]['events'][j]['sport_id'] !== 14) {
              if (openBets[i]['events'][j].game_id === +gameId) {
                openBets[i]['events'][j].status = 'Complete';
              }
            } else {
              const eventID = openBets[i]['events'][0]['selection_id'];
              const marketId = openBets[i]['events'][0]['match_display_id'];

              if (tennisMarket && (JSON.stringify(tennisMarket).includes(eventID + '') || JSON.stringify(tennisMarket).includes(marketId + ''))) {
                openBets[i]['events'][j].status = 'Complete';
              }
            }
            const finished = openBets[i].events.filter(el => el.status !== 'Complete' && el['outcome_name'] === 'NotResulted');
            if (!finished.length) {
              const newArr = [ ...openBets ];
              newArr.splice(i, 1);
              setOpenBets([ ...newArr ]);
            }
          }
        }
        if (openBets[i].type === 1) {

          if (openBets[i]['events'][0]['sport_id'] !== 14) {
            if (openBets[i]['events'][0].game_id === +gameId) {
              const newArr = [ ...openBets ];
              newArr.splice(i, 1);
              setOpenBets([ ...newArr ]);
            }
          } else {
            const eventID = openBets[i]['events'][0]['selection_id'];
            const marketId = openBets[i]['events'][0]['match_display_id'];


            if (tennisMarket && (JSON.stringify(tennisMarket).includes(eventID + '') || JSON.stringify(tennisMarket).includes(marketId + ''))) {
              Object.keys(tennisMarket).map(item => {

                if (+item === +marketId && tennisMarket[item] !== undefined && (tennisMarket[item] === null || tennisMarket[item]['event'][eventID] === null)) {
                  const newArr = [ ...openBets ];
                  newArr.splice(i, 1);
                  setOpenBets([ ...newArr ]);
                }
              })
            }
          }
        }
      }
    }
  }

  const unsubscribe = subId => {
    UNSUBSCRIBE.params.subid = subId;
    socket.send(JSON.stringify(UNSUBSCRIBE));
  }

  const getBetResultBlock = () => {
    if (!betResult) {
      return null;
    }

    setTimeout(() => {
      setBetResult(null);
      setPlaceProcess(false);
    }, 4000);

    const className = betResult === t('bet accepted') ? 'place-bet-results accepted' : 'place-bet-results';

    return <div className={ className }>
      { betResult }
    </div>
  }

  const updateSystemBetCoef = n => {
    let itemPrices = [];
    context.betsData.get.map(item => {
      if (item) {
        itemPrices.push(item['price']);
      }
    });

    const options = generateSelectOpt(n);
    setBetCoef((betCoef) => ({ ...betCoef, names: options['betList'], coef: options['coefList'], itemPrices }));
  }

  const removeItem = id => {
    const filteredItems = id !== 'all' ? betsData.filter(item => item.id !== id) : [];
    const items = Utils.readFromStorage('betsData');

    if (items && items.length) {
      if (!filteredItems.length && id === 'all') {
        for (let el of items) {
          unsubscribe(el['subID']);
        }
      } else {
        const item = items.filter(item => item.id === id);
        unsubscribe(item[0]['subID']);
      }
    }

    if (filteredItems.length < 2) {
      changeBetType(0);
      setIsBetTypeManually(false)
    }

    Utils.writeStorage('betsData', filteredItems);
    context.betsData.set(filteredItems);
  }

  const setIsBetTypeManually = (value) => {
    Utils.writeStorage('isBetTypeManuallyChanged', value);
  }

  const changeBetType = type => {
    const betsCount = betsData.length;
    if (type === '2') {
      updateSystemBetCoef(betsCount);
    }

    Utils.writeStorage('userBetType', type);
    context.userPreferBetType.set(type);
    setBetType(t(betTypeText[type]));
  }

  const changeBetCoefOpt = opt => {
    if (opt !== '0') {
      setSelectedOptionIndex(+opt);
    } else {
      setSelectedOptionIndex(0);
    }
    setCurrentOpt(betCoef['coef'][opt]);
  }

  const generateSelectOpt = n => {
    const betList = [ SELECT ];
    const coefList = [ null, ];
    for (let i = 2; i < n; i++) {
      const coef = Utils.getBinomCoef(i, n);
      coefList.push(coef);
      betList.push(`${ i }/${ n } ( ${ coef } opt. )`);
    }
    return { betList, coefList }
  }

  const getOddsBlock = () => {
    if (betType === MULTIPLE_BET && betsData && betsData.length > 1) {
      let totalCoef = 1;
      betsData.map(item => {
        if (item) {
          totalCoef *= item['price'];
        }
      });

      return <div className="bet-odds-block">
        <p>Odds : <b>{ Utils.getFormattedCoef(totalCoef.toFixed(3), context.coefType.get)}</b></p>
      </div>
    }
  }

  const placeBetsCallback = betValue => {
    const betsData = context.betsData.get;
    const betCheckData = PLACE_BETS_DATA;

    if (betType === SINGLE_BET) {
      betsData.map(item => {
        if (item['status'] !== 'failed') {
          betCheckData.params.type = 1;
          betCheckData.params.amount = item.betValue;
          betCheckData.params.sys_bet = 0;
          betCheckData.params.bets = [ { event_id: item.id, price: item.price } ];

          sendRequest(betCheckData);
        }
      });
    } else {
      betCheckData.params.type = betType === MULTIPLE_BET ? 2 : 3;
      betCheckData.params.amount = betValue;
      betCheckData.params.sys_bet = selectedOptionIndex;
      betCheckData.params.bets = betsData.map(item => {
        if (item['status'] !== 'failed') {
          return { event_id: item.id, price: item.price };
        }
      });

      sendRequest(betCheckData);
    }
  }

  const sendRequest = betData => {
    setPlaceProcess(true);
    socket.send(JSON.stringify(betData));
    socket.addEventListener(RequestManager.PLACE_BETS_EVENT, response => handlePlaceBetsResponse(response.detail), { once: true });
  }

  const handlePlaceBetsResponse = ({ data }) => {
    setPlaceProcess(false);
    const successfulBet = data.result && data.result === 'OK';

    if (successfulBet) {
      setBetResult(t('bet accepted'));
    } else {
      setBetResult(t(data.result_text));
    }

    if (clearBetsCallback && successfulBet) {
      clearBetsCallback();
    }
  }

  const getMaxBetBlock = () => {
    let totalCoef = 1;
    const showSystemWarning = betsData.length <= 2;
    const showMultipleWarning = betsData.length === 1;
    const warningBetsCount = t('add more events');

    if (betType !== t('system')) {
      betsData.map(item => {
        if (item) {
          totalCoef *= item['price'];
        }
      });
    }

    if (betType === t('system')) {
      if (showSystemWarning) {
        return <div className="warning-bets-count"><span>{ warningBetsCount }</span></div>
      }

      totalCoef = 0;
      const subs = Utils.getSubs(betCoef['itemPrices'], selectedOptionIndex);

      subs.forEach(el => {
        totalCoef = totalCoef + el;
      })

      totalCoef = +totalCoef.toFixed(2);
    } else if (betType === t('multiple')) {
      if (showMultipleWarning) {
        return <div className="warning-bets-count"><span>{ warningBetsCount }</span></div>
      }
    }

    return <MaxBet totalCoef={ totalCoef } placeBetsCallback={ placeBetsCallback } isFailed={ isFailed }
                   betType={ betType.charAt(0).toUpperCase() + betType.slice(1) }
                   currentCoef={ currentOpt } betTypes={ betTypes }
                   betSetting={ betSetting }
    />
  }

  const updateBetsData = newBetsData => {
    context.betsData.set(newBetsData);
    Utils.writeStorage('betsData', newBetsData);
  }

  const applyUserSettings = setting => {
    setBetSetting(setting);
  }

  const setSingleBet = (value, id) => {
    const newBetsData = [];

    context.betsData.get.map(item => {
      if (item['id'] === id) {
        item['betValue'] = value;
      }

      newBetsData.push(item);
    });

    Utils.writeStorage('betsData', newBetsData);
    context.betsData.set(newBetsData);
  }

  const getBetsListContainer = () => {

    return <div className="bets-list-container">
      <div className="bets-settings">
        <div className="system-select">
          <div className="custom-select">
            <Select list={ betTypes }
                    onChangeCallback={ changeBetType }
                    setIsBetTypeManually={ () => setIsBetTypeManually(true) }
                    defaultValue={ userBetType ? +userBetType : 0 }/>
          </div>
          {
            (userBetType === '2' || betType === t('system')) && betCoef['names'].length && betsData.length >= 3 ?
              <div className="custom-select">
                <Select list={ betCoef['names'] } defaultValue={selectedOptionIndex} onChangeCallback={ changeBetCoefOpt }/>
              </div> : null
          }
        </div>
        <div className="clear-bet-items" onClick={ () => removeItem('all') }>{ t('clear all') }</div>
      </div>
      {
        betsData?.map((data, index) => {
          if (data) {
            return <BetItem itemData={ data } key={ index }
                            setSingleBet={ setSingleBet }
                            removeItem={ removeItem }
                            updateBetsData={ updateBetsData }
                            applyUserSettings={ applyUserSettings }
                            isTaxCase={ isTaxCase }
            />
          }
        })
      }
      { getOddsBlock() }
      { !placeProcess ? getMaxBetBlock() : null }
    </div>
  }

  const getOpenBets = () => {
    return <div className="open-bets-container">
      { !openBets.length ? <span>{ t('noOpenBets') }</span> :
        <>
          { openBets.map((item, index) =>
            <MyBetElement betData={ item } key={ index } showCashOutPopUp={ showCashOutPopUp }/>
          ) }
        </>
      }
    </div>
  }

  const getBetSlipHeader = () => {
    return <div className="bet-slip-header">
      <div className="close-bet-slip" onClick={ closeBetSlip }>&#x2715;</div>
    </div>
  }
  const changeActiveTab = (index) => {
    if (activeTabIndex !== index) {
      setBetType(userBetType ? t(betTypeText[userBetType]) : t('single'));
      setActiveTabIndex(index);
    }
    setBetResult('');

  }
  const getBetTabs = () => {
    let tabClasses = 'bets-tab';
    return <div className="bets-tab-menu">
      <div className="bets-tabs">
        {
          [ t('bet slip'), t('my bets') ].map((name, index) => {
            tabClasses = index === activeTabIndex ? 'bets-tab active-tab' : 'bets-tab';
            return <div className={ tabClasses } key={ index } onClick={ () => changeActiveTab(index) }>
              { name }
            </div>
          })
        }
      </div>
    </div>
  }

  const openSignInPopUp = () => {
    window.parent.postMessage({ action: 'openSlider', tab: 'login' }, '*');
  }

  const openRegistrationPopUp = () => {
    window.parent.postMessage({ action: 'openSlider', tab: 'register' }, '*');
  }

  const getLoginHint = () => {
    if (userLoggedIn) {
      return null;
    }
    const warningURL = process.env.PUBLIC_URL + './assets/icons/warning.png';
    return <div className="login-hint-block">
      <div className="bet-warning-icon" style={ { backgroundImage: `url(${ warningURL })` } }/>
      <div className="bet-login-warning"> { t('to place bets') }
        <span className="text-button" onClick={ () => openSignInPopUp() }> { t('sign in').toUpperCase() }</span>
        <span> { t('or') } </span>
        <span className="text-button" onClick={ () => openRegistrationPopUp() }>  { t('register').toUpperCase() }</span>
      </div>
    </div>
  }

  return <div className="bet-slip-block">
    <div className="bet-slip-block-content">
      <div className="bet-slip-block-content-header">
        { getBetTabs() }
        { getBetSlipHeader() }
      </div>
      { activeTabIndex === 0 ? getBetsListContainer() : getOpenBets() }
      { getBetResultBlock() }
      { getLoginHint() }
      {
        placeProcess ? <div className="place-bet-process">
          <div className="loader"/>
        </div> : null
      }
    </div>
  </div>
}

export default BetSlipBlock;
