import React from 'react';
import { useTranslation } from 'react-i18next';

function ResultItem({ data, isMatchResult = false }) {
  const { t } = useTranslation();
  const formattedData = new Date(data?.date * 1000).toLocaleString('en-GB');

  return isMatchResult ? <div className="result-item match-result">
    <p><span>{ t('event').toUpperCase() }:</span> <span>{ data?.line_name.toUpperCase() }</span></p>
    <p className="score">
      <span>{ t('outcome').toUpperCase() }:</span>
      {data?.events?.event_name[0] && <span>{ data?.events?.event_name[0].toUpperCase() }</span>}
    </p>
  </div> : <div className="result-item">
    <p><span>{ t('event').toUpperCase() }:</span> <span>{ data?.game_name.toUpperCase() }</span></p>
    <p><span>{ t('date').toUpperCase() }:</span> <span>{ formattedData }</span></p>
    <p className="score"><span>{ t('score').toUpperCase() }:</span> <span>{ data?.scores }</span></p>
  </div>
}

export default ResultItem;