import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import './MatchdayBlock.scss';
import {LEAGUE_ROUNDS_DATA, UNSUBSCRIBE} from "../../../services/RequestDatas"
import RequestManager from "../../../services/RequestManager"
import Select from "../../Native/Select/Select"
import {useTranslation} from "react-i18next";
import {AppContext} from "../../../App";

function MatchDayBlock({showMatchDayMenuCallback, setMatchData, updateScoresData}) {
  const {t} = useTranslation();
  const appContext = useContext(AppContext);
  const webSocket = RequestManager.getInstance().webSocket;
  const [roundNumbers, setRoundNumbers] = useState([]);
  const [roundsData, setRoundsData] = useState([]);
  const roundsPrevData = useRef(null);

  const leagueGame = useMemo(() => {
    const currentleagueGame = appContext.sportsData.get.filter(el => el.id === 7);

    if (currentleagueGame) return currentleagueGame[0]
  }, [appContext.sportsData.get]);

  useEffect(() => {
    webSocket.send(JSON.stringify(LEAGUE_ROUNDS_DATA));
    webSocket.addEventListener(RequestManager.LEAGUE_ROUNDS_DATA_EVENT, response => parseRoundsData(response.detail));
    webSocket.addEventListener(RequestManager.AUTO_GAME_DATA, response => leagueUpdate(response.detail));

    return () => {
      if (LEAGUE_ROUNDS_DATA.subID) {
        unsubscribe(LEAGUE_ROUNDS_DATA.subID);
      }
    }
  }, [leagueGame]);

  const leagueUpdate = (response) => {
    const previewsData = roundsPrevData.current;
    const data = response && response['game'];

    if (data) {
      Object.keys(data).map((item) => {
        if (data[item] === null) {
          const index = previewsData.findIndex(el => +el.id === +item);

          if (index !== -1) {
            previewsData.splice(index, 1);
          }
        } else {
          const stringifyItemData = JSON.stringify(data[item]);

          if (!stringifyItemData.includes('game_number')
            && (stringifyItemData.includes('homescore') || stringifyItemData.includes('awayscore'))) {

            updateScoresData({[item]: data[item]});
          } else {
            previewsData.push(data[item]);
          }
        }
      })
      parseRoundsData([...previewsData], true);
    }
  }

  const parseRoundsData = (response, isUpdate = false) => {
    const data = isUpdate ? response : response.data.data.game;
    const roundsDataValues = Object.values(data);

    roundsDataValues.sort((a, b) => a['start_ts'] - b['start_ts']);

    if (!isUpdate) {
      LEAGUE_ROUNDS_DATA.subID = response.data.subid;
    }

    const roundNumbers = [];
    const roundsData = [];
    let matches;

    roundsDataValues.forEach(item => {
      roundsData.push(item);

      if (roundNumbers.indexOf(item.round) === -1) {
        roundNumbers.push(item.round);
      }
    });

    matches = roundsData.filter((el) => +el.round === roundsDataValues[0].round);
    roundsPrevData.current = [...roundsData];

    setRoundNumbers(roundNumbers);
    setRoundsData(roundsData);
    setMatchData(matches);
    showMatchDayMenuCallback(matches);
  }

  const unsubscribe = subId => {
    UNSUBSCRIBE.params.subid = subId;

    if (subId) {
      webSocket.send(JSON.stringify(UNSUBSCRIBE));
    }
  }

  const changeActiveRound = (roundIndex) => {
    const selectedRoundData = [];

    roundsData.forEach(item => {
      if (item.round === roundNumbers[roundIndex]) {
        selectedRoundData.push(item);
      }
    });

    showMatchDayMenuCallback(selectedRoundData);
  }

  return <div className="select-matchday">
    <p>{t('select day')}</p>
    <div className='custom-select matchday-list'>
      <Select list={roundNumbers} onChangeCallback={changeActiveRound}/>
    </div>
  </div>
}

export default MatchDayBlock;