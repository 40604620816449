import React, { memo, useContext, useEffect, useState } from 'react';
import './MarketTabMenu.scss';
import {useTranslation} from "react-i18next";
import {AppContext} from "../../../../App";
import { LOGIN_DATA } from '../../../../services/RequestDatas';


function MarketTabMenu({groupNames, isTennis = false, activeGroupName, changeGroupCallback}) {
  let tabClasses = 'market-tab';
  const {t} = useTranslation();
  const appContext = useContext(AppContext);
  const activeGroupIndex = groupNames.indexOf(activeGroupName);
  const [activeTabIndex, setActiveTabIndex] = useState(activeGroupIndex);

  useEffect(() => {
    if (isTennis && appContext.tennisData.get && appContext.tennisData.get.Type !== 1) {
      calcCount();
    } else if (appContext.tennisData.get && appContext.tennisData.get.Type === 1) {
      appContext.bookMarks.set({});
    }
  }, [appContext.tennisUpdateState.get])

  const changeActiveTab = (index) => {
    setActiveTabIndex(index);
    changeGroupCallback(groupNames[index]);
  }

  const calcCount = () => {
    const userBookmarkedMarkets = appContext.bookMarks.get[LOGIN_DATA.params.user_id]
    const marketTypeList = userBookmarkedMarkets && userBookmarkedMarkets[appContext.activeGameID.get]
      ? userBookmarkedMarkets[appContext.activeGameID.get] : [];
    if (!userBookmarkedMarkets  || !marketTypeList) return '';

    return marketTypeList?.length ? marketTypeList.length : '';
  }

  return <div className='market-tab-menu'>
    <div className='market-tabs'>
      {
        groupNames.map((name, index) => {
          tabClasses = index === activeTabIndex ? 'market-tab active-tab' : 'market-tab';

          if (index === 0 && appContext.userLoggedIn.get) {
            tabClasses += ' market-tab-menu-bookmark';
            return <div className={tabClasses} key={index} onClick={() => changeActiveTab(index)}>
              &#9733;   <span className='bookmarks-count'>{calcCount()}</span>
            </div>;
          } else if(index === 0 && !appContext.userLoggedIn.get){
            return null
          }

          return <div className={tabClasses} key={index} onClick={() => changeActiveTab(index)}>
            {t(name.toLowerCase())}
          </div>
        })
      }
    </div>
  </div>
}

export default memo(MarketTabMenu);