import React, {useEffect, useState} from 'react';
import './PlayerStatistic.scss';
import Select from "../../../Native/Select/Select";
import {Line} from "react-chartjs-2";
import DonutChart from "../DonutChart/DonutChart";

function PlayerStatistic({playerData}) {
  const [matchCount, setMatchCount] = useState(5);
  const [winPercent, setWinPercent] = useState(0);
  const daysList = [5, 10, 20];
  let winsPercent, winsCount;

  useEffect(() => {
    winsCount = playerData['Stat'].slice(0, matchCount).filter(item => item['Result'] === 'W').length;
    winsPercent = winsCount * 100 / playerData['Stat'].slice(0, matchCount).length;
    setWinPercent(winsPercent);
  }, [matchCount, playerData])

  const changePlayerGamesCount = value => {
    setMatchCount(daysList[value]);
  }

  const getGamesChart = () => {
    const pointsData = playerData['Stat'];
    const count = matchCount < pointsData.length ? matchCount : pointsData.length;
    const labels = Array.from(Array(count), (x, i) => i + 1);
    const values = pointsData.map(data => data['Result'] === 'W' ? 2 : 1);
    const colors = pointsData.map(data => data['Result'] === 'W' ? '#651CCD' : '#E33B80');

    const data = {
      labels: labels,
      datasets: [
        {
          fill: false,
          data: values,
          lineTension: 0,
          borderDash: [],
          borderCapStyle: 'butt',
          borderDashOffset: 0,
          borderJoinStyle: 'miter',
          pointRadius: 6,
          pointHitRadius: 5,
          pointBorderWidth: 3,
          pointHoverRadius: 6,
          pointHoverBorderWidth: 4,
          borderColor: '#BCBCBC',
          backgroundColor: colors
        }
      ]
    };

    const options = {
      legend: {
        display: false
      },
      maintainAspectRatio: false,
      scales: {
        yAxes: [{
          display: true,
          ticks: {
            min: 1,
            max: 2,
            stepSize: 1,
            fontColor: '#212431',
            borderColor: '#212431'
          },
          gridLines: {
            borderDash: [7],
            lineWidth: 2,
            color: "#2b2e39",
            drawBorder: false
          }
        }],
        xAxes: [{
          gridLines: {
            display: false
          }
        }]
      },
      tooltips: {
        callbacks: {
          title: () => {
            return null
          },
          label: item => {
            const data = pointsData[item.index];
            return data['HoemTeamName'] + '   ' + data['Score'] + '   ' + data['AwayTeamName'];
          }
        },
        backgroundColor: "#101218",
        borderWidth: 0,
        displayColors: false,
        bodyFontColor: "white"
      }
    }

    return <div className='chart-container'>
      <Line data={data} options={options}/>
    </div>
  }

  const getGamesInfoBlock = () => {
    return <div className="player-games-info statistic-block">
      <div className='custom-select top-menu'>
        <Select list={daysList} onChangeCallback={changePlayerGamesCount}/>
      </div>
      <div className='content'>
        <div className='icons'>
          <div className='stat-icon win'>W</div>
          <div className='stat-icon lost'>L</div>
        </div>
        {getGamesChart()}
      </div>
    </div>
  }

  const getWinsInfoBlock = () => {
    return <div className="player-wins-info statistic-block">
      <div className='title'>Last {matchCount}</div>
      <div className='content'>
        <DonutChart value={winPercent}/>
      </div>
    </div>
  }

  return <div className='player-statistic'>
    {getGamesInfoBlock()}
    {getWinsInfoBlock()}
  </div>
}

export default React.memo(PlayerStatistic);