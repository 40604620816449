const BBIN_THEME_DATA = {
  mainBG: '#000000',

  color0: '#181818',
  color1: '#1f1f1f',
  color2: '#1c1c1c',
  color3: '#262626',
  color4: '#323232',
  color5: '#3b3b3b',
  color6: '#5a5a5a',
  betSlipPopUpBg:'#D22782',
  

  asset1: '#e6524f',
  asset2: '#e6524f',
  asset3: '#ff615e',
  asset4: '#c29440',
}

const VBET_THEME_DATA = {
  mainBG: '#08070f',
  color0: '#1d1326',
  color1: '#1a161d',
  color2: '#1E1727',
  color3: '#241c2f',
  color4: '#30263c',
  color5: '#564b5f',
  color6: '#605a6d',
  color7: '#d4961b',
  color7hover: "#e5a630",
  color7active: "#cf911e",
  listInfoItemColor: "#aa8136",
  listInfoActiveItemColor: "#ffffff",
  betSlipPopUpBg:'#D22782',

  asset1: '#FB2293',
  asset2: '#217456',
  asset3: '#37926f',
  asset4: '#c29440',
}

export default class ThemeManager {

  /**
   * Apply the parsed dynamic theme data.
   */
  static applyTheme(themeData = BBIN_THEME_DATA) {
    document.documentElement.style.setProperty('--main-bg', themeData.mainBG);
    document.documentElement.style.setProperty('--vs-col0', themeData.color0);
    document.documentElement.style.setProperty('--vs-col1', themeData.color1);
    document.documentElement.style.setProperty('--vs-col2', themeData.color2);
    document.documentElement.style.setProperty('--vs-col3', themeData.color3);
    document.documentElement.style.setProperty('--vs-col4', themeData.color4);
    document.documentElement.style.setProperty('--vs-col5', themeData.color5);
    document.documentElement.style.setProperty('--vs-col6', themeData.color6);
    document.documentElement.style.setProperty('--vs-col7', themeData.betSlipPopUpBg);

    document.documentElement.style.setProperty('--vs-asset1', themeData.asset1);
    document.documentElement.style.setProperty('--vs-asset01', themeData.asset01);
    document.documentElement.style.setProperty('--vs-asset2', themeData.asset2);
    document.documentElement.style.setProperty('--vs-asset3', themeData.asset3);
    document.documentElement.style.setProperty('--vs-asset4', themeData.asset4);
  }
}

export {VBET_THEME_DATA, BBIN_THEME_DATA}