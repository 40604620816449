import React, {useContext, useEffect, useState} from 'react';
import './LiveTable.scss'
import {AppContext} from "../../../App";
import RequestManager from "../../../services/RequestManager";
import Utils from "../../../utils/Utils";
import {SUBSCRIBE_TO_SPORT_DATA, UNSUBSCRIBE} from "../../../services/RequestDatas"
import {useTranslation} from "react-i18next";

function LiveTables({matches}) {
  const {t} = useTranslation();
  const webSocket = RequestManager.getInstance().webSocket;
  const appContext = useContext(AppContext);
  const activeGameID = appContext.activeGameID.get;

  const [selectedGameID, setSelectedGameID] = useState(0);
  const [dataLT, setDataLT] = useState([]);
  const [showLiveTable, setShowLiveTable] = useState(false);


  useEffect(() => {
    SUBSCRIBE_TO_SPORT_DATA.params.where.sport.id = activeGameID;
    webSocket.send(JSON.stringify(SUBSCRIBE_TO_SPORT_DATA));

    webSocket.addEventListener(RequestManager.SPORT_DATA_UPDATED_EVENT,
      (response) => initLiveTable(response.detail), {once: true});

    webSocket.addEventListener(RequestManager.AUTO_LEAGUE_TABLE_DATA,
      response => initLiveTable(response.detail, true));

    return () => {
      unsubscribe(SUBSCRIBE_TO_SPORT_DATA.subID);
    }
  }, [])

  const initLiveTable = (response, isUpdate) => {
    if (response.data === undefined && !isUpdate) {
      return;
    }

    if (response.data) {
      SUBSCRIBE_TO_SPORT_DATA.subID = response.data.subid;

      let liveTableData = isUpdate ? response.competition : response.data.data.competition;
      liveTableData = liveTableData[Utils.firstKey(liveTableData)].info;
      liveTableData.sort((a, b) => parseFloat(a['Points']) - parseFloat(b['Points'])).reverse();
      setDataLT(liveTableData);
    }
  }

  const unsubscribe = subId => {
    UNSUBSCRIBE.params.subid = subId
    if (subId) {
      webSocket.send(JSON.stringify(UNSUBSCRIBE));
    }
  }

  const openSelectedGame = (gameData, id) => {
    if (appContext.activeGameID.get === 7) {
      const videoID = gameData['info']['virtual'][3]['video_id'];
      appContext.activeVideoID.set(videoID);
      setSelectedGameID(id);
    }
  }

  const getLiveScores = () => {
    let teamName1;
    let teamName2;
    let isLastItem;
    let itemClassName;

    return <>
      {
        matches.map((gameData, index) => {
          teamName1 = gameData['team1_name'];
          teamName2 = gameData['team2_name'];
          isLastItem = index === matches.length - 1;
          itemClassName = selectedGameID === gameData['id'] ? 'live-score-info active' : 'live-score-info';

          return <div className={isLastItem ? itemClassName + " last-item" : itemClassName}
                      onClick={() => openSelectedGame(gameData, gameData['id'])} key={index}>
            <div className="teams-data">
              <div className='container'>
                <div className="team-data first">
                  1.<p>{teamName1}</p>
                </div>
                <div className="team-data last">
                  2.<p>{teamName2}</p>
                </div>
              </div>
            </div>
            <div className="score-data">
              <div className="score player1">{gameData['homescore']}</div>
              <div className="score player2">{gameData['awayscore']}</div>
            </div>
          </div>
        })
      }
    </>
  }

  const getUpDownArrow = value => {
    return value !== 0 ? <i className={value > 0 ? 'triangle-up' : 'triangle-down'}/> : null;
  }

  const changeTab = () => {
    setShowLiveTable(showLiveTable => !showLiveTable)
  }

  const getLiveTableContent = () => {
    const iconsBaseURL = process.env.PUBLIC_URL + './assets/flags/';

    return <div className='live-table-data'>
      <div className='live-table-data-info title'>
        <p className='points'>{t('position LG')}</p>
        <p className='team-name'>{t('team')}</p>
        <div className="live-table-results title">
          <p className='table-result'>{t('played LG')}</p>
          <p className='table-result'>{t('win LG')}</p>
          <p className='table-result'>{t('draw LG')}</p>
          <p className='table-result'>{t('lose LG')}</p>
          <p className='table-result'>{t('goals for LG')}</p>
          <p className='table-result'>{t('goals against LG')}</p>
          <p className='table-result'>{t('goals diff LG')}</p>
          <p className='table-result'>{t('points LG')}</p>
        </div>
      </div>
      {
        dataLT.map(({TeamName, Played, Points, Win, Draw, Lose, GF, GA, GD, UpDown}, index) => {
          return <div className='live-table-data-info' key={index}>
            <p className='points'>{++index}</p>
            <img className='team-flag' src={iconsBaseURL + TeamName.toLowerCase() + '.png'} alt=''/>
            <p className='team-name'>{t(TeamName)}</p>
            <div className="live-table-results">
              <p className='table-result'>{Played}</p>
              <p className='table-result'>{getUpDownArrow(UpDown)} {Win}</p>
              <p className='table-result'>{Draw}</p>
              <p className='table-result'>{Lose}</p>
              <p className='table-result'> {getUpDownArrow(UpDown)} {GF}</p>
              <p className='table-result'>{GA}</p>
              <p className='table-result'>{GD}</p>
              <p className='table-result'>{Points}</p>
            </div>
          </div>
        })
      }
    </div>
  }

  return <div className="live-tables">
    <div className="live-table-tabs">
      <div className={`live-table-title ${!showLiveTable ? ' active' : ''}`} onClick={changeTab}>
        <div className="live-icon"/>
        {t('matches').toUpperCase()}</div>
      <div className={`live-table-title ${showLiveTable ? ' active' : ''}`} onClick={changeTab}>
        <div className="live-icon"/>
        {t('live table').toUpperCase()}</div>
    </div>

    <div className='live-scores'>
      <div className={showLiveTable ? 'live-table' : 'live-scores-content'}>
        {showLiveTable ? getLiveTableContent() : getLiveScores()}
      </div>
    </div>
  </div>
}

export default LiveTables;

