import React, {useContext, useState} from 'react';
import Utils from "../../../../utils/Utils"
import {AppContext} from "../../../../App";
import './BasicMarketSection.scss';

function BasicMarketSection({marketData, currentItem, marketID, alias, selectedPriceData, selectPriceCallback}) {
  const appContext = useContext(AppContext);
  const [opened, setOpened] = useState(true);

  const arrowClassName = opened ? 'arrow-icon up' : 'arrow-icon down';
  const iconBaseURL = process.env.PUBLIC_URL + './assets/market/';
  const marketListData = currentItem.event;
  //const marketID = index;

  const getFormattedPrice = (price) => {
    return Utils.getFormattedCoef(price, appContext.coefType.get);
  }
  const getMarblePlayerIcon = number => {
    const colors = ['#005efc', '#009600', '#fcb000', '#f10f4c'];
    return <div style={{backgroundColor: colors[number - 1]}} className='marble-icon'/>
  }

  const getSectionContent = () => {
    if (opened === false) {
      return null;
    }

    return Object.keys(marketListData).map((key, index) => {
      const currentListItem = marketListData[key];
      currentListItem['market_id'] = parseInt(marketID);

      const price = getFormattedPrice(currentListItem['price']);
      const isSelectedBefore = appContext.betsData.get.filter((el) => el.id === currentListItem.id)
      const isActiveItem = (selectedPriceData && selectedPriceData.id === currentListItem.id) || isSelectedBefore.length;

      const betType = currentItem.name;
      let className = 'basic-market-list-item list-info-item price';
      className += isActiveItem ? ' active' : '';

      const svgName = index + 1;
      const playerIconURL = iconBaseURL + alias + '/' + svgName + '.svg';
      const name = marketData['info']['virtual'][index]['AnimalName']
        ? marketData['info']['virtual'][index]['AnimalName']
        : marketData['info']['virtual'][index]['PlayerName'];

      return <div className={className} key={index}
                  onClick={() => selectPriceCallback(currentListItem, betType, isActiveItem)}>
        {appContext.activeGameID.get === 12 ? getMarblePlayerIcon(svgName) : <img src={playerIconURL} alt=''/>}

        <p className='player-name'>{name}</p>
        <p>{price}</p>
      </div>;
    })
  }

  return <div className='basic-market-list'>
    <div className='basic-market-list-item list-title' onClick={() => setOpened(!opened)}>
      {currentItem.name}
      <div className={arrowClassName}/>
    </div>
    {
      getSectionContent()
    }
  </div>
}

export default BasicMarketSection;