import React, {useContext, useEffect, useRef, useState} from 'react';
import './VideoPlayer.scss';
import '../Viewport.scss';
import HLS from "hls.js";
import {AppContext} from "../../../App";
import {useTranslation} from "react-i18next";
import {GAME_DATA} from "../../../services/RequestDatas";
import RequestManager from "../../../services/RequestManager";

function VideoPlayer({VideoIDS}) {
  let hlsURLs = [];
  const {t} = useTranslation();
  const context = useContext(AppContext);
  const video = useRef({});
  const socket = RequestManager.getInstance().webSocket;

  const [loaded, setVideoLoaded] = useState(false);
  const [playState, setPlayState] = useState(context.videoPlayerStatus.get);
  const [startTime, setStartTime] = useState(0);
  const [url, setUrl] = useState([]);

  const playIcon = process.env.PUBLIC_URL + `./assets/icons/play.png`;
  const isTennis = context.activeGameID.get === 14;
  const tennisData = context.tennisData.get;
  const imgBaseURL = process.env.PUBLIC_URL + './assets/tennis/';
  const flagBaseURL = process.env.PUBLIC_URL + './assets/flags/';
  const style = playState
    ? {backgroundColor: 'transparent', opacity: 0}
    : {backgroundImage: `url(${playIcon})`, opacity: 1}

  useEffect(() => {
    const videoID = context.activeVideoID.get;
    if (videoID) {
      playCurrentVideo(videoID);
    }

    window.addEventListener("blur", stopVideoPlaying);

    return () => {
      window.removeEventListener("blur", stopVideoPlaying);
    }
  }, [context.activeVideoID.get]);

  useEffect(() => {
    playCurrentVideo();

    return () => {
      context.activeVideoID.set(null)
    }
  }, [context.activeGameID.get, context.tennisForceUpdate.get]);

  const playCurrentVideo = (video_id = null) => {
    const alias = context.sportsData.get.filter((el) => el.id === context.activeGameID.get);
    GAME_DATA.params.video_id = video_id ?? VideoIDS[alias[0]['alias']];

    socket.send(JSON.stringify(GAME_DATA));
    socket.addEventListener(RequestManager.GAME_DATA_EVENT, (response) => videoUrl(response.detail), {once: true});
  }

  useEffect(() => {
    const width = (window.innerWidth > 0) ? window.innerWidth : 320;
    if (video?.current) {
      video.current.style.height = `${(width * 9) / 16}px`;
    }
    const videoURL = url[0];

    const config = {startPosition: startTime};
    const hls = new HLS(config);
    if (HLS.isSupported()) {
      if (videoURL) {
        hls.loadSource(videoURL);
        hls.attachMedia(video.current);

        hls.on(HLS.Events.MANIFEST_PARSED, () => {
          if (playState) {
            video.current.play()
          } else {
            video.current.pause()
          }
        }, {once: true});
      }
    } else {
      video.current.src = videoURL;
      if (playState) {
        video.current.play()
        video.current.currentTime = startTime
      } else {
        video.current.pause()
      }
    }
    return () => hls.removeAllListeners()
  }, [url, startTime])


  const videoUrl = ({data}) => {
    let startTime = 0;
    try {
      if (context.activeGameID.get === 14) {
        data = JSON.parse(data);
        startTime = data['PlayTime'];
        hlsURLs = [data['VideoURL'], data['VideoURL']];
        context.tennisData.set(data)
      } else {
        hlsURLs = JSON.stringify(data).includes(',') ? data.split(',') : [data, data];
      }
    } catch (e) {
      hlsURLs = ['', ''];
    }
    setStartTime(startTime)
    setVideoLoaded(true)
    setUrl(hlsURLs)
  }

  const overlay = () => {
    const videoPlayer = video.current;

    if (videoPlayer) {
      setPlayState(videoPlayer.paused);
      context.videoPlayerStatus.set(videoPlayer.paused);

      if (videoPlayer.paused) {
        const videoID = context.activeVideoID.get
        playCurrentVideo(videoID)
      } else {
        videoPlayer.pause();
      }
    }
  }

  const stopVideoPlaying = () => {
    const videoPlayer = video.current;

    if (videoPlayer && videoPlayer.paused === false) {
      setPlayState(videoPlayer.paused);
      context.videoPlayerStatus.set(videoPlayer.paused);
      videoPlayer.pause();
    }
  }

  const getTennisStartInfo = () => {
    const firstName1 = tennisData['Player1Name'].split(" ")[0];
    const firstName2 = tennisData['Player2Name'].split(" ")[0];

    return <div className='tennis-info'>
      <div className='start-info'>
        <div className='players-block'>
          <div className="p-block left">
            <img src={imgBaseURL + 'left/' + firstName1.toLowerCase() + '_left.png'} alt={''}/>
            <div className='p-name left'>
              <p>{tennisData['Player1Name']}</p>
            </div>
          </div>
          <div className="p-block right">
            <img src={imgBaseURL + 'right/' + firstName2.toLowerCase() + '_right.png'} alt={''}/>
            <div className='p-name right'>
              <p>{tennisData['Player2Name']}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  const getTennisFinalInfo = () => {
    const winIconURL = tennisData['Type'] === 4 ? '' : process.env.PUBLIC_URL + './assets/tennis/win.png';

    return <div className='tennis-info final'>
      <div className='final-info'>
        <div className='match-result'>
          <div className='container'>
            <div className="header">
              <div className='list'>
                {tennisData['SetScores'].length ? tennisData['SetScores'].map((item, index) => {
                  return (<p key={index}
                             className={index === 0 ? 'minWidth' : null}>{index === 0 ? ' ' : index}</p>)
                }) : null
                }
              </div>
            </div>
            <div className="match-result-content">
              <div className='scores-block'>
                <img className='flag'
                     src={flagBaseURL + tennisData['Player1Flag'].toLowerCase() + '.png'} alt=''/>
                <p className='player-name'>{getPlayerShortName(tennisData['Player1Name'])}</p>
                <div className='scores-list first'>
                  {tennisData['SetScores'].length ? tennisData['SetScores'].map((item, index) => {
                    return (<p key={index}
                               className={index === 0 ? 'final-column' : null}>{item['Home']}</p>)
                  }) : null
                  }
                </div>
              </div>
              <div className='line'/>
              <div className='scores-block'>
                <img className='flag'
                     src={flagBaseURL + tennisData['Player2Flag'].toLowerCase() + '.png'} alt=''/>
                <p className='player-name'>{getPlayerShortName(tennisData['Player2Name'])}</p>
                <div className='scores-list'>
                  {tennisData['SetScores'].length ? tennisData['SetScores'].map((item, index) => {
                    return (<p key={index}
                               className={index === 0 ? 'final-column' : null}>{item['Away']}</p>)
                  }) : null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='players'>
          <div className='player1-info'>
            <img className='player-img' alt=''
                 src={imgBaseURL + tennisData['Player1Name'].split(' ')[0].toLowerCase() + '.png'}/>
            <div className='name-block left'>
              {tennisData['Winner'] === 0 ? <img alt='' className='win-icon l' src={winIconURL}/> : null}
              <p>{tennisData['Player1Name']}</p>
              <img className='flag' src={flagBaseURL + tennisData['Player1Flag'].toLowerCase() + '.png'}
                   alt=''/>
            </div>
          </div>
          <div className='player2-info'>
            <img className='player-img' alt=''
                 src={imgBaseURL + tennisData['Player2Name'].split(' ')[0].toLowerCase() + '.png'}/>
            <div className='name-block right'>
              {tennisData['Winner'] === 1 ? <img alt='' className='win-icon r' src={winIconURL}/> : null}
              <img className='flag' src={flagBaseURL + tennisData['Player2Flag'].toLowerCase() + '.png'}
                   alt=''/>
              <p>{tennisData['Player2Name']}</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  }

  const getPlayerShortName = name => {
    const sepName = name.split(" ");
    return sepName[0][0] + '. ' + sepName[1].substring(0, 3);
  }

  const preloader = () => loaded ? null : <div className='video-preloader'/>;

  const getTennisInfo = () => {
    if (tennisData && tennisData['Type'] === 4 && isTennis) {
      return getTennisFinalInfo();
    }
    if (tennisData && tennisData['Type'] === 1 && isTennis) {
      return getTennisStartInfo();
    }
  }

  return <div className='video-item'>
    {preloader()}
    {isTennis && tennisData && (tennisData['Type'] === 1 || tennisData['Type'] === 4) ? getTennisInfo() : null}
    {tennisData && tennisData['Type'] !== 1 && context.tennisUpdateState.get === 13 && isTennis ?
      <div className='place-bet-text'> {t('place your bets').toUpperCase()} </div> : null}
    <div className='overlay' style={style} onClick={overlay}/>
    <video className='video-player' ref={video} playsInline={true} controls={false}
           autoPlay={playState} onBlur={() => video.current.pause()}>
      <source src={url[0]} type="video/m3u8"/>
    </video>
  </div>
}

export default VideoPlayer