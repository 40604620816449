import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {AppContext} from "../../../../App";

function NextPointTimer() {
  const {t} = useTranslation();
  const appContext = useContext(AppContext);
  const tennisData = appContext.tennisData.get;
  const timerDisabled = appContext.tennisUpdateState.get !== 13;
  const [remainingSeconds, setRemainingSeconds] = useState(10);
  let remSecTimer;

  useEffect(() => {
    if (!timerDisabled) {
      remSecTimer = timer();
      return () => clearTimeout(remSecTimer);
    }
  }, [remainingSeconds, appContext.tennisUpdateState.get]);

  useEffect(() => {
    if (!timerDisabled) {
      setRemainingSeconds(10);
    }
  }, [appContext.tennisUpdateState.get]);

  const timer = () => setTimeout(() => {
    if (remainingSeconds) {
      setRemainingSeconds(prev => prev - 1);
    }
  }, 1000);

  const getFormattedRemainingSeconds = () => {
    if (remainingSeconds < 0) {
      setRemainingSeconds(null);
    }

    return remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds;
  }

  const getRemainingTime = () => {
    if (!tennisData || timerDisabled || remainingSeconds === 0) {
      return '-- : --';
    }
    return '00 : ' + getFormattedRemainingSeconds();
  }
  return <div className='next-point-info'>
    <div className='next-timer'>
      <p>{t('next start in').toUpperCase()}</p>
      <div>{getRemainingTime()}</div>
    </div>
  </div>;
}

export default React.memo(NextPointTimer);