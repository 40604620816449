import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import hy from 'date-fns/locale/hy';
import ru from 'date-fns/locale/ru';
import enCA from 'date-fns/locale/en-CA';
import fa from 'date-fns/locale/fa-IR';
import uk from 'date-fns/locale/uk';
import pt from 'date-fns/esm/locale/pt';
import es from 'date-fns/esm/locale/es';
import fr from 'date-fns/esm/locale/fr';
import zh from 'date-fns/esm/locale/zh-CN';
import tr from 'date-fns/esm/locale/tr';
import ka from 'date-fns/esm/locale/ka';
import ar from 'date-fns/esm/locale/ar-DZ';
import de from 'date-fns/esm/locale/de';
import ko from 'date-fns/esm/locale/ko';
import ja from 'date-fns/esm/locale/ja';
import pl from 'date-fns/esm/locale/pl';

/** For Calendar translations **/
export const lang = {
  eng: enCA,
  rus: ru,
  arm: hy,
  fas: fa,
  ukr: uk,
  por_2: pt,
  spa: es,
  fre: fr,
  zhh: zh,
  chn: zh,
  tur: tr,
  geo: ka,
  arb: ar,
  ger: de,
  kor: ko,
  jpn: ja,
  pol: pl
}

// The translations (tip move them in a JSON file and import them).
const resources = {
  eng: {
    translation: {
      'virtualSport': 'Virtual Sports',
      'noOpenBets': 'There is no open bets.',
      'select': 'Select',
      'accepted': 'Accepted',
      'cashouted': 'Cash Outed',
      'winReturn': 'Win Return',
      'loseReturn': 'Lose Return',
      'always-ask': 'Accept changes and place bet',
      'suspend': 'This event suspended.',
      'bet settings title': 'When price changes',
      'accept high odd': 'Accept higher odds',
      'always ask': 'Always ask',
      'any odd': 'Accept any odds',
      'played': 'Played',
      'draw': 'Draw',
      'lose': 'Lose',
      'goals for': 'Goals for',
      'goals against': 'Goals Against',
      'goals diff': 'Goals Difference',
      'position': 'Position',
      'team': 'Team',
      'position LG': 'Po.',
      'played LG': 'P',
      'win LG': 'W',
      'draw LG': 'D',
      'lose LG': 'L',
      'goals for LG': 'GF',
      'goals against LG': 'GA',
      'goals diff LG': 'GD',
      'points LG': 'Pts',
      'virtual': 'Virtual',
      'instant': 'Instant',
      'VirtualTennis': 'Virtual Tennis',
      'VirtualFootball': 'Virtual Football',
      'VirtualHorseRacing': 'Virtual Horse Racing',
      'VirtualBicycle': 'Virtual Cycling',
      'VirtualGreyhounds': 'Virtual Greyhounds',
      'PenaltyKicks': 'Penalty Kicks',
      'VirtualFootballLeague': 'Virtual Football League',
      'DragRacing': 'Drag Racing',
      'MarbleRacing': 'Marble Racing',
      'results': 'Results',
      'additional info': 'Additional bookmarker info if there is some info',
      'upcoming games': 'Upcoming games',
      'player names': 'Player names',
      'match running': 'This Match is already running. You can watch the markets of other matches.',
      'select day': 'Select match day',
      'matches': 'Matches',
      'live table': 'Live Table',
      'all': 'All',
      'match': 'Match',
      'handicaps': 'Handicaps',
      'total': 'Total',
      'half': 'Half',
      'set': 'Set',
      'game': 'Game',
      'point': 'Point',
      'player name': 'Player Name',
      'decimal': 'Decimal',
      'american': 'American',
      'hongkong': 'HongKong',
      'malay': 'Malay',
      'indo': 'Indo',
      'bet slip': 'Bet Slip',
      'place bet': 'Place Bet',
      'insufficient balance': 'Insufficient balance Deposit',
      'amount is less': 'Bet amount is less than min allowed.',
      'my bets': 'My Bets',
      'clear all': 'Clear all',
      'quick bets': 'Quick Bets',
      'superbet': 'Superbet',
      'superbet about': 'SuperBet is a feature that allows users to request placing a bet with a stake amount that exceeds Max Bet Limit. The request may be approved or disapproved by traders.',
      'counter offer': 'Counter offer available',
      'counter offer about': 'Counter Offer is a new option that allows suggesting preferable odds for selected sporting events. Available on odds above 1.10.',
      'to select bet': 'To select a bet, please click on any odd.',
      'view all bets': 'You can view all your bets in',
      'bet history': 'Bet History',
      'calculator': 'System Calculator',
      'total stake': 'Total Stake',
      'combinations': 'Combinations',
      'winnings': 'Winnings',
      'all bets': 'All Bets',
      'open': 'Open',
      'win': 'Win',
      'lost': 'Lost',
      'return': 'Return',
      'bet ID': 'Bet ID',
      'bet type': 'Bet Type',
      'single': 'Single',
      'multiple': 'Multiple',
      'system': 'System',
      'period': 'Period',
      'range': 'Range',
      'show': 'Show',
      'data id': 'Date and ID',
      'stake': 'Stake',
      'odd type': 'Odd Type',
      'odd': 'Odd',
      'odds': 'Odds',
      'outcome': 'Outcome',
      'status': 'Status',
      'history empty': 'History is empty.',
      'hour': 'hour',
      'custom': 'Custom',
      '1h': '1 hour',
      '2h': '2 hours',
      '3h': '3 hours',
      '6h': '6 hours',
      '12h': '12 hours',
      '24h': '24 hours',
      '48h': '48 hours',
      '72h': '72 hours',
      'sport': 'Sport',
      'competition': 'Competition',
      'date': 'Date',
      'event': 'Event',
      'score': 'Score',
      'search': 'Search',
      'kg': 'kg',
      'final': 'Final',
      'max bet case': 'The entered amount is greater than the maximum bet amount.',
      'min bet case': 'The entered amount is less than the minimum bet.',
      'event not available': 'This event is not available.',
      'bet accepted': 'Your bet is accepted.',
      'bet failed': 'Your bet is failed.',
      'deleted events': 'There are deleted events',
      'bet in progress': 'Your bet in progress...',
      'possible win': 'Possible win',
      'unsettled': 'Unsettled',
      'last': 'Last',
      'next start in': 'Next point starts in',
      'to place bets': 'To place your bet, please',
      'sign in': 'sign in',
      'or': 'or',
      'register': 'register',
      'cash-out': 'Cash-out',
      'add more events': 'Add more events to be able to place bet',
      'quick bets about': 'Insert an amount and Click on any odd to place a bet.',
      'not selected': 'Not selected',
      'place your bets': 'Place your bets',
      'id': 'ID:',
      'Germany': 'Germany',
      'Spain': 'Spain',
      'England': 'England',
      'Italy': 'Italy',
      'France': 'France',
      'Belgium': 'Belgium',
      'Argentina': 'Argentina',
      'Brazil': 'Brazil',
      'PartnerNotFound': 'Partner is not registered',
      'BetTypeError': 'Select valid bet type',
      'BetValidationError': 'Occurred technical issue, please try again',
      'PartnerApiClientBalanceError': 'Insufficient balance',
      'BetSelectionChanged': 'Odds have been changed',
      'SelectionNotFound': 'Selection is not correct',
      'BetAmountError': 'The stake amount is out of the allowed range',
      'SelectionSuspended': 'There are deleted events',
      'tax amount': 'Tax amount:',
      'final payout': 'Final payout:',
      'total bet amount': 'Total bet amount:',
      'new feature': 'New feature',
      'find bets': 'Find your bets here',
      'dont show': 'Don\'t show again',
      'keep in betslip': 'Keep in Betslip',
    }
  },
  rus: {
    translation: {
      'virtualSport': 'Виртуальный спорт',
      'noOpenBets': 'Нет открытых ставок.',
      'select': 'Выбрать',
      'accepted': 'Принято',
      'cashouted': 'Обналичено',
      'winReturn': 'Возврат выигрыша',
      'loseReturn': 'Возврат проигрыша',
      'always-ask': 'Принять изменения и сделать ставку',
      'suspend': 'Это событие приостановлено.',
      'bet settings title': 'Когда коэффициент меняется',
      'accept high odd': 'Принять более высокие коэффициенты',
      'always ask': 'Всегда спрашивать',
      'any odd': 'Принять любые коэффициенты',
      'virtual': 'Виртуальные',
      'instant': 'Моментальные',
      'VirtualTennis': 'Виртуальный теннис',
      'VirtualFootball': 'Виртуальный футбол',
      'VirtualHorseRacing': 'Виртуальные скачки',
      'VirtualBicycle': 'Виртуальный велоспорт',
      'VirtualGreyhounds': 'Виртуальный забег собак',
      'PenaltyKicks': 'Одиннадцатиметровые удары',
      'VirtualFootballLeague': 'Виртуальная футбольная лига',
      'DragRacing': 'Дрэг рейсинг',
      'MarbleRacing': 'Marble рейсинг',
      'results': 'Результаты',
      'additional info': 'Дополнительная информация о букмекере, если оно есть',
      'upcoming games': 'Предстоящие игры',
      'player names': 'Имена игроков',
      'match running': 'Этот матч уже проводится. Вы можете посмотреть рынки других матчей.',
      'select day': 'Выберите игровой день',
      'matches': 'Матчи',
      'live table': 'Лайв таблица',
      'all': 'Все',
      'match': 'Матч',
      'handicaps': 'Форы',
      'place bet': 'Сделать ставку',
      'to select bet': 'Чтобы выбрать ставку, нажмите на любой коэффициент.',
      'reset': 'Сброс',
      'insufficient balance': 'Недостаточный баланс Депозит',
      'amount is less': 'Сумма ставки меньше минимально разрешенной.',
      'clear all': 'Очистить все',
      'single': 'Ординар',
      'multiple': 'Экспресс',
      'system': 'Система',
      'data id': 'Дата и ID',
      'stake': 'Сумма ставки',
      'odd type': 'Тип коэффициента',
      'odds': 'Коэффициенты',
      'outcome': 'Исход',
      'status': 'Статус',
      'history empty': 'История пуста.',
      'hour': 'час',
      'custom': 'Пользовательский',
      '1h': '1 час',
      '2h': '2 часа',
      '3h': '3 часа',
      '6h': '6 часов',
      '12h': '12 часов',
      '24h': '24 часа',
      '48h': '48 часов',
      '72h': '72 часа',
      'sport': 'Спорт',
      'competition': 'Соревнование',
      'date': 'Дата',
      'event': 'Событие',
      'score': 'Счет',
      'search': 'Поиск',
      'kg': 'кг',
      'final': 'Финал',
      'max bet case': 'Введенная сумма меньше минимальной ставки.',
      'min bet case': 'Введенная сумма больше максимальной суммы ставки.',
      'event not available': 'Данное событие недоступно.',
      'bet accepted': 'Ваша ставка принята.',
      'bet failed': 'Ваша ставка не принята.',
      'deleted events': 'Есть удаленные события',
      'bet in progress': 'Ваша ставка обрабатывается...',
      'possible win': 'Возможный выигрыш',
      'unsettled': 'Необработанный',
      'last': 'Последний',
      'next start in': 'Следующий пункт начинается в',
      'to place bets': 'Чтобы сделать ставку, пожалуйста',
      'sign in': 'войдите',
      'or': 'или',
      'register': 'зарегистрируйтесь сейчас!',
      'place your bets': 'Делайте ваши ставки',
      'total': 'Тотал',
      'half': 'Тайм',
      'set': 'Сет',
      'game': 'Игра',
      'point': 'Очко',
      'player name': 'Имя игрока',
      'decimal': 'Десятичный',
      'american': 'Американский',
      'hongkong': 'Гонконгский',
      'malay': 'Малайский',
      'indo': 'Индонезийский',
      'bet slip': 'Купон',
      'my bets': 'Мои ставки',
      'quick bets': 'Быстрые ставки',
      'superbet': 'СуперБет',
      'superbet about': 'СуперБет - это функция, которая позволяет пользователям запрашивать размещение ставки с суммой, превышающей максимальный лимит ставок. Запрос может быть одобрен или отклонен трейдерами.',
      'counter offer': 'Встречное предложение',
      'counter offer about': 'Встречное предложение – это новая опция, которая дает возможность предложить предпочитаемые коэффициенты на выбранные спортивные события. Опция доступна на коэффициенты выше 1.10.',
      'view all bets': 'Вы можете посмотреть все свои ставки в',
      'bet history': 'История ставок',
      'calculator': 'Системный калькулятор',
      'total stake': 'Общая ставка',
      'combinations': 'Комбинации',
      'winnings': 'Выигрыши',
      'all bets': 'Все ставки',
      'open': 'Открытые',
      'win': 'Выигрыш',
      'lost': 'Проигрыш',
      'return': 'Возврат',
      'bet ID': 'ID ставки',
      'bet type': 'Тип ставки',
      'period': 'Период',
      'range': 'Диапазон',
      'show': 'Показать',
      'odd': 'Коэффициент',
      'cash-out': 'Кэшаут',
      'add more events': 'Добавьте больше событий, чтобы сделать ставку',
      'quick bets about': 'Введите сумму и нажмите на любой коэффициент, чтобы сделать ставку.',
      'not selected': 'Не выбрано',

      'Germany': 'Германия',
      'Spain': 'Испания',
      'England': 'Англия',
      'Italy': 'Италия',
      'France': 'Франция',
      'Belgium': 'Бельгия',
      'Argentina': 'Аргентина',
      'Brazil': 'Бразилия',

      'PartnerNotFound': 'Партнер не зарегистрирован',
      'BetTypeError': 'Выберите действительный тип ставки',
      'BetValidationError': 'Возникла техническая проблема, пожалуйста, попробуйте еще раз',
      'PartnerApiClientBalanceError': 'Недостаточный баланс',
      'BetSelectionChanged': ' Коэффициенты были изменены',
      'SelectionNotFound': 'Неправильный выбор',
      'BetAmountError': 'Сумма ставки за пределами допустимого диапазона',
      'SelectionSuspended': 'Есть удаленные события',

    }
  },
  arm: {
    translation: {
      'virtualSport': 'Վիրտուալ սպորտ',
      'noOpenBets': 'Բաց խաղադրույքներ չկան',
      'select': 'Ընտրել',
      'accepted': 'Ընդունված',
      'cashouted': 'Կանխիկացված',
      'winReturn': 'Շահում․ վերադարձ',
      'loseReturn': 'Պարտություն․վերադարձ',
      'always-ask': 'Ընդունել փոփոխությունները և կատարել խաղադրույք',
      'suspend': 'Այս իրադարձությունը կասեցված է',
      'bet settings title': 'Երբ գործակիցը փոխվում է',
      'accept high odd': 'Ընդունել ավելի բարձր գործակիցներ',
      'always ask': 'Միշտ հարցնել',
      'any odd': 'Ընդունել ցանկացած գործակից',

      'virtual': 'Վիրտուալ',
      'instant': 'Ակնթարթային',
      'VirtualTennis': 'Վիրտուալ թենիս',
      'VirtualFootball': 'Վիրտուալ ֆուտբոլ',
      'VirtualHorseRacing': 'Վիրտուալ ձիարշավ',
      'VirtualBicycle': 'Վիրտուալ հեծանվասպորտ',
      'VirtualGreyhounds': 'Վիրտուալ շների վազք',
      'PenaltyKicks': '11-մետրանոց հարվածներ',
      'VirtualFootballLeague': 'Վիրտուալ ֆուտբոլային լիգա',
      'DragRacing': 'Դրեգ-ռեյսինգ',
      'MarbleRacing': 'Մարբլ ռեյսինգ',
      'results': 'Արդյունքներ',
      'upcoming games': 'Առաջիկա խաղեր',
      'match running': 'Այս հանդիպումն արդեն ընթանում է։ Դուք կարող եք դիտել այլ հանդիպումների մարկետները։',
      'select day': 'Ընտրել խաղային օրը',
      'all': 'Բոլորը',
      'match': 'Հանդիպում',
      'handicaps': 'Հենդիքեփներ',
      'total': 'Ընդհանուր',
      'half': 'Խաղակես',
      'set': 'Սեթեր',
      'game': 'Գեյմեր',
      'point': 'Միավորներ',
      'player name': 'Խաղացողի անունը',
      'decimal': 'Տասնորդական',
      'american': 'Ամերիկյան',
      'hongkong': 'Հոնկոնգյան',
      'malay': 'Մալայական',
      'indo': 'Ինդոնեզական',
      'bet slip': 'Կտրոն',
      'my bets': 'Խաղադրույքներ',
      'quick bets': 'Արագ խաղադրույքներ',
      'quick bet about': 'Մուտքագրեք գումարը եւ սեղմեք գործակցի վրա՝ խաղադրույք կատարելու համար։',
      'superbet': 'ՍուպերԲեթ',
      'superbet about': 'ՍուպերԲեթ-ը ֆունկցիա է, որը թույլ է տալիս օգտատիրոջը հարցում կատարել խաղադրույքի առավելագույն սահմանը գերազանցող խաղադրույք տեղադրելու վերաբերյալ։ Հարցումը կարող է հաստատվել կամ մերժվել թրեյդերների կողմից։',
      'counter offer': 'Պատասխան առաջարկը հասանելի է',
      'counter offer about': 'Պատասխան առաջարկը նոր ընտրանք է, որը թույլ է տալիս առաջարկել նախընտրելի գործակիցներ ընտրված սպորտային իրադարձությունների համար։ Հասանելի է 1,10-ից բարձր գործակիցների համար։',
      'to select bet': 'Խաղադրույք ընտրելու համար սեղմեք ցանկացած գործակցի վրա։',
      'view all bets': 'Դուք կարող եք տեսնել ձեր բոլոր խաղադրույքները',
      'bet history': 'Խաղադրույքների պատմություն',
      'calculator': 'Համակարգային հաշվիչ',
      'total stake': 'Խաղադրույքների տոտալը',
      'combinations': 'Համադրություններ',
      'winnings': 'Շահում',
      'all bets': 'Բոլոր խաղադրույքները',
      'open': 'Բաց',
      'win': 'Հաղթանակ',
      'lost': 'Պարտություն',
      'return': 'Վերադարձ',
      'bet ID': 'Խաղադրույքի ID',
      'bet type': 'Խաղադրույքի տեսակ',
      'period': 'Ժամանակահատված',
      'range': 'Միջակայք',
      'show': 'Ցուցադրել',
      'player names': 'Խաղացողների անունները',
      'matches': 'Հանդիպումներ',
      'live table': 'Լայվ աղյուսակ',
      'place bet': 'Կատարել Խաղադրույք',
      'insufficient balance': 'Անբավարար հաշվեկշիռ',
      'amount is less': 'Խաղադրույքի գումարը քիչ է սահմանված նվազագույնից:',
      'clear all': 'Ջնջել',
      'single': 'Օրդինար',
      'multiple': 'Էքսպրես',
      'system': 'Համակարգ',
      'data id': 'Ամսաթիվ և ID',
      'stake': 'Խաղագումար',
      'odd type': 'Գործակցի տեսակը',
      'odds': 'Գործակիցներ',
      'odd': 'Գործակից',
      'outcome': 'Ելք',
      'status': 'Կարգավիճակ',
      'history empty': 'Պատմությունը դատարկ է:',
      'hour': 'ժամ',
      'custom': 'Կարգավորել',
      '1h': '1 ժամ',
      '2h': '2 ժամ',
      '3h': '3 ժամ',
      '6h': '6 ժամ',
      '12h': '12 ժամ',
      '24h': '24 ժամ',
      '48h': '48 ժամ',
      '72h': '72 ժամ',
      'sport': 'Սպորտ',
      'competition': 'Մրցույթ',
      'date': 'Ամսաթիվ',
      'event': 'Իրադարձություն',
      'score': 'Հաշիվ',
      'search': 'Որոնում',
      'kg': 'կգ',
      'final': 'Եզրափակիչ',
      'max bet case': 'Մուտքագրված գումարը նվազագույն խաղադրույքից քիչ է:',
      'min bet case': 'Մուտքագրված գումարը գերազանցում է առավելագույն խաղադրույքի չափը:',
      'event not available': 'Այս իրադարձությունը հասանելի չէ:',
      'bet accepted': 'Ձեր խաղադրույքն ընդունված է:',
      'bet failed': 'Ձեր խաղադրույքը ձախողվել է:',
      'deleted events': 'Կան ջնջված իրադարձություններ',
      'bet in progress': 'Ձեր խաղադրույքն ընթացքի մեջ է ...',
      'possible win': 'Հնարավոր շահում',
      'unsettled': 'Չհաշվարկված',
      'last': 'Վերջին',
      'next start in': 'Հաջորդ միավորի խաղարկմանը մնացել է',
      'to place bets': 'Խաղադրույք կատարելու համար, խնդրում ենք',
      'sign in': 'մուտք գործել',
      'or': 'կամ',
      'register': 'գրանցվել հիմա',
      'place your bets': 'կատարեք ձեր խաղադրույքները',
      'cash-out': 'Քեշաութ',
      'add more events': 'Ավելացնել ավելի շատ իրադարձություններ, որոնց վրա կարելի է խաղադրույք կատարել',
      'quick bets about': 'Մուտքագրել գումարը և Սեղմել ցանկացած գործակցի վրա՝ խաղադրույք կատարելու համար',
      'not selected': 'Ընտրված չէ',

      'Germany': 'Գերմանիա',
      'Spain': 'Իսպանիա',
      'England': 'Անգլիա',
      'Italy': 'Իտալիա',
      'France': 'Ֆրանսիա',
      'Belgium': 'Բելգիա',
      'Argentina': 'Արգենտինա',
      'Brazil': 'Բրազիլիա',

      'PartnerNotFound': 'Գործընկերը գրանցված չէ',
      'BetTypeError': 'Ընտրեք վավերական խաղադրույքի տեսակ',
      'BetValidationError': 'Տեխնիկական խնդիր է առաջացել, խնդրում ենք նորից փորձել',
      'PartnerApiClientBalanceError': 'Անբավարար հաշվեկշիռ',
      'BetSelectionChanged': 'Գործակիցները փոխվել են',
      'SelectionNotFound': 'Ընտրանքը սխալ է',
      'BetAmountError': 'Խաղագումարը թույլատրելի միջակայքից դուրս է',
      'SelectionSuspended': 'Ջնջված իրադարձություններ կան',
    }
  },
  fas: {
    translation: {
      'virtualSport': 'ورزش های مجازی',
      'noOpenBets': 'شرط باز وجود ندارد',
      'select': 'انتخاب کنید',
      'accepted': 'پذیرفته شد',
      'cashouted': 'کش اوت شد',
      'winReturn': ' بازگشت مبلغ برد ',
      'loseReturn': ' بازگشت مبلغ از دست رفته',
      'always-ask': ' تغییرات را بپذیرید و شرط بندی کنید ',
      'suspend': ' این رویداد به حالت تعلیق درآمده است .',
      'bet settings title': 'وقتی ضریب تغییر می کند',
      'accept high odd': ' پذیرفتن ضرایب بالاتر',
      'always ask': ' همیشه بپرس ',
      'any odd': ' پذیرفتن هر ضریب',


      'VirtualTennis': 'تنیس زنده',
      'VirtualFootball': 'فوتبال مجازی',
      'VirtualHorseRacing': 'مسابقه اسب دوانی مجازی',
      'VirtualBicycle': 'دوچرخه سواری مجازی',
      'VirtualGreyhounds': 'سگ دوانی مجازی',
      'PenaltyKicks': 'ضربات پنالتی',
      'VirtualFootballLeague': 'لیگ فوتبال مجازی',
      'DragRacing': 'مسابقه اتومبیل رانی',
      'MarbleRacing': 'مسابقه تیله بازی',
      'additional info': 'اطلاعات اضافی بوک میکر در صورت وجود برخی اطلاعات',
      'quick bet about': ' مبلغی را وارد کنید و برای قرار دادن شرط روی هر ضریب کلیک نمایید.',
      'to select bet': 'برای انتخاب شرط ، لطفاً بر روی هرضریب کلیک کنید.',
      'player names': 'نام بازیکنان',
      'matches': 'مسابقات',
      'live table': 'میز زنده',
      'all': 'همه',
      'match': 'مسابقه',
      'place bet': 'بستن شرط',
      'insufficient balance': 'موجودی کافی نیست',
      'amount is less': 'مبلغ شرط از حداقل مبلغ مجاز کمتر است.',
      'clear all': 'پاک کردن همه',
      'single': 'تکی',
      'multiple': 'چندتایی',
      'system': 'سیستم',
      'data id': 'تاریخ و شناسه',
      'stake': 'مبلغ شرط',
      'odd type': 'نوع ضریب',
      'odds': 'ضرایب',
      'outcome': 'نتیجه',
      'status': 'وضعیت',
      'history empty': 'گزارش خالی است ',
      'hour': 'ساعت',
      'custom': 'سفارشی',
      '1h': '1 ساعت',
      '2h': '2 ساعت',
      '3h': '3 ساعت',
      '6h': '6 ساعت',
      '12h': '12 ساعت',
      '24h': '24 ساعت',
      '48h': '48 ساعت',
      '72h': '72 ساعت',
      'sport': 'ورزش',
      'competition': 'رقابت',
      'date': 'تاریخ',
      'event': 'رویداد',
      'score': 'امتیاز',
      'search': 'جستجو',
      'kg': 'کیلوگرم',
      'final': 'فینال',
      'max bet case': 'مبلغ ورودی از حداقل مبلغ شرط کمتر است',
      'min bet case': 'مبلغ ورودی بیشتر از حداکثر مبلغ شرط مجاز است.',
      'event not available': 'این رویداد در دسترس نیست.',
      'bet accepted': 'شرط شما پذیرفته شد.',
      'bet failed': 'شرط شما مورد پذیرش قرار نگرفت',
      'deleted events': 'رویدادهای حذف شده وجود دارد',
      'bet in progress': 'شرط شما در دست  است...',
      'possible win': 'برد محتمل',
      'unsettled': 'تعیین نشده',
      'last': 'آخرین',
      'next start in': 'امتیاز بعدی شروع می شود در',
      'to place bets': 'جهت بستن شرط، لطفا',
      'sign in': 'ورود',
      'or': 'یا',
      'register': 'همین حالا ثبت نام کنید',
      'virtual': 'مجازی',
      'instant': 'فوری',
      'results': 'نتایج',
      'upcoming games': 'بازی های پیش رو',
      'match running': ' این مسابقه در حال برگزاری است.می توانید مارکت‌های سایر مسابقات را مشاهده نمایید.',
      'select day': ' انتخاب روز مسابقه ',
      'handicaps': 'هندیکپ ها',
      'total': 'مجموع',
      'half': 'نیمه',
      'set': 'ست',
      'game': 'بازی',
      'point': 'امتیاز',
      'player name': 'نام بازیکن',
      'decimal': 'اعشاری',
      'american': 'آمریکایی',
      'hongkong': 'هنگ کنگ',
      'malay': 'مالائی',
      'indo': 'اندونزیایی',
      'bet slip': 'برگه شرط بندی',
      'my bets': 'شرط های من',
      'quick bets': 'شرط‌های سریع',
      'superbet': 'سوپربت',
      'superbet about': ' قابلیت سوپربت به کاربران امکان ثبت شرط با مبلغی بالاتر از حداکثر مبلغ شرط را می دهد.این درخواست ممکن است توسط معامله کننده تایید یا رد شود.',
      'counter offer': 'پیشنهاد متقابل در دسترس است',
      'counter offer about': ' پیشنهاد متقابل قابلیت جدیدی است که امکان پیشنهاد ضریب دلخواه را برای رویدادهای ورزشی منتخب‌ میسر می سازد.این گزینه برای ضرایب بالای 1.10 در دسترس می باشد.',
      'view all bets': 'می توانید تمام شرط های خود را مشاهده نمایید در ',
      'bet history': 'تاریخچه شرط',
      'calculator': 'ماشین حساب سیستم',
      'total stake': 'مجموع مبلغ شرط',
      'combinations': 'ترکیب ها',
      'winnings': 'جوایز',
      'all bets': 'تمام شرط ها',
      'open': 'باز',
      'win': 'برد',
      'lost': 'باخت',
      'return': 'برگشت',
      'bet ID': 'شناسه شرط',
      'bet type': 'نوع شرط',
      'period': 'مدت زمان',
      'range': 'محدوده زمانی',
      'show': 'نمایش',
      'odd': 'ضریب',
      'cash-out': 'کش اوت',
      'add more events': ' افزودن رویدادهای بیش‌تر برای شرط بندی',
      'quick bets about': ' برای بستن شرط مبلغی را وارد کرده و بر روی یک ضریب کلیک کنید.',
      'not selected': 'انتخاب نشده',

      'Germany': 'آلمان',
      'Spain': 'اسپانیا',
      'England': 'انگلستان',
      'Italy': 'ایتالیا',
      'France': 'فرانسه',
      'Belgium': 'بلژیک',
      'Argentina': 'آرژانتین',
      'Brazil': 'برزیل',


      'PartnerNotFound': 'شریک ثبت نشده است',
      'BetTypeError': 'یک نوع شرط معتبر انتخاب کنید',
      'BetValidationError': 'مشکل فنی رخ داده است، لطفا مجدد تلاش کنید',
      'PartnerApiClientBalanceError': 'موجودی کافی نیست',
      'BetSelectionChanged': 'ضرایب برد تغییر کرده است',
      'SelectionNotFound': 'انتخاب صحیح نیست',
      'BetAmountError': 'مبلغ شرط خارج از محدوده مجاز است',
      'SelectionSuspended': 'رویدادهای حذف شده وجود دارد',
    }
  },
  ukr: {
    translation: {
      'virtualSport': 'Віртуальний Спорт',
      'noOpenBets': 'Немає відкритих ставок.',
      'select': 'Обрати',
      'accepted': 'Прийнято',
      'cashouted': 'Переведено в готівку',
      'winReturn': 'Повернення виграшу',
      'loseReturn': 'Повернення програшу',
      'always-ask': 'Прийняти зміни та зробити ставку',
      'suspend': 'Ця подія призупинена.',
      'bet settings title': 'Коли коефіцієнт змінюється',
      'accept high odd': 'Прийняти вищі коефіцієнти',
      'always ask': 'Завжди запитувати',
      'any odd': 'Прийняти будь-які коефіцієнти',
      'VirtualTennis': 'Віртуальний теніс',
      'VirtualFootball': 'Віртуальний Футбол',
      'VirtualHorseRacing': 'Віртуальні кінні перегони',
      'VirtualBicycle': 'Віртуальні велоперегони',
      'VirtualGreyhounds': 'Віртуальні собачі перегони',
      'PenaltyKicks': 'Штрафні удари',
      'VirtualFootballLeague': 'Віртуальна футбольна ліга',
      'DragRacing': 'Дрег-рейсинг',
      'MarbleRacing': 'Марбл Рейсинг',
      'additional info': 'Додаткова інформація букмекера, якщо така є',
      'all': 'Усі',
      'match': 'Матч',
      'quick bet about': 'Введіть суму та натисніть на будь-який коефіцієнт, щоб зробити ставку.',
      'to select bet': 'Щоб обрати ставку, натисніть на будь-який коефіцієнт.',
      'history empty': 'Історія пуста.',
      'place your bets': 'Робіть свої ставки.',
      'virtual': 'Віртуальний',
      'instant': 'Миттєвий',
      'results': 'Результати',
      'upcoming games': 'Майбутні ігри',
      'match running': 'Цей матч уже почався. Ви можете переглянути маркети інших матчів.',
      'select day': 'Оберіть день матчу',
      'handicaps': 'Гандикапи',
      'total': 'Тотал',
      'half': 'Тайм',
      'set': 'Сет',
      'game': 'Гра',
      'point': 'Очко',
      'player name': 'Ім’я Гравця',
      'decimal': 'Десятковий',
      'american': 'Американський',
      'hongkong': 'Гонконзький',
      'malay': 'Малайський',
      'indo': 'Індійський',
      'bet slip': 'Купон',
      'my bets': 'Мої Ставки',
      'quick bets': 'Швидкі Ставки',
      'superbet': 'Супер Ставка',
      'superbet about': 'Супер Ставка – це функція, яка дозволяє користувачам робити запит на розміщення ставок, які перевищують Максимальний Ліміт. Трейдери можуть схвалити або відхилити цей запит.',
      'counter offer': 'Доступна Зустрічна пропозиція',
      'counter offer about': 'Зустрічна пропозиція – це нова опція, яка дозволяє пропонувати бажані коефіцієнти для обраних спортивних подій. Доступна для коефіцієнтів, вищих за 1.10.',
      'view all bets': 'Ви можете переглянути свої ставки в',
      'bet history': 'Історія Ставок',
      'calculator': 'Калькулятор Системи',
      'total stake': 'Загальна ставка',
      'combinations': 'Комбінації',
      'winnings': 'Перемоги',
      'all bets': 'Усі Ставки',
      'open': 'Відкрити',
      'win': 'Перемога',
      'lost': 'Програш',
      'return': 'Повернення',
      'bet ID': 'ID Ставки',
      'bet type': 'Тип Ставки',
      'period': 'Період',
      'range': 'Діапазон',
      'show': 'Показати',
      'odd': 'Коефіцієнт',
      'cash-out': 'Кеш-аут',
      'add more events': 'Додайте більше подій, щоб розмістити ставку',
      'quick bets about': 'Введіть суму та Натисніть на будь-який коефіцієнт, щоб розмістити ставку.',
      'not selected': 'Не обрано',
      'Germany': 'Німеччина',
      'Spain': 'Іспанія',
      'England': 'Англія',
      'Italy': 'Італія',
      'France': 'Франція',
      'Belgium': 'Бельгія',
      'Argentina': 'Аргентина',
      'Brazil': 'Бразилія',
      'PartnerNotFound': 'Партнер не зареєстрований',
      'BetTypeError': 'Оберіть дійсний тип ставки',
      'BetValidationError': 'Виникла технічна проблема, будь ласка, спробуйте ще раз',
      'PartnerApiClientBalanceError': 'Недостатній баланс',
      'BetSelectionChanged': 'Коефіцієнти змінилися',
      'SelectionNotFound': 'Хибна подія',
      'BetAmountError': 'Сума ставки виходить за межі дозволеного діапазону',
      'SelectionSuspended': 'Є видалені події',
      'place bet': 'Зробити ставку',
      'reset': 'Скиданов',
      'insufficient balance': 'Недостатній баланс Депозит',
      'amount is less': 'Сума ставки менше мінімально дозволеної.',
      'clear all': 'Очистити все',
      'single': 'Ординар',
      'multiple': 'Експрес',
      'system': 'Система',
      'data id': 'Дата і ID',
      'stake': 'Сума ставки',
      'odd type': 'Тип коефіцієнта',
      'odds': 'Коефіцієнти',
      'outcome': 'Вихід',
      'status': 'Статус',
      'hour': 'час',
      'custom': 'Призначений для користувача',
      '1h': '1 година',
      '2h': '2 години',
      '3h': '3 години',
      '6h': '6 годин',
      '12h': '12 годин',
      '24h': '24 години',
      '48h': '48 годин',
      '72h': '72 години',
      'sport': 'Спорт',
      'competition': 'Змагання',
      'date': 'Дата',
      'event': 'Подія',
      'score': 'Рахунок',
      'search': 'Пошук',
      'kg': 'кг',
      'final': 'Фінал',
      'max bet case': 'Введена сума менше мінімальної ставки.',
      'min bet case': 'Введена сума більше максимальної суми ставки.',
      'event not available': 'Дана подія недоступно.',
      'bet accepted': 'Ваша ставка прийнята.',
      'bet failed': 'Ваша ставка не прийнята.',
      'deleted events': 'Є віддалені події',
      'bet in progress': 'Ваша ставка обробляється ...',
      'possible win': 'Можливий виграш',
      'unsettled': 'Неопрацьований',
      'last': 'Останній',
      'to place bets': 'Щоб зробити ставку, будь ласка',
      'sign in': 'увійдіть',
      'or': 'або',
      'register': 'зареєструйтеся зараз!',
      'player names': 'Імена гравців',
      'live table': 'Live таблиця',
      'matches': 'зустрічі',
      'edit': 'Редагувати',
      'save': 'Зберегти',
      'next start in': 'Наступний поінт починається в',
      'played': 'Зіграно',
      'draw': 'Нічия',
      'lose': 'Програш',
      'goals for': 'Голи за',
      'goals against': 'Голи проти',
      'goals diff': 'Різниця голів',
      'position': 'Позиція',
      'team': 'Команда',
      'position LG': 'Поз.',
      'played LG': 'З',
      'win LG': 'В',
      'draw LG': 'Н',
      'lose LG': 'П',
      'goals for LG': 'ГЗ',
      'goals against LG': 'ГП',
      'goals diff LG': 'РГ',
      'points LG': 'Очк',
      'id': 'ID:',
      'tax amount': 'Сума податку:',
      'final payout': 'Фінальна виплата:',
      'total bet amount': 'Загальна сума ставки:',
      'final bet amount': 'Фінальна сума ставки:'
    }
  },
  por_2: {
    translation: {
      'virtualSport': 'Esportes Virtuais',
      'noOpenBets': 'Não há apostas abertas.',
      'select': 'Selecionar',
      'accepted': 'Aceitado',
      'cashouted': 'Sacado',
      'winReturn': 'Ganho',
      'loseReturn': 'Perda',
      'always-ask': 'Aceitar mudanças e fazer aposta',
      'suspend': 'Este evento foi suspenso.',
      'bet settings title': 'Quando o preço muda',
      'accept high odd': 'Aceitar odds mais altas',
      'always ask': 'Sempre pergunte',
      'any odd': 'Aceitar todas as odds',
      'VirtualTennis': 'Tênis Virtual',
      'VirtualFootball': 'Futebol Virtual',
      'VirtualHorseRacing': 'Corrida virtual de cavalos',
      'VirtualBicycle': 'Ciclismo virtual',
      'VirtualGreyhounds': 'Galgos virtuais',
      'PenaltyKicks': 'Chutes de pênalti',
      'VirtualFootballLeague': 'Liga Virtual de Futebol',
      'DragRacing': 'Corridas de arrancada',
      'MarbleRacing': 'Corrida de mármore',
      'additional info': 'Informações adicionais do marcador, se houver alguma',
      'all': 'Tudo',
      'find bets': 'Encontre as suas apostas aqui',
      'new feature': 'Nova característica',
      'dont show': 'Não mostrar mais',
      'match': 'Partida',
      'quick bet about': 'Insira um valor e clique em qualquer odd para fazer uma aposta.',
      'to select bet': 'Para selecionar uma aposta, clique em qualquer odd.',
      'player names': 'Nomes dos jogadores',
      'matches': 'Jogos',
      'live table': 'Mesa ao vivo',
      'place bet': 'Apostar',
      'insufficient balance': 'Depósito de saldo insuficiente',
      'amount is less': 'O valor da aposta é inferior ao mínimo permitido.',
      'clear all': 'Limpar tudo',
      'single': 'Simples',
      'multiple': 'Múltipla',
      'system': 'Sistema',
      'data id': 'Data e ID',
      'stake': 'aposta',
      'odd type': 'Tipo de odd',
      'odds': 'Odds',
      'outcome': 'Resultado',
      'status': 'Estado',
      'history empty': 'O histórico está vazio.',
      'hour': 'hora',
      'custom': 'Personalizar',
      '1h': '1 hora',
      '2h': '2 horas',
      '3h': '3 horas',
      '6h': '6 horas',
      '12h': '12 horas',
      '24h': '24 horas',
      '48h': '48 horas',
      '72h': '72 horas',
      'sport': 'Esporte',
      'competition': 'Competição',
      'date': 'Data',
      'event': 'Evento',
      'score': 'Placar',
      'search': 'Pesquisar',
      'kg': 'kg',
      'final': 'Final',
      'max bet case': 'O valor inserido é menor que a aposta mínima.',
      'min bet case': 'O valor inserido é maior do que o valor máximo da aposta.',
      'event not available': 'Este evento não está disponível.',
      'bet accepted': 'Sua aposta foi aceita.',
      'bet failed': 'Sua aposta falhou.',
      'deleted events': 'Existem eventos excluídos',
      'bet in progress': 'Sua aposta está em andamento ...',
      'possible win': 'Possível ganho',
      'unsettled': 'Não resolvido',
      'last': 'Último',
      'next start in': 'Próximo ponto começa em',
      'to place bets': 'Para apostar, por favor',
      'sign in': 'Faça login',
      'or': 'ou',
      'register': 'Inscreva-se agora',
      'place your bets': 'Faça suas apostas',
      'virtual': 'Virtual',
      'instant': 'Instantâneo',
      'results': 'Resultados',
      'upcoming games': 'Próximos jogos',
      'match running': 'Esta partida já está acontecendo. Você pode ver os mercados de outras partidas.',
      'select day': 'Selecione o dia da partida',
      'handicaps': 'Handicaps',
      'total': 'Total',
      'half': 'Metade',
      'set': 'Set',
      'game': 'Jogo',
      'point': 'Ponto',
      'player name': 'Nome do jogador',
      'decimal': 'Decimal',
      'american': 'Americano',
      'hongkong': 'HongKong',
      'malay': 'Malaio',
      'indo': 'Indo',
      'bet slip': 'Boletim de apostas',
      'my bets': 'Minhas apostas',
      'quick bets': 'Apostas rápidas',
      'superbet': 'Superbet',
      'superbet about': 'SuperBet é um recurso que permite aos usuários solicitar uma aposta com um montante de aposta que excede o Limite Máximo de Apostas. O pedido pode ser aprovado ou reprovado pelos traders.',
      'counter offer': 'Contraoferta disponível',
      'counter offer about': 'A Contraoferta é uma nova opção que permite sugerir odds preferenciais para eventos esportivos selecionados. Disponível em odds acima de 1,10.',
      'view all bets': 'Você pode ver todas as suas apostas em',
      'bet history': 'Histórico de apostas',
      'calculator': 'Calculadora do Sistema',
      'total stake': 'Aposta total',
      'combinations': 'Combinações',
      'winnings': 'Ganhos',
      'all bets': 'Todas as apostas',
      'open': 'Abrir',
      'win': 'Ganhou',
      'lost': 'Perdeu',
      'return': 'Retornar',
      'bet ID': 'ID da aposta',
      'bet type': 'Tipo de aposta',
      'period': 'Período',
      'range': 'Intervalo',
      'show': 'Mostrar',
      'odd': 'Odd',
      'cash-out': 'Cash-out',
      'add more events': 'Adicione mais eventos para poder fazer apostas',
      'quick bets about': 'Insira um valor e clique em qualquer odd para fazer uma aposta.',
      'not selected': 'Não selecionado',

      'Germany': 'Alemanha',
      'Spain': 'Espanha',
      'England': 'Inglaterra',
      'Italy': 'Itália',
      'France': 'França',
      'Belgium': 'Bélgica',
      'Argentina': 'Argentina',
      'Brazil': 'Brasil',

      'PartnerNotFound': 'O parceiro não está registrado',
      'BetTypeError': 'Selecione um tipo de aposta válido',
      'BetValidationError': 'Ocorreu um problema técnico, tente novamente',
      'PartnerApiClientBalanceError': 'Saldo insuficiente',
      'BetSelectionChanged': 'As odds foram alteradas',
      'SelectionNotFound': 'A seleção não está correta',
      'BetAmountError': 'O valor da aposta está fora do intervalo permitido',
      'SelectionSuspended': 'Existem eventos excluídos',
    }
  },
  por: {
    translation: {
      'virtualSport': 'Esportes Virtuais',
      'noOpenBets': 'Não há apostas abertas.',
      'select': 'Selecionar',
      'accepted': 'Aceitado',
      'cashouted': 'Sacado',
      'winReturn': 'Ganho',
      'loseReturn': 'Perda',
      'always-ask': 'Aceitar mudanças e fazer aposta',
      'suspend': 'Este evento foi suspenso.',
      'bet settings title': 'Quando o preço muda',
      'accept high odd': 'Aceitar odds mais altas',
      'always ask': 'Sempre pergunte',
      'any odd': 'Aceitar todas as odds',
      'new feature': 'Nova característica',
      'find bets': 'Encontre as suas apostas aqui',
      'dont show': 'Não mostrar mais',

      'VirtualTennis': 'Tênis Virtual',
      'VirtualFootball': 'Futebol virtual',
      'VirtualHorseRacing': 'Corrida de cavalos virtual',
      'VirtualBicycle': 'Bicicleta Virtual',
      'VirtualGreyhounds': 'Galgos virtuais',
      'PenaltyKicks': 'Pênaltis',
      'VirtualFootballLeague': 'Liga de Futebol Virtual',
      'DragRacing': 'Prova de arrancada',
      'MarbleRacing': 'Corrida de bolinhas de gude',
      'additional info': 'Informações adicionais do marcador, se houver alguma',
      'all': 'Tudo',
      'match': 'Partida',
      'quick bet about': 'Insira um valor e clique em qualquer odd para fazer uma aposta.',
      'to select bet': 'Para selecionar uma aposta, clique em qualquer odd.',
      'player names': 'Nomes dos jogadores',
      'matches': 'Jogos',
      'live table': 'Mesa ao vivo',
      'place bet': 'Apostar',
      'insufficient balance': 'Depósito de saldo insuficiente',
      'amount is less': 'O valor da aposta é inferior ao mínimo permitido.',
      'clear all': 'Limpar tudo',
      'single': 'Simples',
      'multiple': 'Múltipla',
      'system': 'Sistema',
      'data id': 'Data e ID',
      'stake': 'aposta',
      'odd type': 'Tipo de odd',
      'odds': 'Odds',
      'outcome': 'Resultado',
      'status': 'Estado',
      'history empty': 'O histórico está vazio.',
      'hour': 'hora',
      'custom': 'Personalizar',
      '1h': '1 hora',
      '2h': '2 horas',
      '3h': '3 horas',
      '6h': '6 horas',
      '12h': '12 horas',
      '24h': '24 horas',
      '48h': '48 horas',
      '72h': '72 horas',
      'sport': 'Esporte',
      'competition': 'Competição',
      'date': 'Data',
      'event': 'Evento',
      'score': 'Placar',
      'search': 'Pesquisar',
      'kg': 'kg',
      'final': 'Final',
      'max bet case': 'O valor inserido é menor que a aposta mínima.',
      'min bet case': 'O valor inserido é maior do que o valor máximo da aposta.',
      'event not available': 'Este evento não está disponível.',
      'bet accepted': 'Sua aposta foi aceita.',
      'bet failed': 'Sua aposta falhou.',
      'deleted events': 'Existem eventos excluídos',
      'bet in progress': 'Sua aposta está em andamento ...',
      'possible win': 'Possível ganho',
      'unsettled': 'Não resolvido',
      'last': 'Último',
      'next start in': 'Próximo ponto começa em',
      'to place bets': 'Para apostar, por favor',
      'sign in': 'Faça login',
      'or': 'ou',
      'register': 'Inscreva-se agora',
      'place your bets': 'Faça suas apostas',
      'virtual': 'Virtual',
      'instant': 'Instantâneo',
      'results': 'Resultados',
      'upcoming games': 'Próximos jogos',
      'match running': 'Esta partida já está acontecendo. Você pode ver os mercados de outras partidas.',
      'select day': 'Selecione o dia da partida',
      'handicaps': 'Handicaps',
      'total': 'Total',
      'half': 'Metade',
      'set': 'Set',
      'game': 'Jogo',
      'point': 'Ponto',
      'player name': 'Nome do jogador',
      'decimal': 'Decimal',
      'american': 'Americano',
      'hongkong': 'HongKong',
      'malay': 'Malaio',
      'indo': 'Indo',
      'bet slip': 'Boletim de apostas',
      'my bets': 'Minhas apostas',
      'quick bets': 'Apostas rápidas',
      'superbet': 'Superbet',
      'superbet about': 'SuperBet é um recurso que permite aos usuários solicitar uma aposta com um montante de aposta que excede o Limite Máximo de Apostas. O pedido pode ser aprovado ou reprovado pelos traders.',
      'counter offer': 'Contraoferta disponível',
      'counter offer about': 'A Contraoferta é uma nova opção que permite sugerir odds preferenciais para eventos esportivos selecionados. Disponível em odds acima de 1,10.',
      'view all bets': 'Você pode ver todas as suas apostas em',
      'bet history': 'Histórico de apostas',
      'calculator': 'Calculadora do Sistema',
      'total stake': 'Aposta total',
      'combinations': 'Combinações',
      'winnings': 'Ganhos',
      'all bets': 'Todas as apostas',
      'open': 'Abrir',
      'win': 'Ganhou',
      'lost': 'Perdeu',
      'return': 'Retornar',
      'bet ID': 'ID da aposta',
      'bet type': 'Tipo de aposta',
      'period': 'Período',
      'range': 'Intervalo',
      'show': 'Mostrar',
      'odd': 'Odd',
      'cash-out': 'Cash-out',
      'add more events': 'Adicione mais eventos para poder fazer apostas',
      'quick bets about': 'Insira um valor e clique em qualquer odd para fazer uma aposta.',
      'not selected': 'Não selecionado',

      'Germany': 'Alemanha',
      'Spain': 'Espanha',
      'England': 'Inglaterra',
      'Italy': 'Itália',
      'France': 'França',
      'Belgium': 'Bélgica',
      'Argentina': 'Argentina',
      'Brazil': 'Brasil',

      'PartnerNotFound': 'O parceiro não está registrado',
      'BetTypeError': 'Selecione um tipo de aposta válido',
      'BetValidationError': 'Ocorreu um problema técnico, tente novamente',
      'PartnerApiClientBalanceError': 'Saldo insuficiente',
      'BetSelectionChanged': 'As odds foram alteradas',
      'SelectionNotFound': 'A seleção não está correta',
      'BetAmountError': 'O valor da aposta está fora do intervalo permitido',
      'SelectionSuspended': 'Existem eventos excluídos',
    }
  },
  spa: {
    translation: {
      'virtualSport': 'Deportes Virtuales',
      'noOpenBets': 'No hay apuestas abiertas',
      'select': 'Seleccionar',
      'accepted': 'Aceptado',
      'cashouted': 'Retirado',
      'winReturn': 'Ganancia',
      'loseReturn': 'Pérdida',
      'always-ask': 'Aceptar cambios y apostar',
      'suspend': 'Este evento ha sido suspendido.',
      'bet settings title': 'Cuando cambia el precio',
      'accept high odd': 'Aceptar cuotas más altas',
      'always ask': 'Siempre preguntar',
      'any odd': 'Aceptar cualquier cuota',
      'new feature': 'Nueva característica',
      'find bets': 'Encuentre sus apuestas aquí',
      'dont show': 'No mostrar más',

      'VirtualTennis': 'Tenis virtual',
      'VirtualFootball': 'Fútbol virtual',
      'VirtualHorseRacing': 'Carrera de caballos virtual',
      'VirtualBicycle': 'Bicicleta virtual',
      'VirtualGreyhounds': 'Galgos virtuales',
      'PenaltyKicks': 'Tanda de penales',
      'VirtualFootballLeague': 'Liga de fútbol virtual',
      'DragRacing': 'Carrera de aceleración',
      'MarbleRacing': 'Carrera de canicas',
      'additional info': 'Información adicional del marcador, de haber alguna información',
      'all': 'Todo',
      'match': 'Partido',
      'quick bet about': 'Ingrese un monto y haga clic en cualquier cuota para realizar una apuesta. ',
      'to select bet': 'Para seleccionar una apuesta, haga clic en cualquier cuota',
      'player names': 'Nombres del jugador',
      'matches': 'Partidos',
      'live table': 'Mesa en vivo',
      'place bet': 'Hacer apuesta',
      'insufficient balance': 'Depósito de saldo insuficiente',
      'amount is less': 'El monto de la apuesta es inferior al mínimo permitido',
      'clear all': 'Borrar todo',
      'single': 'Simple',
      'multiple': 'Múltiple',
      'system': 'Sistema',
      'data id': 'Fecha e ID',
      'stake': 'Monto de apuesta',
      'odd type': 'Tipo de cuota',
      'odds': 'Cuotas',
      'outcome': 'Resultado',
      'status': 'Estado',
      'history empty': 'El historial está vacío',
      'hour': 'hora',
      'custom': 'Personalizar',
      '1h': '1 hora',
      '2h': '2 horas',
      '3h': '3 horas',
      '6h': '6 horas',
      '12h': '12 horas',
      '24h': '24 horas',
      '48h': '48 horas',
      '72h': '72 horas',
      'sport': 'Deporte',
      'competition': 'Competición',
      'date': 'Fecha',
      'event': 'Evento',
      'score': 'Marcador',
      'search': 'Buscar',
      'kg': 'kg',
      'final': 'Final',
      'max bet case': 'El monto ingresado es inferior a la apuesta mínima.',
      'min bet case': 'El monto ingresado es superior al monto máximo de apuesta',
      'event not available': 'Este evento no está disponible',
      'bet accepted': 'Su apuesta ha sido aceptada',
      'bet failed': 'Su apuesta tuvo un error.',
      'deleted events': 'Hay eventos eliminados',
      'bet in progress': 'Su apuesta está en curso ...',
      'possible win': 'Ganancia posible',
      'unsettled': 'No determinado',
      'last': 'Último',
      'next start in': 'El siguiente punto comienza en',
      'to place bets': 'Para realizar su apuesta, por favor',
      'sign in': 'inicie sesión',
      'or': 'o',
      'register': 'regístrese ahora!',
      'place your bets': 'Hagan sus apuestas',
      'virtual': 'Virtual',
      'instant': 'Instantáneo',
      'results': 'Resultados',
      'upcoming games': 'Próximos juegos',
      'match running': 'Este partido ya se está llevando a cabo. Puede ver los mercados de otros partidos',
      'select day': 'Seleccione el día del partido',
      'handicaps': 'Hándicaps',
      'total': 'Total',
      'half': 'Mitad',
      'set': 'Set',
      'game': 'Juego',
      'point': 'Punto',
      'player name': 'Nombre del jugador',
      'decimal': 'Decimal',
      'american': 'Americano',
      'hongkong': 'HongKong',
      'malay': 'Malayo',
      'indo': 'Indo',
      'bet slip': 'Cupón de apuesta',
      'my bets': 'Mis apuestas',
      'quick bets': 'Apuestas rápidas',
      'superbet': 'Superbet',
      'superbet about': 'SuperBet es una función que les permite a los usuarios realizar una apuesta con un monto que exceda el límite máximo de apuesta. Los operadores pueden aprobar o rechazar la solicitud',
      'counter offer': 'Countraoferta disponible',
      'counter offer about': 'La contraoferta es una nueva opción que le permite sugerir cuotas preferibles para eventos deportivos seleccionados. Disponible en cuotas superiores a 1.10',
      'view all bets': 'Puede ver todas sus apuestas en',
      'bet history': 'Historial de apuesta',
      'calculator': 'Calculadora del sistema',
      'total stake': 'Total de apuesta',
      'combinations': 'Combinaciones',
      'winnings': 'Ganancias',
      'all bets': 'Todas las apuestas',
      'open': 'Abrir',
      'win': 'Ganó',
      'lost': 'Perdió',
      'return': 'Volver',
      'bet ID': 'ID de apuesta',
      'bet type': 'Tipo de apuesta',
      'period': 'Periodo',
      'range': 'Rango',
      'show': 'Mostrar',
      'odd': 'Cuota',
      'cash-out': 'Cash-out',
      'add more events': 'Agregue más eventos para poder realizar apuestas',
      'quick bets about': 'Ingrese un monto y haga clic en cualquier cuota para realizar una apuesta',
      'not selected': 'No seleccionado',

      'Germany': 'Alemania',
      'Spain': 'España',
      'England': 'Inglaterra',
      'Italy': 'Italia',
      'France': 'Francia',
      'Belgium': 'Bélgica',
      'Argentina': 'Argentina',
      'Brazil': 'Brasil',

      'PartnerNotFound': 'El socio no está registrado',
      'BetTypeError': 'Seleccione un tipo de apuesta válido',
      'BetValidationError': 'Ocurrió un problema técnico, intente nuevamente',
      'PartnerApiClientBalanceError': 'Saldo insuficiente',
      'BetSelectionChanged': 'Las cuotas han cambiado',
      'SelectionNotFound': 'La selección no es correcta',
      'BetAmountError': 'El monto de la apuesta está fuera del rango permitido',
      'SelectionSuspended': 'Hay eventos eliminados',
    }
  },
  fra: {
    translation: {
      'virtualSport': 'Sports virtuels',
      'noOpenBets': 'Aucun pari ouvert',
      'select': 'Sélectionner',
      'accepted': 'Accepté',
      'cashouted': 'Encaissé',
      'winReturn': 'Retour des gains',
      'loseReturn': 'Retour de perte',
      'always-ask': 'Accepter les changements et placer le pari',
      'suspend': 'Cet événement suspendu',
      'bet settings title': 'Quand le prix change',
      'accept high odd': 'Accepter des cotes plus élevées',
      'always ask': 'Demander toujours',
      'any odd': 'Accepter toutes les cotes',
      'VirtualTennis': 'Tennis virtuel',
      'VirtualFootball': 'Football virtuel',
      'VirtualHorseRacing': 'Courses de chevaux virtuelles',
      'VirtualBicycle': 'Cyclisme virtuel',
      'VirtualGreyhounds': 'Course de chiens virtuelle',
      'PenaltyKicks': 'Penalty',
      'VirtualFootballLeague': 'Ligue de football virtuelle',
      'DragRacing': 'Dragster',
      'MarbleRacing': 'Course de marbre',
      'additional info': 'Informations supplémentaires sur le bookmaker en cas échéant',
      'all': 'Tous',
      'match': 'Match',
      'quick bet about': 'Insérez un montant et cliquez sur n\'importe quelle cote pour placerun pari.',
      'to select bet': 'Cliquez sur une cote pour sélectionner un pari.',
      'history empty': 'L\'histoire est vide.',
      'place your bets': 'Placez vos paris',
      'virtual': 'Virtuel',
      'instant': 'Instant',
      'results': 'Résultats',
      'upcoming games': 'Jeux à venir',
      'match running': 'Ce match est déjà en cours. Vous pouvez regarder les marchés d\'autres matchs.',
      'select day': 'Sélectionner la journée de match',
      'handicaps': 'Handicaps',
      'total': 'Total',
      'half': 'MI-temps',
      'set': 'Set',
      'game': 'Jeu',
      'point': 'Point',
      'player name': 'Nom de joueur',
      'decimal': 'Décimal',
      'american': 'Américain',
      'hongkong': 'HongKong',
      'malay': 'Malais',
      'indo': 'Indo',
      'bet slip': 'Coupon de pari',
      'my bets': 'Mes paris',
      'quick bets': 'Paris rapides',
      'superbet': 'Super pari',
      'superbet about': 'Super Pari est une nouvelle offre qui permet de demander une augmentation de la limite maximale de pari pour certains événements sportifs. La demande peut être approuvée ou désapprouvée par les traders',
      'counter offer': 'Contre-offre disponible',
      'counter offer about': 'Contre-offre est une nouvelle option qui vous permet de négocier vos cotes pour certains événements sportifs Disponible pour les cotes supérieures à 1.10',
      'view all bets': 'Vous pouvez voir tous vos paris dans',
      'bet history': 'Historique des paris',
      'calculator': 'Calculatrice système',
      'total stake': 'Mise totale',
      'combinations': 'Combinaisons',
      'winnings': 'Gains',
      'all bets': 'Tous les paris',
      'open': 'Ouvert',
      'win': 'Gagner',
      'lost': 'Perdre',
      'return': 'Retourner',
      'bet ID': 'ID de pari',
      'bet type': 'Type de pari',
      'period': 'Période',
      'range': 'Gamme',
      'show': 'Montrer',
      'odd': 'Cote',
      'cash-out': 'Cash out',
      'add more events': 'Ajouter plus d\'événements pour pouvoir placer des paris',
      'quick bets about': 'Insérer un montant et cliquer sur n\'importe quelle cote pour placer un pari.',
      'not selected': 'Non séléctionné',
      'Germany': 'Allemagne',
      'Spain': 'Espagne',
      'England': 'Angleterre',
      'Italy': 'Italie',
      'France': 'France',
      'Belgium': 'Belgique',
      'Argentina': 'Argentine',
      'Brazil': 'Brésil',
      'PartnerNotFound': 'Le partenaire n\'est pas enregistré',
      'BetTypeError': 'Sélectionnez un type de pari valide',
      'BetValidationError': 'Un problème technique s\'est produit, veuillez réessayer',
      'PartnerApiClientBalanceError': 'Solde insuffisant',
      'BetSelectionChanged': 'Les cotes ont été modifiées',
      'SelectionNotFound': 'La sélection est incorrecte',
      'BetAmountError': 'Le montant de la mise se situe en dehors de la fourchette autorisée',
      'SelectionSuspended': 'Il y a des événements supprimés',
      'save': 'Enregistrer',
      'edit': 'Modifier',
      'played': 'Joué',
      'draw': 'Égalité',
      'lose': 'Défaite',
      'goals for': 'Buts pour',
      'goals against': 'Buts contre',
      'goals diff': 'Différence de buts',
      'position': 'Position',
      'team': 'Équipe',
      'position LG': 'Pos.',
      'played LG': 'J',
      'win LG': 'V',
      'draw LG': 'É',
      'lose LG': 'D',
      'goals for LG': 'BP',
      'goals against LG': 'BC',
      'goals diff LG': 'DB',
      'points LG': 'Pts',
      'player names': 'Noms des joueurs',
      'matches': 'Matchs',
      'live table': 'Tableau en direct',
      'place bet': 'Placer le pari',
      'insufficient balance': 'Solde insuffisant, déposez',
      'amount is less': 'Le montant du pari est inférieur au min autorisé.',
      'clear all': ' Effacer tout ',
      'single': 'Simple',
      'multiple': 'Combiné',
      'system': 'Système',
      'data id': 'Date et ID',
      'stake': 'Mise',
      'odd type': 'Type de cote',
      'odds': 'Cotes',
      'outcome': 'Résultat',
      'status': 'Statut',
      'hour': 'heure',
      'custom': 'Personnaliser',
      '1h': '1 heure',
      '2h': '2 heures',
      '3h': '3 heures',
      '6h': '6 heures',
      '12h': '12 heures',
      '24h': '24 heures',
      '48h': '48 heures',
      '72h': '72 heures',
      'sport': 'Sport',
      'competition': 'Compétition',
      'date': 'Date',
      'event': ' Événement',
      'score': 'Score',
      'search': 'Rechercher',
      'kg': 'kg',
      'final': 'Finale',
      'max bet case': 'Le montant saisi est supérieur au montant maximal de pari.',
      'min bet case': ' Le montant saisi est inférieur au montant minimal de pari. ',
      'event not available': 'Cet événement n\'est pas disponible.',
      'bet accepted': 'Votre pari est accepté.',
      'bet failed': 'Votre pari a échoué.',
      'deleted events': 'Il y a des événements supprimés',
      'bet in progress': 'Votre pari est en cours...',
      'possible win': 'Gain possible',
      'unsettled': 'Non réglé',
      'last': 'Dernier',
      'next start in': 'Le prochain point commence dans',
      'to place bets': 'Pour placer votre pari, veuillez',
      'sign in': 'vous connecter',
      'or': 'ou',
      'register': 'vous inscrire maintenant !',
      'id': 'ID:',
      'tax amount': 'Montant de la taxe :',
      'final payout': 'Gain final :',
      'total bet amount': 'Montant total du pari :',
      'final bet amount': 'montant final du pari :'
    }
  },
  chn: { // Traditional
    translation: {
      'virtualSport': '虛擬體育',
      'noOpenBets': '沒有未結算投注。',
      'select': '選擇t',
      'accepted': '已接受',
      'cashouted': '已兌現',
      'winReturn': '中獎退還',
      'loseReturn': '輸退還',
      'always-ask': '接受更改並下注',
      'suspend': '本場比賽暫停。 ',
      'bet settings title': '當賠率變化時',
      'accept high odd': '接受更高的賠率',
      'always ask': '總是問',
      'any odd': '接受任何賠率',
      'VirtualTennis': '虛擬網球',
      'VirtualFootball': '虛擬足球',
      'VirtualHorseRacing': '虛擬賽馬',
      'VirtualBicycle': '虛擬自行車賽',
      'VirtualGreyhounds': '虛擬狗賽',
      'PenaltyKicks': '點球',
      'VirtualFootballLeague': '虛擬足球聯賽',
      'DragRacing': '直線競速賽',
      'MarbleRacing': '彈珠賽車',
      'additional info': '其他莊家信息（如果有）',
      'all': '所有',
      'match': '比賽',
      'quick bet about': '輸入金額並單擊任意賠率下注.',
      'counter offer': '還價可用',
      'to select bet': '要選擇一個投注，請單擊任何賠率.',
      'history empty': '歷史是空的.',
      'place your bets': '下注',
      'virtual': '虛擬',
      'instant': '立即',
      'results': '賽果',
      'upcoming games': '即將到來的遊戲',
      'match running': '此比賽已在運行。 您可以觀看其他比賽的市場。',
      'select day': '選擇比賽日',
      'handicaps': '讓盤',
      'total': '總數',
      'half': '一半',
      'set': '盤',
      'game': '局',
      'point': '得分',
      'player name': '球員名',
      'decimal': '歐洲盤',
      'american': '美式盤',
      'hongkong': '香港盤',
      'malay': '馬來盤',
      'indo': '印尼盤',
      'bet slip': '投注單',
      'my bets': '我的投注',
      'quick bets': '快速投注',
      'superbet': '超級投注',
      'superbet about': '超級投注是一項功能，允許用戶請求投注額超過最大投注限額的下注。 該請求可以被莊家批准或不批准。',
      'counter offer about': '還價是一個新選項，可以為選定的體育賽事提供更好的賠率。 可用為賠率高於1.10。',
      'view all bets': '您可以查看您的所有下注',
      'bet history': '投注歷史記錄',
      'calculator': '系統計算器',
      'total stake': '投注總額',
      'combinations': '組合',
      'winnings': '中獎',
      'all bets': '所有投注',
      'open': '打開',
      'win': '贏',
      'lost': '輸',
      'return': '退還',
      'bet ID': '投注ID',
      'bet type': '投注類型',
      'period': '時期',
      'range': '範圍',
      'show': '顯示',
      'odd': '賠率',
      'cash-out': '兌現',
      'add more events': '添加更多賽事以下注',
      'quick bets about': '輸入金額，然後單擊任意賠率下注。',
      'not selected': '未選擇',
      'Germany': '德國',
      'Spain': '西班牙',
      'England': '英格蘭',
      'Italy': '意大利',
      'France': '法國',
      'Belgium': '比利時',
      'Argentina': '阿根廷',
      'Brazil': '巴西',
      'PartnerNotFound': '合作伙伴未注册',
      'BetTypeError': '选择有效的投注类型',
      'BetValidationError': '出现技术问题，请重试',
      'PartnerApiClientBalanceError': '余额不足',
      'BetSelectionChanged': '赔率已更改',
      'SelectionNotFound': '选项不正确',
      'BetAmountError': '投注额超出允许范围',
      'SelectionSuspended': '有删除的赛事',
      'save': '保存',
      'edit': '編輯',
      'played': '已玩過',
      'draw': '和局',
      'lose': '輸掉',
      'goals for': '進球數',
      'goals against': '進球數',
      'goals diff': '進球數差別',
      'position': '位置',
      'team': '球隊',
      'position LG': 'Po.',
      'played LG': 'P',
      'win LG': 'W',
      'draw LG': 'D',
      'lose LG': 'L',
      'goals for LG': 'GF',
      'goals against LG': 'GA',
      'goals diff LG': 'GD',
      'points LG': '分',
      'player names': '球員的名字',
      'matches': '比賽',
      'live table': '現場表',
      'place bet': '下注',
      'insufficient balance': '存款餘額不足',
      'amount is less': '投注額小於允許的最低投注額。',
      'clear all': '全部清除',
      'single': '單個',
      'multiple': '多個',
      'system': '系統',
      'data id': '日期和ID',
      'stake': '投注',
      'odd type': '賠率類型',
      'odds': '賠率',
      'outcome': '結果',
      'status': '狀態',
      'hour': '小時',
      'custom': '定制',
      '1h': '1 個小時',
      '2h': '2 個小時',
      '3h': '3 個小時',
      '6h': '6 個小時',
      '12h': '12 個小時',
      '24h': '24 個小時',
      '48h': '48 個小時',
      '72h': '72 個小時',
      'sport': '體育',
      'competition': '比賽',
      'date': '日期',
      'event': '賽事',
      'score': '分數',
      'search': '搜索',
      'kg': '公斤',
      'final': '最終',
      'max bet case': '輸入的金額大於最大投注金額。',
      'min bet case': '輸入的金額小於最低投注金額。',
      'event not available': '該賽事不可用。',
      'bet accepted': '您的投注已被接受',
      'bet failed': '你的投注失敗了。',
      'deleted events': '有刪除的賽事',
      'bet in progress': '您的投注正在進行中',
      'possible win': '可能的勝利',
      'unsettled': '未結算',
      'last': '最後',
      'next start in': '下一點開始於',
      'to place bets': '要下注, 請',
      'sign in': '登錄',
      'or': '或者',
      'register': '現在註冊!',
      'id': 'ID:',
      'tax amount': '稅額：',
      'final payout': '最終支付：',
      'total bet amount': '總投注額',
      'final bet amount': '最終投注額:'
    }
  },
  zhh: {
    translation: {
      'virtualSport': '虚拟体育',
      'noOpenBets': '没有未结算投注。',
      'select': '选择t',
      'accepted': '已接受',
      'cashouted': '已兑现',
      'winReturn': '中奖退还',
      'loseReturn': '输退还',
      'always-ask': '接受更改并下注',
      'suspend': '本场比赛暂停。',
      'bet settings title': '当赔率变化时',
      'accept high odd': '接受更高的赔率',
      'always ask': '总是问',
      'any odd': '接受任何赔率',
      'VirtualTennis': '虛擬網球',
      'VirtualFootball': '虚拟足球',
      'VirtualHorseRacing': '虚拟赛马',
      'VirtualBicycle': '虚拟自行车赛',
      'VirtualGreyhounds': '虚拟狗赛',
      'PenaltyKicks': '点球',
      'VirtualFootballLeague': '虚拟足球联赛',
      'DragRacing': '直线竞速赛',
      'MarbleRacing': '弹珠赛车',
      'additional info': '其他庄家信息（如果有）',
      'all': '所有',
      'match': '比赛',
      'quick bet about': '输入金额并单击任意赔率下注.',
      'counter offer': '还价可用',
      'to select bet': '要选择一个投注，请单击任何赔率.',
      'history empty': '历史是空的.',
      'place your bets': '下注',
      'virtual': '虚拟',
      'instant': '立即',
      'results': '赛果',
      'upcoming games': '即将到来的游戏',
      'match running': '此比赛已在运行。 您可以观看其他比赛的市场。',
      'select day': '选择比赛日',
      'handicaps': '让盘',
      'total': '总数',
      'half': '一半',
      'set': '盘',
      'game': '局',
      'point': '得分',
      'player name': '球员名',
      'decimal': '欧洲盘',
      'american': '美式盘',
      'hongkong': '香港盘',
      'malay': '马来盘',
      'indo': '印尼盘',
      'bet slip': '投注单',
      'my bets': '我的投注',
      'quick bets': '快速投注',
      'superbet': '超级投注',
      'superbet about': '超级投注是一项功能，允许用户请求投注额超过最大投注限额的下注。 该请求可以被庄家批准或不批准。',
      'counter offer about': '还价是一个新选项，可以为选定的体育赛事提供更好的赔率。 可用为赔率高于1.10。',
      'view all bets': '您可以查看您的所有下注',
      'bet history': '投注历史记录',
      'calculator': '系统计算器',
      'total stake': '投注总额',
      'combinations': '组合',
      'winnings': '中奖',
      'all bets': '所有投注',
      'open': '打开',
      'win': '赢',
      'lost': '输',
      'return': '退还',
      'bet ID': '投注ID',
      'bet type': '投注类型',
      'period': '时期',
      'range': '范围',
      'show': '显示',
      'odd': '赔率',
      'cash-out': '兑现',
      'add more events': '添加更多赛事以下注',
      'quick bets about': '输入金额，然后单击任意赔率下注。',
      'not selected': '未选择',
      'Germany': '德国',
      'Spain': '西班牙',
      'England': '英格兰',
      'Italy': '意大利',
      'France': '法国',
      'Belgium': '比利时',
      'Argentina': '阿根廷',
      'Brazil': '巴西',
      'save': '保存',
      'edit': '编辑',
      'played': '已玩过',
      'draw': '和局',
      'lose': '输掉',
      'goals for': '进球数',
      'goals against': '进球数',
      'goals diff': '进球数差别',
      'position': '位置',
      'team': '球队',
      'position LG': 'Po.',
      'played LG': 'P',
      'win LG': 'W',
      'draw LG': 'D',
      'lose LG': 'L',
      'goals for LG': 'GF',
      'goals against LG': 'GA',
      'goals diff LG': 'GD',
      'points LG': '分',
      'player names': '球员的名字',
      'matches': '比赛',
      'live table': '现场表',
      'place bet': '下注',
      'insufficient balance': '存款余额不足',
      'amount is less': '投注额小于允许的最低投注额。',
      'clear all': '全部清除',
      'single': '单个',
      'multiple': '多个',
      'system': '系统',
      'data id': '日期和ID',
      'stake': '投注',
      'odd type': '赔率类型',
      'odds': '赔率',
      'outcome': '结果',
      'status': '状态',
      'hour': '小时',
      'custom': '定制',
      '1h': '1 个小时',
      '2h': '2 个小时',
      '3h': '3 个小时',
      '6h': '6 个小时',
      '12h': '12 个小时',
      '24h': '24 个小时',
      '48h': '48 个小时',
      '72h': '72 个小时',
      'sport': '体育',
      'competition': '比赛',
      'date': '日期',
      'event': '赛事',
      'score': '分数',
      'search': '搜索',
      'kg': '公斤',
      'final': '最终',
      'max bet case': '输入的金额大于最大投注金额。',
      'min bet case': '输入的金额小于最低投注金额。',
      'event not available': '该赛事不可用。',
      'bet accepted': '您的投注已被接受',
      'bet failed': '你的投注失败了。',
      'deleted events': '有删除的赛事',
      'bet in progress': '您的投注正在进行中',
      'possible win': '可能的胜利',
      'unsettled': '未结算',
      'last': '最后',
      'next start in': '下一点开始于',
      'to place bets': '要下注, 请',
      'sign in': '登录',
      'or': '或者',
      'register': '现在注册!',
      'id': 'ID:',
      'PartnerNotFound': '合作伙伴未注册',
      'BetTypeError': '选择有效的投注类型',
      'BetValidationError': '出现技术问题，请重试',
      'PartnerApiClientBalanceError': '余额不足',
      'BetSelectionChanged': '赔率已更改',
      'SelectionNotFound': '选择不正确',
      'BetAmountError': '投注金额超出允许范围',
      'SelectionSuspended': '有删除的赛事',
      'tax amount': '税额：',
      'final payout': '最终支付：',
      'total bet amount': '总投注额',
      'final bet amount': '最终投注额:'
    }
  },
  tur: {
    translation: {
      'virtualSport': 'Sanal Sporlar',
      'noOpenBets': 'Açık bahis yok.',
      'select': 'Seç',
      'accepted': 'Kabul edildi',
      'cashouted': 'Nakit Çıkışı yapıldı',
      'winReturn': 'Kazanç İade',
      'loseReturn': 'Kayıp İade',
      'always-ask': 'Değişiklikleri kabul et ve bahis yap',
      'suspend': 'Bu etkinlik askıya alındı.',
      'bet settings title': 'Fiyat değiştiğinde',
      'accept high odd': 'Daha yüksek oranları kabul et',
      'always ask': 'Her zaman sor',
      'any odd': 'Herhangi bir olasılığı kabul et',
      'VirtualTennis': 'Sanal Tenis',
      'VirtualFootball': 'Sanal Futbol',
      'VirtualHorseRacing': 'Sanal At Yarışı',
      'VirtualBicycle': 'Sanal Bisiklet',
      'VirtualGreyhounds': 'Sanal Tazı Yarışı',
      'PenaltyKicks': 'Penaltı Atışları',
      'VirtualFootballLeague': 'Sanal Futbol Ligi',
      'DragRacing': 'Drag Yarışı',
      'MarbleRacing': 'Misket Yarışı',
      'additional info': 'Bazı bilgiler varsa ek bahisçi bilgisi',
      'all': 'Tümü',
      'match': 'Maç',
      'quick bet about': 'Miktar girin ve bahis yapmak için herhangi bir orana Tıklayın.',
      'to select bet': 'Bahis seçmek için, lütfen herhangi bir orana tıklayın.',
      'history empty': 'Geçmiş boş.',
      'place your bets': 'Bahislerinizi yapın',
      'virtual': 'Sanal',
      'instant': 'Anlık',
      'results': 'Sonuçlar',
      'upcoming games': 'Yaklaşan oyunlar',
      'match running': ' Bu Maç zaten devam ediyor. Diğer maçların pazarlarını izleyebilirsiniz.',
      'select day': ' Maç gününü seçin',
      'handicaps': 'Handikaplar',
      'total': 'Toplam',
      'half': 'Yarı',
      'set': 'Set',
      'game': 'Oyun',
      'point': 'Puan',
      'player name': 'Oyuncu İsmi',
      'decimal': 'Ondalık',
      'american': 'Amerikan',
      'hongkong': 'HongKong',
      'malay': 'Malayca',
      'indo': 'Hindu',
      'bet slip': 'Bahis Kuponu',
      'my bets': 'Bahislerim',
      'quick bets': 'Hızlı Bahisler',
      'superbet': 'Süper bahis',
      'superbet about': ' Super Bahis, kullanıcıların Maksimum Bahis Limitini aşan bir bahis tutarı ile bahis oynamalarını sağlayan bir özelliktir. Talep operatörler tarafından onaylanabilir veya reddedilebilir.',
      'counter offer': 'Karşı teklif mevcut',
      'counter offer about': 'Karşı Teklif, seçilen spor etkinlikleri için tercih edilebilir oranlar önermeye izin veren yeni bir seçenektir. 1.10\'un üzerindeki oranlarda kullanılabilir.',
      'view all bets': ' Tüm bahislerinizi şurada görüntüleyebilirsiniz:',
      'bet history': 'Bahis Geçmişi',
      'calculator': 'Sistem Hesaplayıcı',
      'total stake': 'Toplam Bahis',
      'combinations': 'Kombinasyonlar',
      'winnings': 'Kazançlar',
      'all bets': 'Tüm Bahisler',
      'open': 'Açık',
      'win': 'Kazandı',
      'lost': 'Kaybetti',
      'return': 'İade',
      'bet ID': 'Bahis Kimliği',
      'bet type': 'Bahis Türü',
      'period': 'Periyot',
      'range': 'Aralık',
      'show': 'Göster',
      'odd': 'Oran',
      'cash-out': 'Nakit çıkışı',
      'add more events': ' Bahis yapabilmek için daha fazla etkinlik ekleyin',
      'quick bets about': ' Bir miktar girin ve bahis yapmak için herhangi bir orana Tıklayın.',
      'not selected': 'Seçilmedi',
      'Germany': 'Almanya',
      'Spain': 'İspanya',
      'England': 'İngiltere',
      'Italy': 'İtalya',
      'France': 'Fransa',
      'Belgium': 'Belçika',
      'Argentina': 'Arjantin',
      'Brazil': 'Brezilya',
      'PartnerNotFound': 'Satış Ortağı kayıtlı değil',
      'BetTypeError': 'Geçerli bahis türünü seçin',
      'BetValidationError': 'Teknik sorun oluştu, lütfen tekrar deneyin',
      'PartnerApiClientBalanceError': 'Yetersiz bakiye',
      'BetSelectionChanged': 'Oranlar değişti',
      'SelectionNotFound': 'Seçim doğru değil',
      'BetAmountError': 'Bahis miktarı izin verilen aralığın dışında',
      'SelectionSuspended': 'Silinen etkinlikler var',
      'live table': 'Live tablo',
      'matches': 'Maçlar',
      'next start in': 'Bir puan nokta şurada başlıyor',
      'player names': 'Oyuncu adları',
      'place bet': 'Bahis Yap',
      'insufficient balance': 'Depozitoda yetersiz bakiye',
      'amount is less': 'Bahis miktarı izin verilen minimumdan az.',
      'clear all': 'Tümünü temizle',
      'single': 'Tekli',
      'multiple': 'Kombine',
      'system': 'Sistem',
      'data id': 'Tarih ve Kimlik',
      'stake': 'Bahis',
      'odd type': 'Tuhaf Şey',
      'odds': 'Oranlar',
      'outcome': 'Sonuç',
      'status': 'Durum',
      'hour': 'saat',
      'custom': 'Özel',
      '1h': '1 saat',
      '2h': '2 saat',
      '3h': '3 saat',
      '6h': '6 saat',
      '12h': '12 saat',
      '24h': '24 saat',
      '48h': '48 saat',
      '72h': '72 saat',
      'sport': 'Spor',
      'competition': 'Yarışma',
      'date': 'Tarih',
      'event': 'Etkinlik',
      'score': 'Skor',
      'search': 'Arama',
      'kg': 'kg',
      'final': 'Final',
      'max bet case': 'Girilen miktar maksimum bahis miktarından fazla.',
      'min bet case': 'Girilen miktar minimum bahisten az.',
      'event not available': 'Bu etkinlik mevcut değil.',
      'bet accepted': 'Bahsiniz kabul edildi.',
      'bet failed': 'Bahsiniz başarısız oldu.',
      'deleted events': 'Silinmiş etkinlikler var',
      'bet in progress': 'Bahsiniz devan ediyor...',
      'possible win': 'Olası kazanç',
      'unsettled': 'Kararsız',
      'last': 'Son',
      'to place bets': 'Lütfen, bahsinizi yapın',
      'sign in': 'giriş yap',
      'or': 'veya',
      'register': 'şimdi kayıt ol!',
      'id': 'KİMLİK:',
    }
  },
  geo: {
    translation: {
      'virtualSport': 'ვირტუალური სპორტი',
      'noOpenBets': 'ღია ფსონები არ არის.',
      'select': 'არჩევა',
      'accepted': 'მიღებულია',
      'cashouted': 'განაღდებულია',
      'winReturn': 'მოგების დაბრუნება',
      'loseReturn': 'წაგების დაბრუნება',
      'always-ask': 'ცვლილებების მიღება და ფსონის დადება',
      'suspend': 'ეს ღონისძიება შეჩერებულია.',
      'bet settings title': 'როდესაც იცვლება კოეფიციენტი',
      'accept high odd': 'უფრო მაღალი კოეფიციენტის მიღება',
      'always ask': 'ყოველთვის კითხვის დასმა',
      'any odd': 'ნებისმიერი კოეფიციენტის მიღება',
      'VirtualTennis': 'ვირტუალური ჩოგბურთი',
      'VirtualFootball': 'ვირტუალური ფეხბურთი',
      'VirtualHorseRacing': 'ვირტუალური დოღი',
      'VirtualBicycle': 'ვირტუალური ველორბოლა',
      'VirtualGreyhounds': 'ვირტუალური გრეიჰაუნდები',
      'PenaltyKicks': 'პენალტის დარტყმები',
      'VirtualFootballLeague': 'ვირტუალური ფეხბურთის ლიგა',
      'DragRacing': 'დრაგ რეისინგი',
      'MarbleRacing': 'მარბლ რეისინგი',
      'player names': 'მოთამაშეების სახელები',
      'matches': 'მატჩები',
      'live table': 'ლაივის ცხრილი',
      'all': 'ყველა',
      'match': 'მატჩი',
      'place bet': 'ფსონის დადება',
      'insufficient balance': 'არასაკმარისი ბალანსი დეპოზიტზე',
      'amount is less': 'ფსონის თანხა არის დაშვებულ მინიმუმზე ნაკლები',
      'clear all': 'ყველას წაშლა',
      'single': 'ორდინარი',
      'multiple': 'ექსპრესი',
      'system': 'სისტემა',
      'data id': 'თარიღი და ID',
      'stake': 'Stake',
      'odd type': 'კოეფიციენტის ტიპი',
      'odds': 'კოეფიციენტები',
      'outcome': 'შედეგი',
      'status': 'სტატუსი',
      'history empty': 'ისტორია ცარიელია.',
      'hour': 'საათი',
      'custom': 'მომხმარებლის',
      '1h': '1 საათი',
      '2h': '2 საათი',
      '3h': '3 საათი',
      '6h': '6 საათი',
      '12h': '12 საათი',
      '24h': '24 საათი',
      '48h': '48 საათი',
      '72h': '72 საათი',
      'sport': 'სპორტი',
      'competition': 'შეჯიბრი',
      'date': 'თარიღი',
      'event': 'ღონისძიება',
      'score': 'ანგარიში',
      'search': 'ძებნა',
      'kg': 'კგ.',
      'final': 'ფინალი',
      'max bet case': 'შეყვანილი თანხა მინიმალურ ფსონზე ნაკლებია',
      'min bet case': 'შეყვანილი თანხა მაქსიმალურ ფსონზე მეტია',
      'event not available': 'ეს ღონისძიება არ არის ხელმისაწვდომი.',
      'bet accepted': 'თქვენი ფსონი მიღებულია.',
      'bet failed': 'თქვენი ფსონი არ არის მიღებული.',
      'deleted events': 'არის წაშლილი ღონისძიებები',
      'bet in progress': 'თქვენი ფსონი მუშავდება...',
      'possible win': 'სავარაუდო მოგება',
      'unsettled': 'დაუმუშავებელი',
      'last': 'ბოლო',
      'next start in': 'შემდეგი პუნქტი იწყება',
      'to place bets': 'ფსონის დასადებად, გთხოვთ',
      'sign in': 'ავტორიზაცია',
      'or': 'ან',
      'register': 'დარეგისტრირდით ახლა!',
      'place your bets': 'განათავსეთ თქვენი ფსონები',
      'virtual': 'ვირტუალური',
      'instant': 'მომენტალური',
      'results': 'შედეგები',
      'upcoming games': 'მომავალი თამაშები',
      'match running': 'ეს მატჩი უკვე მიმდინარეობს. შეგიძლიათ უყუროთ სხვა მატჩების მარკეტებს.',
      'select day': 'შეარჩიეთ მატჩის დღე',
      'handicaps': 'ჰანდიკაპები',
      'total': 'ტოტალი',
      'half': 'ტაიმი',
      'set': 'სეტი',
      'game': 'გეიმი',
      'point': 'ქულა',
      'player name': 'მოთამაშის სახელი',
      'decimal': 'ათწილადი',
      'american': 'ამერიკული',
      'hongkong': 'ჰონგ კონგი',
      'malay': 'მალაიზიური',
      'indo': 'ინდო',
      'bet slip': 'ფსონის ბილეთი',
      'my bets': 'ჩემი ფსონები',
      'quick bets': 'სწრაფი ფსონები',
      'superbet': 'სუპერბეთი',
      'superbet about': 'სუპერბეთი არის ფუნქცია, რომელიც მომხმარებლებს საშუალებას აძლევს მოითხოვონ ფსონი, რომელიც აჭარბებს ფსონის მაქს. დასაშვებ ოდენობას. მოთხოვნა შეიძლება დამტკიცდეს ან უარყოფილი იქნას ბუკმეიკერების მიერ.',
      'counter offer': 'შემხვედრი შემოთავაზება ხელმისაწვდომია',
      'counter offer about': 'შემხვედრი შემოთავაზება არის ახალი შესაძლებლობა, რომელიც საშუალებას გაძლევთ მიიღოთ სასურველი კოეფიციენტები შერჩეული სპორტული ღონისძიებებისთვის. ხელმისაწვდომია 1.10 ზე მეტი კოეფიციენტისთვის.',
      'view all bets': 'თქვენი ფსონების ნახვა შეგიძლიათ აქ',
      'bet history': 'ფსონის ისტორია',
      'calculator': 'სისტემის კალკულატორი',
      'total stake': 'ჯამური ფსონი',
      'combinations': 'კომბინაციები',
      'winnings': 'მოგება',
      'all bets': 'ყველა ფსონი',
      'open': 'გახსნა',
      'win': 'მოგება',
      'lost': 'წაგება',
      'return': 'დაბრუნება',
      'bet ID': 'ფსონის ID',
      'bet type': 'ფსონის ტიპი',
      'period': 'პერიოდი',
      'range': 'დიაპაზონი',
      'show': 'ჩვენება',
      'odd': 'კოეფიციენტი',
      'cash-out': 'ქეშ-აუთი',
      'add more events': 'დაამატეთ ღონისძიება ფსონის დასადებად',
      'quick bets about': 'შეიყვანეთ თანხა და დააჭირეთ ნებისმიერ კოეფიციენტზე ფსონის დასადებად.',
      'not selected': 'არ არის შერჩეული',

      'Germany': 'გერმანია',
      'Spain': 'ესპანეთი',
      'England': 'ინგლისი',
      'Italy': 'იტალია',
      'France': 'საფრანგეთი',
      'Belgium': 'ბელგია',
      'Argentina': 'არგენტინა',
      'Brazil': 'ბრაზილია',

      'PartnerNotFound': 'პარტნიორი არ არის რეგისტრირებული',
      'BetTypeError': 'აირჩიეთ სწორი ფსონის ტიპი',
      'BetValidationError': 'ტექნიკური პრობლემა, გთხოვთ, სცადოთ ხელახლა',
      'PartnerApiClientBalanceError': 'არასაკმარისი ბალანსი',
      'BetSelectionChanged': 'კოეფიციენტები შეიცვალა',
      'SelectionNotFound': 'შერჩევა არ არის სწორი',
      'BetAmountError': 'ფსონის თანხა დაშვებულ დიაპაზონს სცილდება',
      'SelectionSuspended': 'არის წაშლილი ღონისძიებები',
    }
  },
  arb: {
    translation: {
      'virtualSport': 'الرياضات الافتراضية',
      'noOpenBets': ' لا توجد رهانات مفتوحة. ',
      'select': 'حدد',
      'accepted': ' مقبولة',
      'cashouted': 'تم صرف النقود',
      'winReturn': 'عائد الربح',
      'loseReturn': 'عائد الخسارة',
      'always-ask': 'اقبل التغييرات وقم بوضع رهان',
      'suspend': ' تم تعليق هذا الحدث.',
      'bet settings title': 'عندما يتغير المعامل ',
      'accept high odd': 'قبول المعامل الأعلى ',
      'always ask': 'اسال دائماً ',
      'any odd': 'قبول أي معامل',


      'virtual': 'افتراضي',
      'instant': 'فوري',
      'VirtualTennis': 'كرة المضرب الافتراضية',
      'VirtualFootball': 'كرة القدم الافتراضية',
      'VirtualHorseRacing': 'سباق الخيل الافتراضية',
      'VirtualBicycle': 'سباق الدراجات الهوائية الافتراضية',
      'VirtualGreyhounds': 'سباق الكلاب السلوقية الافتراضية',
      'PenaltyKicks': 'ركلات الجزاء الترجيحية',
      'VirtualFootballLeague': 'دوري كرة القدم الافتراضية',
      'DragRacing': 'دراج ريسينج',
      'MarbleRacing': 'مارابيل ريسينج',
      'results': 'النتائج',
      'additional info': 'معلومات مرجعية إضافية إذا كان هناك بعض المعلومات',
      'upcoming games': 'الألعاب القادمة',
      'player names': 'أسماء اللاعبين',
      'match running': 'هذه المباراة جارية بالفعل. يمكنك مشاهدة أسواق المباريات الأخرى.',
      'select day': 'حدد يوم المباراة',
      'matches': 'المباريات',
      'live table': 'الطاولة الحية',
      'all': 'الكل',
      'match': 'المباراة',
      'handicaps': 'الهانديكابات',
      'total': 'مجموع',
      'half': 'الشوط',
      'set': 'المجموعة',
      'game': 'اللعبة',
      'point': 'نقطة',
      'player name': 'اسم اللاعب',
      'decimal': 'عشري',
      'american': 'أمريكي',
      'hongkong': 'هونغ كونغ',
      'malay': 'مالاي',
      'indo': 'إندو',
      'bet slip': 'قسيمة الرهان',
      'place bet': 'ضع رهان',
      'insufficient balance': 'رصيد المبلغ المودع غير كاف',
      'amount is less': 'مبلغ الرهان أقل من الحد الأدنى المسموح به.',
      'my bets': 'رهاناتي',
      'clear all': 'مسح الكل',
      'quick bets': 'الرهانات السريعة',
      'superbet': 'سوبر بيت',
      'superbet about': 'سوبر بيت هي ميزة تتيح للمستخدمين طلب وضع رهان بمبلغ يتجاوز الحد الأقصى للرهان. قد يتم الموافقة على الطلب أو رفضه من قبل المتداولين.',
      'counter offer': 'العرض المضاد متاح',
      'counter offer about': 'العرض المضاد هو خيار جديد يسمح باقتراح معاملات مفضلة لأحداث رياضية محددة. متاح في معاملات أعلى من 1.10 .',
      'to select bet': 'يرجى النقر على أي معامل لتحديد رهان',
      'view all bets': 'يمكنك عرض كل رهاناتك في',
      'bet history': 'سجل الرهان',
      'calculator': 'حاسبة النظام',
      'total stake': 'مجموع كميات الرهان',
      'combinations': 'المجموعات',
      'winnings': 'المكاسب',
      'all bets': 'كل الرهانات',
      'open': 'فتح',
      'win': 'فوز',
      'lost': 'خسارة',
      'return': 'إرجاع',
      'bet ID': 'معرف الرهان',
      'bet type': 'نوع الرهان',
      'single': 'فردي',
      'multiple': 'متعدد',
      'system': 'نظام',
      'period': 'الفترة',
      'range': 'نطاق',
      'show': 'عرض',
      'data id': 'التاريخ و المعرف',
      'stake': 'كمية الرهان',
      'odd type': 'نوع المعامل',
      'odd': 'معامل',
      'odds': 'معاملات',
      'outcome': 'النتيجة',
      'status': 'الحالة',
      'history empty': 'السجل فارغ.',
      'hour': 'ساعة',
      'custom': 'مخصصة',
      '1h': 'ساعة 1',
      '2h': 'ساعتين 2',
      '3h': '3 ساعات',
      '6h': '6 ساعات',
      '12h': '12 ساعة',
      '24h': '24 ساعة',
      '48h': '48 ساعة',
      '72h': '72 ساعة',
      'sport': 'الرياضة',
      'competition': 'المنافسة',
      'date': 'التاريخ',
      'event': 'الحدث',
      'score': 'النتيجة',
      'search': 'بحث',
      'kg': 'كغ',
      'final': 'نهائي',
      'max bet case': 'المبلغ الذي تم إدخاله أكبر من الحد الأقصى لمبلغ الرهان.',
      'min bet case': 'المبلغ الذي تم إدخاله أقل من الحد الأدنى للرهان.',
      'event not available': 'هذا الحدث غير متوفر.',
      'bet accepted': 'رهانك مقبول.',
      'bet failed': 'فشل رهانك.',
      'deleted events': 'هناك أحداث محذوفة',
      'bet in progress': 'رهانك قيد التقدم...',
      'possible win': 'مكسب محتمل',
      'unsettled': 'لم تجري تسويتها',
      'last': 'آخر',
      'next start in': 'تبدأ النقطة التالية في',
      'to place bets': 'لوضع رهانك من فضلك',
      'sign in': 'تسجيل الدخول',
      'or': 'أو',
      'register': 'سجل الآن!',
      'cash-out': 'صرف النقود',
      'add more events': 'أضف المزيد من الأحداث لتتمكن من وضع رهان',
      'quick bets about': 'أدخل مبلغاً و انقر على أي معامل لوضع رهان',
      'not selected': 'لم يتم تحديده',
      'place your bets': 'ضع رهاناتك',
      'id': 'المعرف:',

      'Germany': 'ألمانيا',
      'Spain': 'إسبانيا',
      'England': 'إنجلترا',
      'Italy': 'إيطاليا',
      'France': 'فرنسا',
      'Belgium': 'بلجيكا',
      'Argentina': 'الأرجنتين',
      'Brazil': 'البرازيل',

      'PartnerNotFound': 'الشريك غير مسجل',
      'BetTypeError': 'حدد نوع رهان صالح',
      'BetValidationError': 'حدثت مشكلة فنية، يرجى المحاولة مرة أخرى',
      'PartnerApiClientBalanceError': 'رصيد غير كاف',
      'BetSelectionChanged': 'تم تغيير المعامل',
      'SelectionNotFound': 'التحديد غير صحيح',
      'BetAmountError': 'كمية الرهان خارج النطاق المسموح به',
      'SelectionSuspended': 'هناك أحداث محذوفة',

    }
  },
  ger: {
    translation: {
      'virtualSport': 'Virtuelle Sportarten',
      'noOpenBets': 'Es gibt keine offenen Wetten.',
      'select': 'Wählen',
      'accepted': 'Akzeptiert',
      'cashouted': 'Ausgezahlt',
      'winReturn': 'Gewinnrückgabe',
      'loseReturn': 'Verlustrückgabe',
      'always-ask': 'Änderungen akzeptieren und Wette platzieren',
      'suspend': 'Diese Veranstaltung wurde suspendiert.',
      'bet settings title': 'Wenn die Quote geändert wird',
      'accept high odd': 'Höhere Quoten akzeptieren',
      'always ask': 'Immer fragen',
      'any odd': 'Beliebige Quote akzeptieren',

      'virtual': 'Virtuell',
      'instant': 'Sofortig',
      'VirtualTennis': 'Virtuelles Tennis',
      'VirtualFootball': 'Virtueller Fußball',
      'VirtualHorseRacing': 'Virtuelles Pferderennen',
      'VirtualBicycle': 'Virtuelles Fahrrad',
      'VirtualGreyhounds': 'Virtuelle Windhunde',
      'PenaltyKicks': 'Strafstöße',
      'VirtualFootballLeague': 'Virtuelle Fußball Liga',
      'DragRacing': 'Beschleunigungsrennen',
      'MarbleRacing': 'Murmelrennen',
      'results': 'Ergebnisse',
      'additional info': 'Zusätzliche Buchmacher-Informationen, wenn es diese gibt',
      'upcoming games': 'Kommende Spiele',
      'player names': 'Spieler-Namen',
      'match running': 'Dieses Spiel läuft bereits. Sie können sich die Märkte anderer Spiele ansehen.',
      'select day': 'Spieltag auswählen',
      'matches': 'Spiele',
      'live table': 'Live Tabelle',
      'all': 'Alle',
      'match': 'Spiel',
      'handicaps': 'Handicaps',
      'total': 'Gesamtzahl',
      'half': 'Halbzeit',
      'set': 'Satz',
      'game': 'Game',
      'point': 'Punkt',
      'player name': 'Spieler-Name',
      'decimal': 'Dezimal',
      'american': 'Amerikanisch',
      'hongkong': 'Hongkong',
      'malay': 'Malaiisch',
      'indo': 'Indo',
      'bet slip': 'Wettschein',
      'place bet': 'Wette platzieren',
      'insufficient balance': 'Unzureichende Bilanz. Einzahlen',
      'amount is less': 'Wettbetrag ist geringer als der zulässige Mindestbetrag.',
      'my bets': 'Meine Wetten',
      'clear all': 'Alle löschen',
      'quick bets': 'Schnelle Wetten',
      'superbet': 'Superbet',
      'superbet about': 'SuperBet ist eine Funktion, mit der Benutzer das Platzieren einer Wette mit einem Einsatzbetrag anfordern können, der das maximale Wettlimit überschreitet. Die Anfrage kann von Tradern angenommen oder abgelehnt werden.',
      'counter offer': 'Gegenangebot ist verfügbar',
      'counter offer about': 'Das Gegenangebot ist eine neue Option, mit der Sie bevorzugte Quoten für ausgewählte Sportereignisse vorschlagen können. Verfügbar bei Quoten über 1,10.',
      'to select bet': 'Um eine Wette zu wählen, klicken Sie bitte auf eine Quote.',
      'view all bets': 'Sie können alle Ihre Wetten ansehen in',
      'bet history': 'Wettverlauf',
      'calculator': 'System-Rechner',
      'total stake': 'Gesamteinsatz',
      'combinations': 'Kombis',
      'winnings': 'Gewinne',
      'all bets': 'Alle Wetten',
      'open': 'Offen',
      'win': 'Gewinn',
      'lost': 'Verlust',
      'return': 'Rückgabe',
      'bet ID': 'Wett-ID',
      'bet type': 'Wetttyp',
      'single': 'Einzel',
      'multiple': 'Mehrfach',
      'system': 'System',
      'period': 'Periode',
      'range': 'Bereich',
      'show': 'Anzeigen',
      'data id': 'Datum und ID',
      'stake': 'Einsatz',
      'odd type': 'Quoten-Typ',
      'odd': 'Quote',
      'odds': 'Quoten',
      'outcome': 'Ergebnis',
      'status': 'Status',
      'history empty': 'Verlauf ist leer.',
      'hour': 'Stunde',
      'custom': 'Benutzerdefiniert',
      '1h': '1 Stunde',
      '2h': '2 Stunden',
      '3h': '3 Stunden',
      '6h': '6 Stunden',
      '12h': '12 Stunden',
      '24h': '24 Stunden',
      '48h': '48 Stunden',
      '72h': '72 Stunden',
      'sport': 'Sport',
      'competition': 'Wettbewerb',
      'date': 'Datum',
      'event': 'Ereignis',
      'score': 'Punktzahl',
      'search': 'Suche',
      'kg': 'kg',
      'final': 'Finale',
      'max bet case': 'Eingegebener Betrag ist größer als der maximale Wettbetrag.',
      'min bet case': 'Eingegebener Betrag ist geringer als die Mindestwette.',
      'event not available': 'Dieses Ereignis ist nicht verfügbar.',
      'bet accepted': 'Ihre Wette wurde akzeptiert.',
      'bet failed': 'Ihre Wette ist fehlgeschlagen.',
      'deleted events': 'Es gibt gelöschte Ereignisse',
      'bet in progress': 'Ihre Wette wird bearbeitet...',
      'possible win': 'Möglicher Gewinn',
      'unsettled': 'Nicht berechnet',
      'last': 'Letzte',
      'next start in': 'Nächster Punkt beginnt in',
      'to place bets': 'Um Ihre Wette zu platzieren,',
      'sign in': 'melden Sie sich bitte an',
      'or': 'oder',
      'register': 'registrieren Sie sich jetzt!',
      'cash-out': 'Auszahlung',
      'add more events': 'Fügen Sie weitere Ereignisse hinzu, um eine Wette platzieren zu können',
      'quick bets about': 'Geben Sie einen Betrag ein und klicken Sie auf eine Quote, um eine Wette zu platzieren.',
      'not selected': 'Nicht ausgewählt',
      'place your bets': 'Platzieren Sie Ihre Wetten',
      'id': 'ID:',

      'Germany': 'Deutschland',
      'Spain': 'Spanien',
      'England': 'England',
      'Italy': 'Italien',
      'France': 'Frankreich',
      'Belgium': 'Belgien',
      'Argentina': 'Argentinien',
      'Brazil': 'Brasilien',

      'PartnerNotFound': 'Partner ist nicht registriert',
      'BetTypeError': 'Einen gültigen Wetttyp wählen',
      'BetValidationError': 'Technisches Problem aufgetreten, bitte versuchen Sie es erneut',
      'PartnerApiClientBalanceError': 'Unzureichende Bilanz',
      'BetSelectionChanged': 'Quoten wurden geändert',
      'SelectionNotFound': 'Falsche Auswahl',
      'BetAmountError': 'Einsatzbetrag liegt außerhalb des zulässigen Bereichs',
      'SelectionSuspended': 'Es gibt gelöschte Events',
    }
  },
  kor: {
    translation: {
      'virtualSport': '가상 스포츠',
      'noOpenBets': '오픈 베팅이 없습니다.',
      'select': '고르기',
      'accepted': '수락 됨',
      'cashouted': '현금 인출 됨',
      'winReturn': '승리 반환',
      'loseReturn': '손실 반환',
      'always-ask': '변경 사항 수락 및 베팅',
      'suspend': '이 이벤트는 일시 중지됨.',
      'bet settings title': '가격이 변할 때',
      'accept high odd': '더 높은 배당률 수락',
      'always ask': '항상 묻기',
      'any odd': '모든 배당률 수락',
      'new feature': '새로운 기능',
      'find bets': '여기에서 내 베팅을 확인하세요.',
      'dont show': '다시 보지 않기',

      'virtual': '가상',
      'instant': '인스턴트',
      'VirtualTennis': '가상 테니스',
      'VirtualFootball': '가상 축구',
      'VirtualHorseRacing': '가상 경마',
      'VirtualBicycle': '가상 자전거',
      'VirtualGreyhounds': '가상 그레이하운드',
      'PenaltyKicks': '페널티 킥',
      'VirtualFootballLeague': '가상 풋볼 리그',
      'DragRacing': '드래그 레이싱',
      'MarbleRacing': '마블 레이싱',
      'results': '결과',
      'additional info': '정보가 있는 경우 추가 북마크 정보',
      'upcoming games': '예정된 게임',
      'player names': '플레이어 이름',
      'match running': '이 경기는 이미 진행 중입니다. 다른 경기의 마켓을 볼 수 있습니다.',
      'select day': '경기 일 선택',
      'matches': '경기',
      'live table': '라이브 테이블',
      'all': '모두',
      'match': '경기',
      'handicaps': '핸디캡',
      'total': '총',
      'half': '하프',
      'set': '세트',
      'game': '게임',
      'point': '포인트',
      'player name': '플레이어 이름',
      'decimal': '소수',
      'american': '미국',
      'hongkong': '홍콩',
      'fractional': '분수의',
      'malay': '말레이시아',
      'indo': '인도',
      'bet slip': '베팅 슬립',
      'place bet': '베팅하기',
      'insufficient balance': '잔액보증금부족',
      'amount is less': '베팅 금액이 허용되는 최소 금액보다 적습니다.',
      'my bets': '내 베팅',
      'clear all': '모두 지우기',
      'quick bets': '빠른 베팅',
      'superbet': '슈퍼베팅',
      'superbet about': '슈퍼베팅은 사용자가 최대 베팅 한도를 초과하는 베팅 금액으로 베팅을 요청할 수 있는 기능입니다. 거래자는 요청을 승인하거나 거부 할 수 있습니다.',
      'counter offer': '카운터 제공 가능',
      'counter offer about': '카운터 오퍼는 선택한 스포츠 이벤트에 대해 선호하는 배당률을 제안 할 수 있는 새로운 옵션입니다. 1.10 이상의 배당률에서 사용이 가능합니다.',
      'to select bet': '베팅을 선택하시려면 임의의 확률을 클릭하십시오.',
      'view all bets': ' ...에서 모든 베팅을 볼 수 있습니다. ',
      'bet history': '베팅 내역',
      'calculator': '시스템 계산기',
      'total stake': '총 베팅',
      'combinations': '조합',
      'winnings': '상금',
      'all bets': '모든 베팅',
      'open': '열기',
      'win': '승리',
      'lost': '패배',
      'return': '반환',
      'bet ID': '베팅 ID',
      'bet type': '베팅 유형',
      'single': '단일',
      'multiple': '배수',
      'system': '시스템',
      'period': '기간',
      'range': '범위',
      'show': '쇼',
      'data id': '날짜 및 ID ',
      'stake': '베팅',
      'odd type': '홀수 유형',
      'odd': '홀수',
      'odds': '확률',
      'outcome': '결과',
      'status': '상태',
      'history empty': '내역이 없습니다.',
      'hour': '시간',
      'custom': '커스텀',
      '1h': '1 시간',
      '2h': '2 시간',
      '3h': '3 시간',
      '6h': '6 시간',
      '12h': '12 시간',
      '24h': '24 시간',
      '48h': '48 시간',
      '72h': '72 시간',
      'sport': '스포츠',
      'competition': '경쟁',
      'date': '날짜',
      'event': '이벤트',
      'score': '점수',
      'search': '검색',
      'kg': 'kg',
      'final': '최종',
      'max bet case': '입력한 금액이 최대 베팅 금액보다 큽니다.',
      'min bet case': '입력한 금액이 최소 베팅 금액보다 적습니다.',
      'event not available': '이 이벤트는 사용할 수 없습니다.',
      'bet accepted': '귀하의 베팅이 수락되었습니다.',
      'bet failed': '베팅이 실패되었습니다.',
      'deleted events': '삭제 된 이벤트가 있습니다',
      'bet in progress': '귀하의 베팅은 진행 중입니다...',
      'possible win': '가능한 승리',
      'unsettled': '미결제',
      'last': '마지막',
      'next start in': '다음 포인트는 ... 후에 시작됩니다.',
      'to place bets': '베팅을 하시려면',
      'sign in': '로그인',
      'or': '또는',
      'register': '등록하세요!',
      'cash-out': '현금 인출',
      'add more events': '베팅을 할 수 있도록 더 많은 이벤트를 추가하세요',
      'quick bets about': '금액을 입력하고 홀수를 클릭하여 베팅하십시오.',
      'not selected': '선택되지 않은',
      'place your bets': '베팅하세요',
      'id': 'ID:',

      'Germany': '독일',
      'Spain': '스페인',
      'England': '잉글랜드',
      'Italy': '이탈리아',
      'France': '프랑스',
      'Belgium': '벨기에',
      'Argentina': '아르헨티나',
      'Brazil': '브라질',

      'PartnerNotFound': '파트너가 등록되지 않았습니다. ',
      'BetTypeError': '유효한 배팅 유형을 선택하십시오',
      'BetValidationError': '기술적인 문제가 발생했습니다. 다시 시도해 주십시오.',
      'PartnerApiClientBalanceError': '잔액 불충분',
      'BetSelectionChanged': '확률이 변경되었습니다',
      'SelectionNotFound': '선택이 올바르지 않습니다.',
      'BetAmountError': '판돈 금액이 허용 범위를 벗어났습니다.',
      'SelectionSuspended': '삭제된 이벤트가 있습니다.',
    }
  },
  jpn: {
    translation: {
      "virtual": "バーチャル",
      "instant": "インスタント",
      "VirtualTennis": "バーチャル・テニス",
      "VirtualFootball": "バーチャル・フットボール",
      "VirtualHorseRacing": "バーチャル・競馬",
      "VirtualBicycle": "バーチャル・競輪",
      "VirtualGreyhounds": "バーチャル・グレイハウンド",
      "PenaltyKicks": "バーチャル・ペナルティキック",
      "VirtualFootballLeague": "バーチャル・フットボールリーグ",
      "DragRacing": "バーチャル・ドラッグレース",
      "MarbleRacing": "バーチャル・マーブルレーシング",
      "results": "結果",
      "additional info": "情報がある場合は、追加のブックマーカ",
      "upcoming games": "試合予定",
      "player names": "プレイヤー名",
      "match running": "この試合はすでに終了しております。他の市場をご覧ください。",
      "select day": "試合予定日",
      "matches": "試合",
      "live table": "ライブ・テーブル",
      "all": "全部",
      "match": "試合",
      "handicaps": "ハンディキャップ",
      "total": "合計",
      "half": "ハーフ",
      "set": "セット",
      "game": "ゲーム",
      "point": "ポイント",
      "player name": "プレイヤー名",
      "decimal": "小数点",
      "american": "アメリカ",
      "fractional": "分数",
      "hongkong": "香港",
      "malay": "マレーシア",
      "indo": "インド",
      "bet slip": "ベットスリップ",
      "place bet": "賭けを行う",
      "insufficient balance": "残高不足",
      "amount is less": "最低額以下の賭け金額",
      "my bets": "ベット履歴",
      "clear all": "全消去",
      "quick bets": "クィックベット",
      "superbet": "スーパー・ベット",
      "superbet about": "スーパー・ベットは、ユーザーに対する最高ベット限度を超える賭けを行うリクエストを可能にする特徴です。こうしたリクエストがトレーダーによって認可または拒否される場合がある。",
      "counter offer": "カウンター・オファーは利用できる",
      "counter offer about": "カウンター・オファーは、特定スポーツイベントに対するむしろ望ましいオッズを提案可能にする新たなオプションです。 1.10以上のオッズで利用できる。",
      "to select bet": "賭けを選択するには、あるオッズをクリックしてください。",
      "view all bets": "こちらにベット履歴が表示されます",
      "bet history": "ベット履歴の詳細",
      "calculator": "計算機システム",
      "total stake": "賭け合計",
      "combinations": "コンビネーション",
      "winnings": "勝利",
      "all bets": "全賭け",
      "open": "開く",
      "win": "勝つ",
      "lost": "負ける",
      "return": "戻る",
      "bet ID": "賭けID",
      "bet type": "賭け種類",
      "single": "シングル",
      "multiple": "マルチプル",
      "system": "システム",
      "period": "期間",
      "range": "範囲",
      "show": "示す",
      "data id": "日付とID",
      "stake": "賭け金額",
      "odd type": "オッズ種類",
      "odd": "オッズ",
      "save": "保存する",
      "edit": "編集する",
      "odds": "オッズ",
      "outcome": "成果",
      "status": "ステータス",
      "history empty": "歴史は空です。",
      "hour": "時間",
      "custom": "カスタム",
      "1h": "1時間",
      "2h": "2時間",
      "3h": "3時間",
      "6h": "6時間",
      "12h": "12時間",
      "24h": "24時間",
      "48h": "48時間",
      "72h": "72時間",
      "sport": "スポーツ",
      "competition": "競争",
      "date": "日付",
      "event": "イベント",
      "score": "スコア",
      "search": "検索",
      "kg": "キロ",
      "final": "決勝",
      "max bet case": "入力した金額が許される最大賭け金額よりも大きい。",
      "min bet case": "入力した金額が許される最低賭け金額よりも少ない。",
      "event not available": "このイベントは利用できません。",
      "bet accepted": "ご賭けは受け付けた。",
      "bet failed": "ご賭けは失敗した。",
      "deleted events": "削除されたイベントがある。",
      "bet in progress": "ご賭けは進行中である...",
      "possible win": "可能な勝利",
      "unsettled": "未決済",
      "last": "最後",
      "next start in": "次のポイントまでの待ち時間",
      "to place bets": "ベットを行うには",
      "sign in": "ログイン",
      "or": "または",
      "register": "会員登録",
      "cash-out": "キャッシュアウト",
      "add more events": "賭けを行うことができるようにイベントを追加する",
      "quick bets about": "賭ける金額を保存して、オッズをクリックすると自動的にベットが行われます。",
      "not selected": "未選択",
      "place your bets": "ベットを賭けてください",
      "id": "ID",
      "Germany": "ドイツ",
      "Spain": " スペイン",
      "England": "イングランド",
      "Italy": "イタリア",
      "France": "フランス",
      "Belgium": "ベルギー",
      "Argentina": "アルゼンチン",
      "Brazil": "ブラジル",
      'PartnerNotFound' : '有効なレートタイプを選択してください',
      'BetTypeError':'有効なベットタイプを選択してください',
      'BetValidationError':'技術的な問題が発生しました、もう一度お試しください',
      'PartnerApiClientBalanceError':'バランス不足',
      'BetSelectionChanged':'オッズが変更されました',
      'SelectionNotFound':'セレクションは正しくない',
      'BetAmountError': '賭け金の額が許容範囲外です',
      'SelectionSuspended':'削除されたイベントがある',
      'virtualSport': '「バーチャル・スポーツ」',
      'noOpenBets': '「開いた賭けはありません」',
      'select': '「選択する」',
      'accepted': '「受け付けた」',
      'cashouted': '「キャッシュアウト」',
      'winReturn': '「勝利戻り」',
      'loseReturn': '「負け戻り」',
      'always-ask': '「変更を受け入れて賭けを行う」',
      'suspend': '「本イベントは停止中である。」',
      'bet settings title': '「価格変更時」',
      'accept high odd': '「より高いオッズを受け入れる」',
      'always ask': '「いつも尋ねる」',
      'any odd': '「あらゆるオッズを受け入れる」',
      'new feature': '新機能',
      'find bets': 'ベットスリップを開く',
      'dont show': '今後表示しない',
    }
  },
  pol: {
    translation: {
      'virtualSport': 'Sporty wirtualne',
      'noOpenBets': 'Nie ma otwartych zakładów',
      'select': 'Wybrać',
      'accepted': 'Przyjęto',
      'cashouted': 'Wypłata gotówki',
      'winReturn': 'Zwrot Wygranej',
      'loseReturn': 'Zwrot Przegranej',
      'always-ask': 'Zaakceptuj zmiany i postaw zakład',
      'suspend': 'Dane wydarzenie zostało wstrzymanes',
      'bet settings title': 'Kiedy współczynnik się zmienia',
      'accept high odd': 'Zaakceptować wyższe współczynniki',
      'always ask': 'Zawsze pytać',
      'any odd': 'Akceptować wszelkie współczynniki',
      'virtual': 'Wirtualny',
      'instant': 'Natychmiast',
      'VirtualTennis': 'Wirtualny Tenis',
      'VirtualFootball': 'Wirtualna Piłka Nożna',
      'VirtualHorseRacing': 'Wirtualne Wyścigi Konne',
      'VirtualBicycle': 'Wirtualne Kolarstwo',
      'VirtualGreyhounds': ' Wirtualne Wyścigi Chartów ',
      'PenaltyKicks': 'Rzuty Karne',
      'VirtualFootballLeague': 'Wirtualna Liga Piłki Nożnej',
      'DragRacing': ' Wyścigi Samochodowe',
      'MarbleRacing': 'Marble Racin',
      'results': 'Wyniki',
      'additional info': 'Dodatkowe informacje o zakładkach, jeśli są dostępne informacje',
      'upcoming games': 'Nadchodzące gry',
      'player names': 'Imiona zawodników',
      'match running': 'Ten mecz jest już w trakcie rozgrywki. Można obejrzeć markety innych meczów.',
      'select day': 'Wybierz dzień meczu',
      'matches': 'Mecze',
      'live table': 'Live Table',
      'all': 'Wszystko',
      'match': 'Mecz',
      'handicaps': 'Handicapy',
      'total': 'Suma',
      'half': 'Połowa',
      'set': 'Set',
      'game': 'Gra',
      'point': 'Punkt',
      'player name': 'Imię zawodnika',
      'decimal': 'Dziesiętny',
      'american': 'Amerykański',
      'hongkong': 'Hongkoński',
      'malay': 'Mlajski',
      'indo': 'Indonezyjski',
      'bet slip': 'Kupon',
      'place bet': 'Obstaw',
      'insufficient balance': 'Niewystarczające saldo Depozytu',
      'amount is less': 'Kwota zakładu jest mniejsza niż min. dozwolona.',
      'my bets': 'Moje Zakłady',
      'clear all': 'Wyczyść wszystko',
      'quick bets': 'Szybkie Zakłady',
      'superbet': 'Super Bet',
      'superbet about': 'Super Bet to funkcja, która pozwala użytkownikom na postawienie zakładu o wysokości stawki przekraczającej maksymalny limit zakładu. Żądanie to może zostać zatwierdzone lub odrzucone przez traderów.',
      'counter offer': 'Kontr-oferta dostępna',
      'counter offer about': 'Kontr-oferta to nowa opcja, która pozwala na sugerowanie preferowanych kursów na wybrane wydarzenia sportowe. Dostępna przy kursach powyżej 1.10.',
      'to select bet': 'Aby wybrać zakład, prosimy wybrać dowolny kurs.',
      'view all bets': 'Można zobaczyć wszystkie swoje zakłady w',
      'bet history': 'Historia Zakładów',
      'calculator': 'Kalkulator Systemu',
      'total stake': 'Suma stawki',
      'combinations': 'Kombinacje',
      'winnings': 'Wygrane',
      'all bets': ' Wszystkie Zakłady ',
      'open': 'Otwórz',
      'win': 'Wygrana',
      'lost': 'Przegrana',
      'return': 'Zwrot',
      'bet ID': 'ID zakładu',
      'bet type': 'Rodzaj Zakładu',
      'single': 'Pojedynczy',
      'multiple': 'Wielokrotne',
      'system': 'System',
      'period': ' Okres ',
      'range': 'Zakres',
      'show': 'Pokaż',
      'data id': 'Data oraz ID',
      'stake': 'Stawka',
      'odd type': 'Typ Kursu/współczynnika',
      'odd': 'Kurs',
      'odds': 'Kursy',
      'outcome': 'Wynik',
      'status': 'Status',
      'history empty': 'Historia jest pusta.',
      'hour': 'Godzina',
      'custom': 'Indywidualne',
      '1h': '1 godzina',
      '2h': '2 godziny',
      '3h': '3 godziny',
      '6h': '6 godzin',
      '12h': '12 godzin',
      '24h': '24 godziny',
      '48h': '48 godzin',
      '72h': '72 godziny',
      'sport': 'Sport',
      'competition': 'Konkurencja',
      'date': 'Data',
      'event': 'Wydarzenie',
      'score': 'Wynik',
      'search': 'Szukaj',
      'kg': 'kg',
      'final': 'Finał',
      'max bet case': 'Wprowadzona suma jest większa niż maksymalna kwota zakładu.',
      'min bet case': ' Wprowadzona suma jest mniejsza niż minimalna kwota zakładu.',
      'event not available': 'Dane wydarzenie jest niedostępne.',
      'bet accepted': 'Zakład został przyjęty',
      'bet failed': 'Zakład jest nieudany.',
      'deleted events': 'Zdarzenia zostały usunięte',
      'bet in progress': 'Zakład jest w trakcie realizacji...',
      'possible win': 'Możliwa wygrana',
      'unsettled': ' Nierozliczone',
      'last': 'Ostatnie',
      'next start in': ' Kolejny punkt zaczyna się w',
      "to place bets": "Aby postawić zakład, najpierw",
      "sign in": "ZALOGUJ SIĘ",
      "or": "lub",
      "register": "ZAREJESTRUJ SIĘ",
      'cash-out': 'Cash-out',
      'add more events': 'Dodaj więcej wydarzeń, aby móc obstawiać zakłady',
      'quick bets about': 'Wprowadź kwotę i kliknij na dowolny kurs, aby postawić zakład',
      'not selected': 'Nie wybrano',
      'place your bets': 'Obstawiaj zakłady',
      'id': 'ID:',
      'Germany': 'Niemcy',
      'Spain': 'Hiszpania',
      'England': 'Anglia',
      'Italy': 'Włochy',
      'France': 'Francja',
      'Belgium': 'Belgia',
      'Argentina': 'Argentyna',
      'Brazil': 'Brazylia',
      'PartnerNotFound': 'Partner nie jest zarejestrowany',
      'BetTypeError': 'Wybierz prawidłowy typ zakładu',
      'BetValidationError': 'Proszę spróbować ponownie',
      'PartnerApiClientBalanceError': 'Niewystarczające saldo ',
      'BetSelectionChanged': 'Kursy zostały zmienione ',
      'SelectionNotFound': 'Wybór jest nieprawidłowy',
      'BetAmountError': 'Kwota stawki jest poza dozwolonym zakresem',
      'SelectionSuspended': 'Wydarzenia zostały usunięte',
      'tax amount': 'Kwota podatku:',
      'final payout': 'Ostateczna wypłata:',
      'total bet amount': 'Całkowita kwota zakładu:',
      'final bet amount': 'Ostateczna kwota zakładu:',
      'new feature': 'Nowa funkcjonalność',
      'find bets': 'Twoje zakłady są tutaj!',
      'dont show': 'Nie pokazuj ponownie',
    }
  },
  hin: {
    translation: {
      'virtualSport': 'आभासी खेल ',
      'noOpenBets': 'कोई बेट खुली नहीं हैं।',
      'select': 'चयन करें',
      'accepted': 'स्वीकार करें',
      'cashouted': 'कैश आउट हो गया है',
      'winReturn': 'जीत का रिटर्न',
      'loseReturn': 'हार का रिटर्न',
      'always-ask': 'बदलावों को स्वीकार करें और बेट लगाएं',
      'suspend': 'यह इवेंट स्थगित हो चुका है',
      'bet settings title': 'जब प्राइस बदलते हैं',
      'accept high odd': 'उच्च ऑड्स को स्वीकार करें',
      'always ask': 'हमेशा पूछें',
      'any odd': 'कोई भी ऑड स्वीकार करें',
      'played': 'खेला गया',
      'draw': 'ड्रॉ',
      'lose': 'हार',
      'goals for': 'के लिये गोल',
      'goals against': 'के खिलाफ गोल',
      'goals diff': 'गोल अंतर',
      'position': 'स्थान',
      'team': 'टीम',
      'position LG': 'पीओ.',
      'played LG': 'पी',
      'win LG': 'W',
      'draw LG': 'D',
      'lose LG': 'L',
      'goals for LG': 'GF',
      'goals against LG': 'GA',
      'goals diff LG': 'GD',
      'points LG': 'Pts',
      'virtual': 'आभासी',
      'instant': 'तुरंत',
      'VirtualTennis': 'आभासी टेनिस',
      'VirtualFootball': ' आभासी फुटबॉल',
      'VirtualHorseRacing': ' आभासी घुड़दौड़',
      'VirtualBicycle': ' आभासी साइक्लिंग',
      'VirtualGreyhounds': ' आभासी ग्रेहाउंड्स',
      'PenaltyKicks': 'पेनाल्टी किक्स',
      'VirtualFootballLeague': ' आभासी फुटबॉल लीग',
      'DragRacing': 'ड्रैग रेसिंग',
      'MarbleRacing': 'मार्बल रेसिंग',
      'results': 'परिणाम',
      'additional info': 'अगर कोई जानकारी है, तो अतिरिक्त बुकमार्कर जानकारी',
      'upcoming games': 'आगामी खेल',
      'player names': 'खिलाड़ियों के नाम',
      'match running': ' यह मैच पहले से चल रहा है। आप अन्य मैचों के बाजार देख सकते हैं।',
      'select day': 'मैच के दिन का चयन करें',
      'matches': 'मैच',
      'live table': 'लाइव टेबल',
      'all': 'सभी',
      'match': 'मैच',
      'handicaps': 'हैंडिकैप्स',
      'total': 'कुल',
      'half': 'हॉफ',
      'set': 'सेट',
      'game': 'गेम',
      'point': 'अंक',
      'player name': 'खिलाड़ी का नाम',
      'decimal': 'दशमलव',
      'american': 'अमेरिकी',
      'hongkong': 'हांगकांग',
      'malay': 'मलेशिया',
      'indo': 'इंडोनेशिया',
      'bet slip': 'बेट स्लिप',
      'place bet': 'बेट लगाएं',
      'insufficient balance': ' अपर्याप्त शेष जमा',
      'amount is less': 'बेट की राशि न्यूनतम राशि से कम है',
      'my bets': 'मेरी बेट',
      'clear all': 'सभी क्लियर करें',
      'quick bets': 'तुरंत बेट लगाएं',
      'superbet': ',सुपर बेट',
      'superbet about': ' सुपरबेट एक ऐसी सुविधा है जो उपयोगकर्ताओं को अधिकतम बेट सीमा से अधिक की हिस्सेदारी के साथ दांव लगाने का अनुरोध करने की अनुमति देती है। अनुरोध व्यापारियों द्वारा स्वीकृत या अस्वीकृत हो सकता है।',
      'counter offer': 'काउंटर ऑफर उपलब्ध हैं',
      'counter offer about': ' काउंटर ऑफर एक नया विकल्प है जो चयनित खेल आयोजनों के लिए बेहतर ऑड्स का सुझाव देता है। 1.10 . से ऊपर के ऑड्स पर उपलब्ध।',
      'to select bet': 'दांव का चयन करने के लिए',
      'view all bets': 'आप अपने सभी दांव इसमें देख सकते हैं।',
      'bet history': 'बेट की हिस्ट्री',
      'calculator': 'सिस्टम कैलकुलेटर',
      'total stake': 'कुल स्टेक',
      'combinations': 'संयोजन',
      'winnings': 'जीत',
      'all bets': 'सभी बेट',
      'open': 'ओपन',
      'win': 'जीत',
      'lost': 'हार',
      'return': 'रिटर्न',
      'bet ID': 'बेट ID',
      'bet type': 'बेट का प्रकार',
      'single': 'सिंगल',
      'multiple': 'मल्टिपल',
      'system': 'सिस्टम',
      'period': 'पीरियड',
      'range': 'रेंज',
      'show': 'शो',
      'data id': 'तारीख और आईडी',
      'stake': 'स्टेक',
      'odd type': 'ऑड का प्रकार',
      'odd': 'ऑड',
      'odds': 'ऑड्स',
      'outcome': 'परिणाम',
      'status': 'स्थिति',
      'history empty': 'हिस्ट्री खाली है।',
      'hour': 'घंटा',
      'custom': 'कस्टम',
      '1h': '1 घंटा',
      '2h': '2 घंटे',
      '3h': '3 घंटे',
      '6h': '6 घंटे',
      '12h': '12 घंटे',
      '24h': '24 घंटे',
      '48h': '48 घंटे',
      '72h': '72 घंटे',
      'sport': 'खेल',
      'competition': 'प्रतियोगिता',
      'date': 'तारीख',
      'event': 'इवेंट',
      'score': 'स्कोर',
      'search': 'खोज',
      'kg': 'kg',
      'final': 'फाइनल',
      'max bet case': ' दर्ज की गई राशि अधिकतम दांव राशि से अधिक है।',
      'min bet case': ' दर्ज की गई राशि न्यूनतम बेट से कम है।',
      'event not available': 'यह इवेंट उपलब्ध नहीं है।',
      'bet accepted': 'आपकी बेट स्वीकार कर ली गई है।',
      'bet failed': 'आपकी बेट फेल हुई है।',
      'deleted events': 'कुछ डिलीट किये गये इवेंट हैं।',
      'bet in progress': 'आपकी बेट प्रोग्रेस में है...',
      'possible win': 'संभावित जीत',
      'unsettled': 'सैटल नहीं है।',
      'last': 'आखिरी',
      'next start in': 'अगला प्वाइंट शुरू हुआ',
      'to place bets': 'कृपया अपनी बेट लगाएं',
      'sign in': 'साइन इन',
      'or': 'या',
      'register': 'अभी पंजीकरण करें!',
      'cash-out': 'कैश-आउट',
      'add more events': 'बेट लगाने में सक्षम होने के लिये औऱ इवेंट जोड़ें',
      'quick bets about': ' एक राशि डालें और बेट लगाने के लिए किसी भी ऑड पर क्लिक करें।',
      'not selected': 'चयन नहीं हुआ है',
      'place your bets': 'अपनी बेट लगाएं',
      'id': 'ID:',
      'Germany': 'जर्मनी',
      'Spain': 'स्पेन',
      'England': 'इंग्लैंड',
      'Italy': 'इटली',
      'France': 'फ्रांस',
      'Belgium': 'बेल्जियम',
      'Argentina': 'अर्जेंटीना',
      'Brazil': 'ब्राजील',
      'PartnerNotFound': 'सहभागी पंजीकृत नहीं है।',
      'BetTypeError': 'वैध बेट प्रकार का चयन करें',
      'BetValidationError': 'तकनीकी खराबी हुई है, कृपया फिर से कोशिश करें।',
      'PartnerApiClientBalanceError': 'अपर्याप्त राशि',
      'BetSelectionChanged': 'ऑड्स में बदलाव हुए हैं।',
      'SelectionNotFound': 'चयन सही नहीं है।',
      'BetAmountError': ' हिस्सेदारी की राशि अनुमत सीमा से बाहर है',
      'SelectionSuspended': ' हटाए गए ईवेंट हैं।',
      'tax amount': 'कर राशि',
      'final payout': 'आखिरी पेआउट:',
      'total bet amount': 'कुल बेट राशि:',
      'final bet amount': 'अंतिम बेट राशि:'
    }
  },
  swe: {
    translation: {
      'virtualSport': 'Virtuell Sport ',
      'noOpenBets': 'Det finns inga öppna satsningar.',
      'select': 'Välj',
      'accepted': 'Accepterad',
      'cashouted': ' Utbetalad ',
      'winReturn': 'Vinst Retur',
      'loseReturn': 'Förkora Retur',
      'always-ask': 'Acceptera ändringar och satsa',
      'suspend': 'Den här evenemanget har avbrutits.',
      'bet settings title': 'När priset ändras',
      'accept high odd': 'Acceptera högre odds',
      'always ask': 'Fråga alltid',
      'any odd': 'Acceptera alla odds',
      'played': 'Spelade',
      'draw': 'Dra',
      'lose': 'Förlora',
      'goals for': 'Mål för',
      'goals against': 'Mål Mot ',
      'goals diff': 'Målskillnad ',
      'position': ' Position ',
      'team': 'Lag',
      'position LG': 'Po.',
      'played LG': 'P',
      'win LG': 'W',
      'draw LG': 'D',
      'lose LG': 'L',
      'goals for LG': 'GF',
      'goals against LG': 'GA',
      'goals diff LG': 'GD',
      'points LG': 'Pts',
      'virtual': 'Virtuell',
      'instant': 'Omedelbar',
      'VirtualTennis': 'Virtuell Tennis',
      'VirtualFootball': 'Virtuell Football',
      'VirtualHorseRacing': 'Virtuell Hästkapplöpning',
      'VirtualBicycle': 'Virtuell Cykling',
      'VirtualGreyhounds': 'Virtuella Vinthundar',
      'PenaltyKicks': 'Straff Sparkar',
      'VirtualFootballLeague': 'Virtuell Fotbollsliga',
      'DragRacing': 'DragTtävling',
      'MarbleRacing': ' Marmor Racing',
      'results': 'Resultat',
      'additional info': 'Ytterligare bokmärkesinfo om det finns någon info',
      'upcoming games': 'Kommande spel',
      'player names': 'Spelarnamn',
      'match running': ' Denna match pågår redan. Du kan se marknader för andra matcher.',
      'select day': 'Välj matchdag',
      'matches': 'Matchar',
      'live table': 'Live- Bord',
      'all': 'Alla',
      'match': 'Match',
      'handicaps': 'Handikapp',
      'total': 'Totalt',
      'half': 'Halv',
      'set': 'Set',
      'game': 'Spel',
      'point': 'Poäng',
      'player name': 'Spelarens namn',
      'decimal': 'Decimal',
      'american': 'Amerikansk',
      'hongkong': 'HongKong',
      'malay': 'Malajiska',
      'indo': 'Indo',
      'bet slip': 'Spelkupong',
      'place bet': 'Placera Insats',
      'insufficient balance': 'Otillräckligt saldo Insättning',
      'amount is less': 'Insatsen är mindre än minsta tillåtna belopp.',
      'my bets': 'Mina Insatser',
      'clear all': 'Rensa alla',
      'quick bets': 'Snabba Satsningar',
      'superbet about': 'SuperBet är en funktion som gör det möjligt för användare att begära att få placera ett insatsbelopp som överstiger Max Bet Limit. Begäran kan godkännas eller avvisas av handlare.',
      'counter offer': 'Motbud tillgängligt',
      'counter offer about': 'Motbud är ett nytt alternativ som gör det möjligt att föreslå bättre odds för utvalda sportevenemang. Tillgängligt för odds över 1,10.',
      'to select bet': 'För att välja en insats, klicka på någon odds.',
      'view all bets': 'Du kan se alla dina insatser i ',
      'bet history': 'Satsningshistoria',
      'calculator': 'Systemkalkylator',
      'total stake': 'Total Insats',
      'combinations': 'Kombinationer',
      'winnings': 'Vinster',
      'all bets': 'Alla Insatser',
      'open': 'Öppen',
      'win': 'Vinn',
      'lost': 'Förlorat',
      'return': 'Returnera',
      'bet ID': 'Insats ID',
      'bet type': 'Insatstyp',
      'single': 'Singel',
      'multiple': 'Flera',
      'system': 'System',
      'period': 'Period',
      'range': 'Utbud',
      'show': 'Visa',
      'data id': 'Datum och ID',
      'stake': 'Insats',
      'odd type': 'Odd Typ',
      'odd': 'Odd',
      'odds': 'Odds',
      'outcome': 'Utfall',
      'status': 'Status',
      'history empty': 'Historien är tom.',
      'hour': 'timme',
      'custom': 'Anpassad',
      '1h': '1 timme',
      '2h': '2 timmar',
      '3h': '3 timmar',
      '6h': '6 timmar',
      '12h': '12 timmar',
      '24h': '24 timmar',
      '48h': '48 timmar',
      '72h': '72 timmar',
      'sport': 'Sport',
      'competition': 'Tävling',
      'date': 'Datum',
      'event': 'Evenemang',
      'score': 'Poäng',
      'search': 'Sök',
      'kg': 'kg',
      'final': 'Final',
      'max bet case': 'Det angivna beloppet är större än det maximala insatsbeloppet.',
      'min bet case': 'Det angivna beloppet är mindre än den lägsta insatsent.',
      'event not available': 'Detta evenemang är inte tillgängligt.',
      'bet accepted': 'Din insats är accepterad.',
      'bet failed': 'Din satsning har misslyckats.',
      'deleted events': 'Det finns raderade evenemang.',
      'bet in progress': ' Din satsning pågår...',
      'possible win': 'Möjlig vinst',
      'unsettled': 'Obestämd',
      'last': 'Sista',
      'next start in': 'Nästa punkt börjar i',
      'to place bets': 'För att placera ditt spel, vänligen',
      'sign in': 'logga in',
      'or': 'eller',
      'register': 'registrera dig nu!',
      'cash-out': 'Uttag',
      'add more events': 'Lägg till fler evenemang för att kunna satsa',
      'quick bets about': 'Ange ett belopp och klicka på någon odd för att placera en insats.',
      'not selected': 'Inte valts',
      'place your bets': 'Placera dina satsningar',
      'id': 'ID:',
      'Germany': 'Tyskland',
      'Spain': 'Spanien',
      'England': 'England',
      'Italy': 'Italien',
      'France': 'Frankrike',
      'Belgium': 'Belgien',
      'Argentina': 'Argentina',
      'Brazil': 'Brasilien',
      'PartnerNotFound': 'Partner är inte registrerad',
      'BetTypeError': 'Välj giltig typ av insats',
      'BetValidationError': 'Tekniskt problem uppstod, försök igen.',
      'PartnerApiClientBalanceError': 'Otillräcklig balans',
      'BetSelectionChanged': 'Oddsen har ändrats',
      'SelectionNotFound': 'Urvalet är inte korrekt',
      'BetAmountError': 'Insatsbeloppet ligger utanför det tillåtna intervallet.',
      'SelectionSuspended': 'Det finns raderade event',
      'tax amount': ' Skattebelopp:',
      'final payout': 'Slututbetalning:',
      'total bet amount': 'Totalt insatsbelopp:',
      'final bet amount': 'Slutligt insatsbelopp:'
    }
  }
};
i18n.use(initReactI18next).init({
  resources,
  lng: 'eng',
  keySeparator: false, // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false // react already safes from xss
  }
});

export default i18n;