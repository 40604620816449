import React, {memo} from 'react';
import './TennisInstantGame.scss';
import PlayerSlider from "./Sciences/PlayerSlider";

function TennisInstantGame({science, setActiveInstantGameData}) {
  const components = {
    playNow: PlayerSlider
  };

  const Component = components[science];

  const changeScience = () => {
    setActiveInstantGameData(null)
  }
  return <div className='tennis-instant-game'>
    <Component changeScience={changeScience}/>
  </div>
}

export default memo(TennisInstantGame);