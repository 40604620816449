import React, {useState} from 'react';
import './Select.scss';

function Select({list, onChangeCallback, setIsBetTypeManually, defaultValue}) {
  const selectList = list && list.length ? list : [];
  const [value, setValue] = useState(defaultValue)

  const onSelect = value => {
    onChangeCallback(value)
    setValue(value)
  }

  const changeBetType = e => {
    onSelect(e.target.value);
    
    if (setIsBetTypeManually) {
      setIsBetTypeManually();
    }
  }

  return <select value={defaultValue !== undefined ? defaultValue : value} onChange={changeBetType}>
    {
      selectList.map((name, index) => {
        return <option value={index} key={index}>{name}</option>;
      })
    }
  </select>
}

export default Select;