import React, {useRef} from 'react';
import './BetSlipHint.scss';
import {useTranslation} from "react-i18next";

function BetSlipHint({closeHint}) {
  const {t} = useTranslation();
  const dontShowCheckbox = useRef();

  return <div className='bet-slip-hint'>
    <div className="container">
      <div className="close-hint" onClick={() => closeHint(dontShowCheckbox.current.checked)}>&#10005;</div>
      <div className="hint-text">
        <pre>{t('new feature').toUpperCase()}</pre>
        <p>{t('find bets')}</p>
      </div>
      <img src={process.env.PUBLIC_URL + './assets/icons/hint_arrow.png'} alt=""/>
      <div className="dont-show">
        <input type="checkbox" id="check" ref={dontShowCheckbox}/>
        <label htmlFor="check"> {t('dont show')}</label>
      </div>
    </div>
  </div>
}

export default BetSlipHint;