import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import './BetItem.scss';
import {AppContext} from "../../../App";
import RequestManager from "../../../services/RequestManager";
import Utils from "../../../utils/Utils";
import {useTranslation} from "react-i18next";
import {MAX_BET_DATA, RE_CAPTCHA_MAX_DATA} from "../../../services/RequestDatas";

function BetItem({
                   itemData,
                   isTaxCase,
                   setSingleBet,
                   removeItem,
                   updateBetsData,
                   applyUserSettings
                 }) {
  const {t} = useTranslation();
  const context = useContext(AppContext);
  const socket = RequestManager.getInstance().webSocket;
  const price = itemData && itemData['price'] && itemData['price'].toString().substring(0, 4);
  const text = t('event not available');
  const textSuspend = t('suspend');
  const currency = context.currency.get;
  const minBetValue = context.partnerConfig.get['min_bet_stakes'][currency];
  const betDefaultValue = context.betsData.get.length === 1 ? minBetValue * 2 : '';
  const userBetType = +context.userPreferBetType.get;
  const [betValue, setBetValue] = useState(betDefaultValue);
  const [updatedOdd, setUpdatedOdd] = useState({oldOdd: '', newOdd: ''});

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener('change', ({detail}) => updateBetValue(detail));
    }

    if (betValue === '') {
      updateBetValue(minBetValue * 2);
    } else if (betDefaultValue) {
      updateBetValue(betDefaultValue);
    }
  }, [])

  useEffect(() => {
    if (itemData) {
      socket.addEventListener(RequestManager.AUTO_MARKET_DATA, checkBetItemStatus);
    }

    return () => {
      socket.removeEventListener(RequestManager.AUTO_MARKET_DATA, checkBetItemStatus);
    }
  }, []);

  const checkBetItemStatus = ({ detail }) => {

    let data;
    let newPrice = null;
    const itemMarketId = itemData['market_id'];
    const itemPrice = itemData['price'];
    const itemId = itemData['id'];
    const responseString = JSON.stringify(detail);
    const newBetsData = [];
    const currentBetsData = Utils.readFromStorage('betsData');
    const betSetting = Utils.readFromStorage('userOptions', false);

    if (detail && Utils.firstKey(detail) === 'sport') {
      data = detail[Utils.firstKey(detail)];
      data = data[Utils.firstKey(data)].region;
      data = data[Utils.firstKey(data)].competition;
      data = data[Utils.firstKey(data)].game;
      data = data[Utils.firstKey(data)].market;
    } else {
      data = detail[Utils.firstKey(detail)];
    }

    if (data[itemMarketId]) {
      newPrice = data[itemMarketId].event ? data[itemMarketId].event[itemId] && data[itemMarketId].event[itemId].price : null
      if (newPrice !== null && itemPrice !== newPrice) {
        setUpdatedOdd(updated => ({...updated, oldOdd: itemPrice, newOdd: newPrice}))
      }
    } else {
      // TODO: when market was deleted (odd is too small)
      const temp = [];
      currentBetsData.map(item => {
        if (data[itemMarketId] === null && item['stadium_name'] !== "Virtual Tennis") {
          item['status'] = 'failed';
        }
        temp.push(item);
      })
      updateBetsData(temp);
    }

    // UPDATE IN BETS DATA
    if (responseString.includes(itemMarketId)) {
      currentBetsData.map(item => {
        if (data[itemMarketId] === null && item['stadium_name'] !== "Virtual Tennis") {
          item['status'] = 'failed';
        } else if (item['stadium_name'] === "Virtual Tennis") {
          if (itemPrice !== newPrice && newPrice !== null) {
            item['price'] = newPrice;
            item['status'] = context.tennisUpdateState.get === 12 ? 'suspend' : 'success';

            if (betSetting === 'option3') {
              applyUserSettings('');
            } else if (betSetting === 'option2') {
              applyUserSettings('always-ask');
            } else if (itemPrice < newPrice && betSetting === 'option1') {
              applyUserSettings('accept-higher');
            } else if (itemPrice > newPrice && betSetting === 'option1') {
              applyUserSettings('always-ask');
            }
          }
        }

        newBetsData.push(item);
      })

      updateBetsData(newBetsData);
    }
  }

  const prepareToGetMaxBetValue = () => {
    const reCaptchaData = context.reCaptchaData.get;
    if (reCaptchaData && reCaptchaData['version'] === 3) {
      const script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js?render=" + context.reCaptchaData.get.siteKey;
      script.addEventListener("load", handleReCaptchaScriptLoaded);

      document.body.appendChild(script);
    } else {
      getMaxBetValue();
    }
  }

  const handleReCaptchaScriptLoaded = data => {
    window.grecaptcha.ready(data => {
      window.grecaptcha.execute(context.reCaptchaData.get.siteKey, {action: "get_max_bet"}).then(token => RE_CAPTCHA_MAX_DATA.params.g_recaptcha_response = token)
    })

    socket.send(JSON.stringify(RE_CAPTCHA_MAX_DATA));
    socket.addEventListener(RequestManager.RE_CAPTCHA_MAX_EVENT, response => handleMaxReCaptchaRequestResponse(response.detail), {once: true});
  }

  const handleMaxReCaptchaRequestResponse = response => {
    // TODO needs to check response status.
    getMaxBetValue();
  }

  const getMaxBetValue = () => {
    const betItemIDs = context.betsData.get.filter(item => item['id'] === itemData.id);

    MAX_BET_DATA.params.type = userBetType + 1;
    MAX_BET_DATA.params.events = betItemIDs.length ? [betItemIDs[0]['id']] : [];

    socket.send(JSON.stringify(MAX_BET_DATA));
    socket.addEventListener(RequestManager.MAX_BET_EVENT, response => updateMaxBetValue(response.detail), {once: true});
  }

  const updateMaxBetValue = ({data}) => {
    if (data && data.details && data.details.amount) {
      updateBetValue(data.details.amount);
    }
  }

  const updateBetValue = value => {
    value = Utils.validInputValue(value)

    setSingleBet(value, itemData.id);
    setBetValue(value);
  }

  const getFailedIcon = () => {
    const infoURL = process.env.PUBLIC_URL + './assets/icons/failed.svg';
    const itemStatus = itemData['status'] === 'failed' ? text : itemData['status'] === 'suspend' ? textSuspend : null;
    const className = itemData['status'] === 'failed' ? 'dell-text' : itemData['status'] === 'suspend' ? 'dell-text suspended' : null;

    return itemStatus ? <div className='event-status'>
      <div className='bet-failed-icon' style={{backgroundImage: `url(${infoURL})`}}/>
      <span className={className}>{context.tennisUpdateState.get === 12 ? textSuspend : itemStatus}</span>
    </div> : null;
  }

  const getStakeTaxPercentage = () => {
    const taxAmountRanges = context.partnerConfig.get['tax_amount_ranges'];
    const selectedTaxRange = taxAmountRanges.filter(item => {
      return (betValue >= item['MinAmount'] && betValue <= item['MaxAmount'] && item['TaxType'] === 4)
    })[0];

    return selectedTaxRange ? selectedTaxRange['TaxPrecentage'] : 0;
  }

  const getTaxedPossibleWin = () => {
    const taxStakePercentage = getStakeTaxPercentage();
    return (price * (betValue - (betValue * taxStakePercentage / 100))).toFixed(2);
  }

  const getWinTaxPercentage = () => {
    const taxAmountRanges = context.partnerConfig.get['tax_amount_ranges'];
    const possibleWin = (price * betValue).toFixed(2);
    const selectedWinTaxRange = taxAmountRanges.filter(item => {
      return (possibleWin >= item['MinAmount'] && possibleWin <= item['MaxAmount'] && item['TaxType'] === 2)
    })[0];

    return selectedWinTaxRange ? selectedWinTaxRange['TaxPrecentage'] : 0;
  }

  const getTaxWinInfoSingle = () => {
    if (isTaxCase === false || !betValue) {
      return false;
    }

    const taxWinPercentage = getWinTaxPercentage();
    const possibleWin = getTaxedPossibleWin();

    return taxWinPercentage ? <div className='tax-info'>
      <div className="potential-winning-container">
        <p className='potential-win-title'>{t('tax amount') + ' (' + taxWinPercentage + '%)'}</p>
        <p className='potential-win-value'>{(possibleWin * taxWinPercentage / 100).toFixed(2)}</p>
      </div>
      <div className="potential-winning-container">
        <p className='potential-win-title'>{t('final payout')}</p>
        <p className='potential-win-value'>{(possibleWin - (possibleWin * taxWinPercentage / 100)).toFixed(2)}</p>
      </div>
    </div> : null
  }

  const getProfitTaxPercentage = taxedPossibleWin => {
    const taxAmountRanges = context.partnerConfig.get['tax_amount_ranges'];
    const taxStakePercentage = getStakeTaxPercentage();
    const calcTaxedPossibleWin = taxedPossibleWin - (betValue - betValue * taxStakePercentage / 100).toFixed(2);

    const selectedTaxRange = taxAmountRanges.filter(item => {
      return (calcTaxedPossibleWin >= item['MinAmount'] && calcTaxedPossibleWin <= item['MaxAmount'] && item['TaxType'] === 1)
    })[0];

    return selectedTaxRange ? selectedTaxRange['TaxPrecentage'] : 0;
  }

  const getTaxStakeInfoSingle = () => {
    if (isTaxCase === false || !betValue) {
      return false;
    }

    const taxStakePercentage = getStakeTaxPercentage();
    const totalAmount = (betValue - betValue * taxStakePercentage / 100).toFixed(2);
    const taxedPossibleWin = getTaxedPossibleWin();
    const profitTaxPercentage = getProfitTaxPercentage(taxedPossibleWin);

    let taxedTotalProfit;
    if (profitTaxPercentage) {
      taxedTotalProfit = ((taxedPossibleWin - totalAmount) * profitTaxPercentage / 100).toFixed(2);
    }

    return taxStakePercentage ? <div className='tax-info'>
      <div className="potential-winning-container">
        <p className='potential-win-title'>{t('tax amount') + '(' + taxStakePercentage + '%)'}</p>
        <p className='potential-win-value'>{betValue * taxStakePercentage / 100}</p>
      </div>
      <div className="potential-winning-container">
        <p className='potential-win-title'>{t('total bet amount')}</p>
        <p className='potential-win-value'>{(betValue - betValue * taxStakePercentage / 100).toFixed(2)}</p>
      </div>
      {
        taxedTotalProfit ? <div className="potential-winning-container">
          <p className='potential-win-title'>Tax to be paid to RRA:</p>
          <p className='potential-win-value'>{taxedTotalProfit}</p>
        </div> : null
      }
      {
        taxedTotalProfit ? <div className="potential-winning-container">
          <p className='potential-win-title'>Final payout:</p>
          <p className='potential-win-value'>{(Number(taxedPossibleWin) - Number(taxedTotalProfit)).toFixed(2)}</p>
        </div> : null
      }
    </div> : null
  }

  const getMinBetValue = () => {
    const value = Number(minBetValue);
    updateBetValue(value);
  }

  const minusMin = () => {
    if (betValue > minBetValue) {
      const value = Number(betValue) - Number(minBetValue);
      updateBetValue(value);
    }
  }

  const plusMin = () => {
    const value = Number(betValue) + minBetValue;
    updateBetValue(value);
  }

  const doubleUp = () => {
    if (betValue) {
      const value = betValue * 2;
      updateBetValue(value);
    }
  }

  const divideValue = () => {

    if (betValue && betValue && (betValue / 2 >= minBetValue)) {
      const value = betValue / 2;
      updateBetValue(value);
    }
  }

  const activateInput = e => {
    context.activeStakeInput.set(e.currentTarget);
  }

  const getSingleBetItem = () => {
    return <div className='bet-item'>
      <div className="bet-item-header">
        <p className="bet-stadium-name">{itemData['sportName'] ? itemData['sportName'] : itemData['stadium_name']}</p>
        <p className="bet-time">{itemData['event_time']}</p>
        <div className="remove-item" onClick={() => removeItem(itemData['id'])}>&#10006;</div>
      </div>
      <div className='bet-type'>{itemData['market_name'] ? itemData['market_name'] : t('single')}</div>
      <div className='bet-type'>{itemData['game_name'] + ' / ' + itemData['bet_type']}</div>
      <div className="bet-item-footer">
        <p className="bet-player-name">{itemData['name'] + ' ' + (itemData['base'] ? itemData['base'] : '')}</p>
        {
          updatedOdd['oldOdd'] !== '' && updatedOdd['newOdd'] !== '' ?
            <p className="bet-coef">
              <span className='old-odd'>{Utils.getFormattedCoef(updatedOdd['oldOdd'], context.coefType.get)}</span>
              <span>{Utils.getFormattedCoef(updatedOdd['newOdd'], context.coefType.get)}</span>
            </p> :
            <p className="bet-coef">{Utils.getFormattedCoef(price, context.coefType.get)}</p>
        }
      </div>
      <div className="bet-input-container">

        <div className="stake-block">
          <div className="stake-button max-button" onClick={getMinBetValue}>Min</div>
          <div className="stake-button" onClick={divideValue}>1/2</div>
          <div className="stake-button minus" onClick={minusMin}>-</div>
          <input type="text" ref={inputRef} placeholder={t('stake')} readOnly onClick={activateInput} value={betValue}/>
          <div className="stake-button plus" onClick={plusMin}>+</div>
          <div className="stake-button" onClick={doubleUp}>x2</div>
          <div className="stake-button min-button" onClick={prepareToGetMaxBetValue}>Max</div>
        </div>

      </div>
      <div className='potential-winning-container'>
        <p className='potential-win-title'>{t('possible win')}:</p>
        <p className='potential-win-value'>{
          `${isNaN((price * betValue).toFixed(2))
            ? `0.00`
            : (price * betValue).toFixed(2)} ${context.userLoggedIn.get
            ? context.currency.get
            : context.partnerConfig.get['currency']}`}</p>
      </div>
      {getFailedIcon()}
      {getTaxWinInfoSingle()}
      {getTaxStakeInfoSingle()}
    </div>
  }

  const getMultipleBetItem = () => {
    return <div className='bet-item'>
      <div className="bet-item-header">
        <p className="bet-stadium-name">{itemData['sportName'] ? itemData['sportName'] : itemData['stadium_name']}</p>
        <p className="bet-time">{itemData['event_time']}</p>
        <div className="remove-item" onClick={() => removeItem(itemData['id'])}>&#10006;</div>
      </div>
      <div className='bet-type'>{t('multiple')}</div>
      <div className='bet-type'>{itemData['game_name'] + ' / ' + itemData['bet_type']}</div>
      <div className="bet-item-footer">
        <p className="bet-player-name">{itemData['name'] + ' ' + (itemData['base'] ? itemData['base'] : '')}</p>
        {
          updatedOdd['oldOdd'] !== '' && updatedOdd['newOdd'] !== '' ?
            <p className="bet-coef">
              <span className='old-odd'>{Utils.getFormattedCoef(updatedOdd['oldOdd'], context.coefType.get)}</span>
              <span>{Utils.getFormattedCoef(updatedOdd['newOdd'], context.coefType.get)}</span>
            </p> :
            <p className="bet-coef">{Utils.getFormattedCoef(price, context.coefType.get)}</p>
        }
      </div>
      {getFailedIcon()}
    </div>
  }

  const getSystemBetItem = () => {
    return <div className='bet-item'>
      <div className="bet-item-header">
        <p className="bet-stadium-name">{itemData['sportName'] ? itemData['sportName'] : itemData['stadium_name']}</p>
        <p className="bet-time">{itemData['event_time']}</p>
        <div className="remove-item" onClick={() => removeItem(itemData['id'])}>&#10006;</div>
      </div>
      <div className='bet-type'>{t('system')}</div>
      <div className='bet-type'>{itemData['game_name'] + ' / ' + itemData['bet_type']}</div>
      <div className="bet-item-footer">
        <p className="bet-player-name">{itemData['name'] + ' ' + (itemData['base'] ? itemData['base'] : '')}</p>
        {
          updatedOdd['oldOdd'] !== '' && updatedOdd['newOdd'] !== '' ?
            <p className="bet-coef">
              <span className='old-odd'>{Utils.getFormattedCoef(updatedOdd['oldOdd'], context.coefType.get)}</span>
              <span>{Utils.getFormattedCoef(updatedOdd['newOdd'], context.coefType.get)}</span>
            </p> :
            <p className="bet-coef">{Utils.getFormattedCoef(price, context.coefType.get)}</p>
        }
      </div>
      {getFailedIcon()}
    </div>
  }

  return <>
    {userBetType === 0 ? getSingleBetItem() :
      userBetType === 1 ?
        getMultipleBetItem() :
        userBetType === 2  ? getSystemBetItem() : null}
  </>
}

export default memo(BetItem);
