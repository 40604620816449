import React, {useContext, useEffect, useState} from 'react';
import './BasicMarket.scss';
import UpcomingGames from "../UpcomingGames/UpcomingGames";
import Utils from "../../../utils/Utils";
import {AppContext} from "../../../App";
import {BET_CHECK_DATA} from "../../../services/RequestDatas"
import RequestManager from "../../../services/RequestManager"
import BasicMarketSection from "./BasicMarketSection/BasicMarketSection"
import {useTranslation} from "react-i18next"

function BasicMarket({gameTimesData, setBetsData, addBetItemAnimation}) {
  // TODO: This Match is already running. Below you can see the markets of the Next Match and place bets on them.
  const {t} = useTranslation();
  const appContext = useContext(AppContext);
  const webSocket = RequestManager.getInstance().webSocket;
  const [marketData, setMarketData] = useState({});
  const [stadiumName, setStadiumName] = useState('');
  const [alias, setAlias] = useState('');
  const [eventTime, setEventTime] = useState();
  const [selectedPriceData, setSelectedPriceData] = useState({});

  useEffect(() => {
    if (appContext.betsData.get.length) {
      setSelectedPriceData({});
    }
  }, [eventTime, appContext.betsData.get])

  const parseMarketData = (data) => {
    if (Object.keys(data['sport']).length === 0) {
      setMarketData(null);
      return;
    }
    const eventTime = data['event_time'];
    const aliasValue = data.sport[Utils.firstKey(data.sport)].alias;
    data = data.sport[Utils.firstKey(data.sport)].region;
    data = data[Utils.firstKey(data)].competition;
    const stadiumName = data[Utils.firstKey(data)].name;

    data = data[Utils.firstKey(data)].game;
    data = data[Utils.firstKey(data)];

    setStadiumName(stadiumName);
    setEventTime(eventTime);
    setAlias(aliasValue);
    setMarketData(data);
  }

  useEffect(() => {
    checkCurrentBetData();
  }, [appContext.showBlock.get])

  const dataIsEmpty = () => {
    return marketData === null || (marketData && (Object.keys(marketData).length === 0 ||
      Object.keys(marketData.market).length === 0 || JSON.stringify(marketData.market).includes('null')));
  }

  const matchIsAlreadyRunningInfo = () => {
    return <p className='match-running-info'>{t('match running')}</p>
  }

  const updateMarketData = response => {
    if (Object.keys(response).length === 0) {
      return;
    }

    if (marketData && JSON.stringify(response).includes(marketData.id)) {
      setMarketData(null);
    }
  }

  const getMarketData = () => {
    return marketData ? Object.keys(marketData['market']).map(function (key, index) {
      const currentItem = marketData['market'][key];
      return <BasicMarketSection key={index} marketID={key} alias={alias} currentItem={currentItem}
                                 marketData={marketData} selectedPriceData={selectedPriceData}
                                 selectPriceCallback={selectPrice}/>
    }) : null
  }

  const openSignInPopUp = () => {
    window.parent.postMessage({ action: 'openSlider', tab: 'login' }, '*');
  }

  const selectPrice = (selectedItemData, betType, isActiveItem) => {
    if (!appContext.userLoggedIn.get) {
      openSignInPopUp()
      return;
    }

    addBetItemAnimation();

    if (isActiveItem) {
      const betsData = appContext.betsData.get;
      const filteredItems = betsData.filter(item => item.id !== selectedItemData.id);
      Utils.writeStorage('betsData', filteredItems);
      appContext.betsData.set(filteredItems);

      setSelectedPriceData({});
      return;
    }

    const activeGameName = appContext.sportsData.get.find(data => data.id === appContext.activeGameID.get)['name'];
    selectedItemData['stadium_name'] = stadiumName;
    selectedItemData['event_time'] = eventTime;
    selectedItemData['bet_type'] = betType;
    selectedItemData['game_id'] = parseInt(marketData.id);
    selectedItemData['bet_type'] = betType;
    selectedItemData['game_name'] = activeGameName;

    checkCurrentBetData(selectedItemData);
  }

  const checkCurrentBetData = (selectedItemData = {}) => {
    let eventIDs = [];
    let gameIDs = [];
    let marketIDs = [];

    appContext.betsData.get.map(item => {
      eventIDs.push(parseInt(item['id']));
      gameIDs.push(parseInt(item['game_id']));
      marketIDs.push(parseInt(item['market_id']));
    });

    BET_CHECK_DATA.params.where.game.id['@in'] = gameIDs;
    BET_CHECK_DATA.params.where.event.id['@in'] = eventIDs;
    BET_CHECK_DATA.params.where.market.id['@in'] = marketIDs;

    webSocket.send(JSON.stringify(BET_CHECK_DATA));

    if (Object.keys(selectedItemData).length) {
      webSocket.addEventListener(RequestManager.BET_CHECKED_EVENT,
        response => parseBetData(response.detail, selectedItemData), {once: true});
    } else {
      webSocket.addEventListener(RequestManager.BET_CHECKED_EVENT,
        response => parseBetData(response.detail), {once: true});
    }
  }

  const parseBetData = (response, selectedData = {}) => {
    if (response && response.data) {

      let currentBets = [...appContext.betsData.get];
      const successBet = Object.keys(response.data.data.game).length;
      BET_CHECK_DATA.subID = response.data.subid;

      if (Object.keys(selectedData).length) {
        currentBets = currentBets.filter(item => {
          if (item['game_id'] !== selectedData['game_id']) {
            return item;
          }
        });

        selectedData['subID'] = response.data.subid;
        currentBets.push(selectedData);
      } else {

        if (successBet) {
          const game = response.data.data.game;
          const key = Object.keys(game);
          appContext.betsData.get.filter((el) => key.indexOf(el.game_id + '') === -1).forEach((el) => {
            el['status'] = 'failed'
            return true
          })
        } else {
          appContext.betsData.get.forEach(item => {
            item['status'] = 'failed';
          });
        }
      }
      setBetsData(currentBets);
      setSelectedPriceData(selectedData);
    }
  }

  const getContent = () => {
    return dataIsEmpty()
      ? matchIsAlreadyRunningInfo()
      : <div className='basic-market-container'>
        <div className='basic-market-list'>
          {
            getMarketData()
          }
        </div>
      </div>
  }

  return <div className='basic-market'>
    <UpcomingGames gameTimesData={gameTimesData} marketDataParser={parseMarketData}
                   updateMarketData={updateMarketData}/>
    {getContent()}
  </div>
}

export default BasicMarket;