import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './PlayerSlider.scss'

export default function PlayerSlider({changeScience}) {
  const baseURL = process.env.PUBLIC_URL + './assets/tennis/';

  const players = [
    {id: 1, name: 'danel_right'},
    {id: 2, name: 'noval'},
    {id: 3, name: 'noval'},
    {id: 4, name: 'noval'},
    {id: 5, name: 'noval'},
    {id: 6, name: 'noval'},
    {id: 7, name: 'noval'},
    {id: 8, name: 'noval'},
    {id: 9, name: 'noval'},
    {id: 10, name: 'noval'}
  ];
  const settings = {
    dots: false,
    speed: 300,
    slidesToScroll: 3,
    className: "center players-slider",
    centerMode: true,
    centerPadding: "10px",
    slidesToShow: 1
  };
  const close = () => {

  }
  const getPlayerBlock = (item, index) => {
    return <div className='player-block-inst' key={index}>
      <img className='player-icon' src={baseURL + 'left/' + 'noval_left.png'} alt={''}/>
      <img className='player-icon' src={baseURL + 'temp/' + 'noval.png'} alt={''}/>
      <div className="name">NOVAL DJAKOVIC</div>
    </div>
  }

  return (
    <>
      <Slider {...settings} >
        {players.map((item, index) => getPlayerBlock(item, index))}
      </Slider>
      <div className="footer">

        <div className="button play-button">PLAY</div>
        <div className="button random-button" onClick={() => changeScience('back')}>Close</div>
      </div>
    </>

  );
}