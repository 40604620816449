import React, {memo, useContext, useEffect, useRef, useState} from 'react';
import './Keyboard.scss';
import Utils from "../../utils/Utils";
import {useLocalStorage} from "@rehooks/local-storage";
import {AppContext} from "../../App";

function Keyboard({changeCallBack}) {
  const [editMode, setEditMode] = useState(false);
  const [userBets] = useLocalStorage('userBets');
  const [lastIsOption, setLastIsOption] = useState(false);

  const context = useContext(AppContext);
  const activeInput = context.activeStakeInput.get;
  const minBetValue = context.partnerConfig.get['min_bet_stakes'][context.currency.get];
  const options = useRef(userBets && userBets.length ? [...userBets] : [
    (minBetValue * 3).toFixed(2).replace(/\.00$/, ''),
    (minBetValue * 5).toFixed(2).replace(/\.00$/, ''),
    (minBetValue * 10).toFixed(2).replace(/\.00$/, '')
  ]);

  const firstOptionInput = useRef();
  const secondOptionInput = useRef();
  const lastOptionInput = useRef();

  const activeOptionInput = useRef();

  useEffect(() => {
    if (editMode) {
      activeOptionInput.current = firstOptionInput.current;
    }
  }, [editMode])

  const handleKeyPress = (key, force) => {
    const input = editMode ? activeOptionInput.current : activeInput;

    if (input) {
      const pointIndex = input.value.indexOf('.');
      const maxLength = 12;

      if (pointIndex !== -1 && key === '.' || (input.value.length >= maxLength && !force) ) {
        return;
      }

      if (pointIndex !== -1 && input.value.length > pointIndex + 2 && force === false) {
        return;
      }

      if (force) {
        setLastIsOption(true);
      } else if (lastIsOption) {
        force = true;
        setLastIsOption(false);
      }

      input.value = force ? key : input.value + key;

      if (input.value === '.') {
        input.value = '0.';
      }

      if (editMode === false) {
        input.value = Utils.validInputValue(input.value)
        changeCallBack(input.value);
        input.dispatchEvent(new CustomEvent('change', {detail: input.value}));
      }
    }
  }

  const removeLastChar = () => {
    const input = editMode ? activeOptionInput.current : activeInput;

    if (input && input.value) {
      input.value = input.value.substring(0, input.value.length - 1);

      if (editMode === false) {
        changeCallBack(input.value);
        input.dispatchEvent(new CustomEvent('change', {detail: input.value}));
      }
    }
  }

  const manageEditMode = () => {
    if (editMode) {
      options.current = [
        Number(firstOptionInput.current.value) || Number(firstOptionInput.current.placeholder),
        Number(secondOptionInput.current.value) || Number(secondOptionInput.current.placeholder),
        Number(lastOptionInput.current.value) || Number(lastOptionInput.current.placeholder)
      ];

      Utils.writeStorage('userBets', options.current, 3600);
    }

    setEditMode(!editMode);
  }

  return activeInput ? <div className='keyboard'>
    <div className="button" onClick={() => handleKeyPress(1)}>1</div>
    <div className="button" onClick={() => handleKeyPress(2)}>2</div>
    <div className="button" onClick={() => handleKeyPress(3)}>3</div>
    {
      editMode
        ? <input type="text" ref={firstOptionInput} placeholder={options.current[0]} readOnly
                 onClick={e => activeOptionInput.current = e.currentTarget} defaultValue={options.current[0]}/>
        : <div className="button" onClick={() => handleKeyPress(options.current[0], true)}>{options.current[0]}</div>
    }
    <div className="button" onClick={() => handleKeyPress(4)}>4</div>
    <div className="button" onClick={() => handleKeyPress(5)}>5</div>
    <div className="button" onClick={() => handleKeyPress(6)}>6</div>
    {
      editMode
        ? <input type="text" placeholder={options.current[1]} readOnly ref={secondOptionInput}
                 onClick={e => activeOptionInput.current = e.currentTarget} defaultValue={options.current[1]}/>
        : <div className="button" onClick={() => handleKeyPress(options.current[1], true)}>{options.current[1]}</div>
    }
    <div className="button" onClick={() => handleKeyPress(7)}>7</div>
    <div className="button" onClick={() => handleKeyPress(8)}>8</div>
    <div className="button" onClick={() => handleKeyPress(9)}>9</div>
    {
      editMode
        ? <input type="text" placeholder={options.current[2]} readOnly ref={lastOptionInput}
                 onClick={e => activeOptionInput.current = e.currentTarget} defaultValue={options.current[2]}/>
        : <div className="button" onClick={() => handleKeyPress(options.current[2], true)}>{options.current[2]}</div>
    }
    <div className="button" onClick={() => handleKeyPress('.')}>.</div>
    <div className="button" onClick={() => handleKeyPress(0)}>0</div>
    <div className="button" onClick={removeLastChar}>
      <img className='backspace' src={process.env.PUBLIC_URL + './assets/icons/backspace.svg'} alt=""/>
    </div>
    <div className="button" onClick={manageEditMode}>
      {
        editMode ? <div>✔</div> :
          <img className="edit" src={process.env.PUBLIC_URL + './assets/icons/edit.png'} alt=""/>
      }
    </div>
  </div> : null
}

export default memo(Keyboard);