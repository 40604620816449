import React, {useContext, useEffect, useState} from 'react';
import {MARKET_DATA, UNSUBSCRIBE} from "../../../services/RequestDatas";
import RequestManager from "../../../services/RequestManager";
import LargeMarket from "../LargeMarket/LargeMarket";
import {AppContext} from "../../../App";
import {useTranslation} from "react-i18next";

function LeagueMenuTab({info, gameID}) {
  const {t} = useTranslation();
  const appContext = useContext(AppContext);
  const [opened, setOpened] = useState(false);
  const [leagueData, setLeagueData] = useState(null);
  const webSocket = RequestManager.getInstance().webSocket;
  const activeSportData = appContext.sportsData.get.find(data => data.id === 7);
  const iconsBaseURL = process.env.PUBLIC_URL + './assets/flags/';

  useEffect(() => {
    setOpened(false);
  }, [info]);

  const openRoundInfo = () => {
    if (!opened) {
      initMarketRequest();
    } else {
      setOpened(opened => !opened);
    }
  }

  const initMarketRequest = () => {
    const sportID = activeSportData.id;
    const regionID = activeSportData.region_id;

    MARKET_DATA.params.where.game.id = parseInt(gameID);
    MARKET_DATA.params.where.sport.id = parseInt(sportID);
    MARKET_DATA.params.where.region.id = parseInt(regionID);

    webSocket.send(JSON.stringify(MARKET_DATA));
    webSocket.addEventListener(RequestManager.MARKET_DATA_EVENT, parseMarketData, {once: true});
  }

  const parseMarketData = ({detail}) => {
    const responseData = detail.data.data;

    if (MARKET_DATA.subID) {
      unsubscribe(MARKET_DATA.subID);
    }
    MARKET_DATA.subID = detail.data.subid;

    if (responseData && (Object.keys(responseData['sport']).length)) {
      setLeagueData(responseData);
    } else {
      setLeagueData(null);
    }

    setOpened(opened => !opened);
  }

  const unsubscribe = (subId) => {
    UNSUBSCRIBE.params.subid = subId;
    if (subId) {
      webSocket.send(JSON.stringify(UNSUBSCRIBE));
    }
  }

  const getMarket = () => {
    return leagueData ?
      <div className={opened ? 'league-market-content' : 'league-market-content-hidden'}>
        <LargeMarket isFootballLeague={true} marketExternalData={leagueData}/>
      </div>
      : null;
  }

  const iconName0 = info['virtual'][0]['PlayerName'].toLowerCase();
  const iconName1 = info['virtual'][1]['PlayerName'].toLowerCase();

  return <div className='league-menu-tab'>
    <div className='league-menu-tab-title' onClick={() => openRoundInfo()}>
      <p>
        <span className="team-data first">
          {<img className='team-flag' src={iconsBaseURL + iconName0 + '.png'} alt=''/>}
          {t(info['virtual'][0]['PlayerName'])} </span>
        <span className="team-data last">
            {<img className='team-flag' src={iconsBaseURL + iconName1 + '.png'} alt=''/>}
          {t(info['virtual'][1]['PlayerName'])} </span>
      </p>
      <div className={opened ? 'arrow up' : 'arrow down'}/>
    </div>
    {getMarket()}
  </div>
}

export default LeagueMenuTab;