import React, {useContext, useEffect, useState} from 'react';
import './HistoryPopUp.scss';
import Select from "../../Native/Select/Select";
import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import {BET_HISTORY_DATA} from "../../../services/RequestDatas";
import RequestManager from "../../../services/RequestManager";
import HistoryBetItem from "./HistoryBetItem/HistoryBetItem";
import {useTranslation} from "react-i18next";
import {AppContext} from "../../../App";
import {lang} from "../../../i18n";
import Utils from '../../../utils/Utils';


function HistoryPupUp({hideHistoryPopUp}) {
  const {t} = useTranslation();
  const context = useContext(AppContext);
  const socket = RequestManager.getInstance().webSocket;
  const userLoggedIn = context.userLoggedIn.get;
  const iconBaseURL = process.env.PUBLIC_URL + './assets/icons/';
  const periodTypes = [t('custom'), t('1h'), t('2h'), t('3h'), t('6h'), t('12h'), t('24h'), t('48h'), t('72h')];

  const [startDate, setStartDate] = useState(() => Utils.formatPeriodDate(4));
  const [endDate, setEndDate] = useState(() => Utils.formatPeriodDate(0));
  const [showLoader, setShowLoader] = useState(false);
  const [betsList, setBetList] = useState([]);
  const [period, setPeriod] = useState(periodTypes[0]);

  registerLocale("en-GB", lang[context.language.get]);
  setDefaultLocale("en-GB");

  useEffect(() => {
    getHistoryData();
    return () => {
      socket.removeEventListener(RequestManager.BET_HISTORY_EVENT, parseResponse);
    }
  }, []);

  const getHistoryData = () => {
    BET_HISTORY_DATA.params.where.from_date = startDate / 1000;
    BET_HISTORY_DATA.params.where.to_date = endDate / 1000;

    socket.send(JSON.stringify(BET_HISTORY_DATA));
    setShowLoader(showLoader => !showLoader);
    socket.addEventListener(RequestManager.BET_HISTORY_EVENT, (response) => parseResponse(response.detail), {once: true});
  }

  const parseResponse = response => {
    const bets = response.data.bets;
    setBetList(bets);
    setShowLoader(showLoader => !showLoader);
  }

  const changeResultsDate = (date, dateType) => {
    const selectedDateTimeStamp = date.valueOf();

    if (dateType === 'start') {
      setStartDate(selectedDateTimeStamp);
    } else if (dateType === 'end') {
      setEndDate(selectedDateTimeStamp);
    }
  }

  const getBetsList = () => {
    if (!showLoader) {
      if (betsList && !betsList.length) {
        return <p className='empty-history'>{t('history empty')}</p>;
      }

      return <div className='bets-list-container'>
        <div className='bets-list'>
          {
            betsList && betsList.length ? betsList.map((betItemData, index) => {
              return <HistoryBetItem key={index} itemKey={index} itemData={betItemData} oddType={context.coefType.get}/>
            }) : null
          }
        </div>
      </div>
    }
  }

  const preloader = () => !showLoader ? null : <div className='backGround'>
    <div className='video-preloader'/>
  </div>

  const getHistoryHeader = () => {
    return <div className="history-block-header">
      <p>{t('bet history')}</p>
      <div className="close-history-block" onClick={() => hideHistoryPopUp()}>&#x2715;</div>
    </div>
  }

  const handleCalendarStatus = (isOpen, name) => {
    const inp = document.getElementsByClassName(`calendar-input ${name}`)[0];

    inp.blur();
    if (inp?.style) {
      inp.style.pointerEvents = isOpen ? 'none' : 'unset';
    }
  }

  const getPeriodValue = value => {
    const formattedDate = Utils.formatPeriodDate(value);

    setStartDate(formattedDate);
    setEndDate(Utils.formatPeriodDate(0));

    setPeriod(periodTypes[value]);
  }

  const getHistoryContent = () => {
    return <div className='history-block-content'>
      <div className="history-filters">
        <div className="history-filter filter-bet-period">
          <p>{t('period')}</p>
          <div className="custom-select">
            <Select list={periodTypes} onChangeCallback={getPeriodValue}/>
          </div>
        </div>
        {period === periodTypes[0] ?
          <div className="history-filter filter-bet-range">
            <p>{t('range')}</p>
            <div className='filter-calendars'>
              <div className="search-calendar">
                <DatePicker className='calendar-input start' selected={startDate} maxDate={new Date()}
                            onCalendarOpen={() => handleCalendarStatus(true, 'start')}
                            onCalendarClose={() => handleCalendarStatus(false, 'start')}
                            timeFormat="HH:mm" onChange={date => changeResultsDate(date, 'start')}/>
              </div>
              <img className="calendar-icon" src={iconBaseURL + 'calendar.svg'} alt=''/>
            </div>
            <div className='filter-calendars'>
              <div className="search-calendar">
                <DatePicker className='calendar-input end' selected={endDate} maxDate={new Date()}
                            onCalendarOpen={() => handleCalendarStatus(true, 'end')}
                            onCalendarClose={() => handleCalendarStatus(false, 'end')}
                            timeFormat="HH:mm" onChange={date => changeResultsDate(date, 'end')}/>
              </div>
              <img className="calendar-icon" src={iconBaseURL + 'calendar.svg'} alt=''/>
            </div>
          </div> : null
        }
        <div className="show-button"
             onClick={userLoggedIn ? () => getHistoryData() : null}>{t('show').toUpperCase()}</div>
      </div>
      {getBetsList()}
    </div>
  }

  const openSignInPopUp = () => {
    window.parent.postMessage({action: "openSlider", tab: "login"}, '*');
  }

  const openRegistrationPopUp = () => {
    window.parent.postMessage({action: "openSlider", tab: "register"}, '*');
  }

  const getLoginHint = () => {
    if (userLoggedIn) {
      return null;
    }
    const warningURL = process.env.PUBLIC_URL + './assets/icons/warning.png';
    return <div className='login-hint-block'>
      <div className='bet-warning-icon' style={{backgroundImage: `url(${warningURL})`}}/>
      <div className='bet-login-warning'> {t('to place bets')}
        <span className='text-button' onClick={() => openSignInPopUp()}> {t("sign in")}</span>
        <span> {t("or")} </span>
        <span className='text-button' onClick={() => openRegistrationPopUp()}>  {t("register")}</span></div>
    </div>
  }

  return <div className='history-pop-up'>
    {preloader()}
    {getHistoryHeader()}
    {getHistoryContent()}
    {getLoginHint()}
  </div>
}

export default HistoryPupUp;
