import React, {useCallback, useContext, useMemo} from 'react';
import './MarketBlock.scss';
import {AppContext} from "../../App";
import BasicMarket from "./BasicMarket/BasicMarket";
import LargeMarket from "./LargeMarket/LargeMarket";
import LeagueMarket from "./LeagueMarket/LeagueMarket";
import TennisMarket from "./TennisMarket/TennisMarket";
import Utils from "../../utils/Utils";

function MarketBlock({activeGameID, sportsData, addBetItemAnimation}) {
  const appContext = useContext(AppContext);
  const basicMarketGameIDs = [3, 4, 6, 12];
  const activeSportData = sportsData.find(data => data.id === activeGameID);

  const getGamesTimesData = useMemo(() => {
    const gameTimesData = [];

    if (activeSportData) {
      for (let key in activeSportData.game) {
        if (activeSportData.game.hasOwnProperty(key)) {
          const timeData = {};
          timeData.id = key;
          timeData.startTime = activeSportData.game[key] ? activeSportData.game[key]['start_ts'] : 0;

          if (timeData.startTime) {
            gameTimesData.push(timeData)
          }
        }
      }
    }

    return gameTimesData;
  }, [activeGameID, activeSportData, sportsData])

  const setBetsData = useCallback((bets) => {
    const currentBets = [...bets];

    Utils.writeStorage('betsData', currentBets);
    appContext.betsData.set(currentBets);
  }, [activeGameID])

  const content = () => {
    const isVirtualFootballLeague = activeGameID === 7;
    const isVirtualTennis = activeGameID === 14;

    if (isVirtualFootballLeague) {
      return <LeagueMarket/>
    }

    if (isVirtualTennis) {
      return <TennisMarket setBetsData={setBetsData}/>
    }

    return basicMarketGameIDs.indexOf(activeGameID) !== -1
      ? <BasicMarket gameTimesData={getGamesTimesData} setBetsData={setBetsData} addBetItemAnimation={addBetItemAnimation}/>
      : <LargeMarket gameTimesData={getGamesTimesData}/>;
  }

  return <div className="market-block">
    {content()}
  </div>
}

export default MarketBlock;