import React, {useContext, useEffect, useState} from 'react';
import './TennisMarket.scss';
import LargeMarket from "../LargeMarket/LargeMarket";
import RequestManager from "../../../services/RequestManager";
import {MARKET_DATA, TENNIS_DATA, UNSUBSCRIBE} from "../../../services/RequestDatas";
import Utils from "../../../utils/Utils";
import GameInfo from "./GameInfo/GameInfo";
import {AppContext} from "../../../App";

function TennisMarket({setBetsData}) {
  const appContext = useContext(AppContext);
  const socket = RequestManager.getInstance().webSocket;
  const [marketData, setMarketData] = useState(null);
  const [gameData, setGameData] = useState(null);
  const activeSportData = appContext.sportsData.get.find(data => data.id === 14);

  useEffect(() => {
    return () => {
      UNSUBSCRIBE.params.subid = TENNIS_DATA.subID;
      socket.send(JSON.stringify(UNSUBSCRIBE));

      UNSUBSCRIBE.params.subid = MARKET_DATA.subID;
      socket.send(JSON.stringify(UNSUBSCRIBE));
    }
  }, [])

  useEffect(() => {
    socket.send(JSON.stringify(TENNIS_DATA));
    socket.addEventListener(RequestManager.TENNIS_DATA_EVENT, response => parseData(response.detail), {once: true});
  }, [appContext.tennisUpdateState.get, appContext.tennisForceUpdate.get])

  useEffect(() => {
    if (appContext.tennisData.get && appContext.tennisData.get.Type === 4) {
      updateTennisStatus(4);
    }
  }, [appContext.tennisData.get]);

  const getMarket = () => {
    const playerNamesList = [];
    if (gameData) {
      playerNamesList.push(gameData['team1_name'] ? gameData['team1_name'].split(' ')[0] : '');
      playerNamesList.push(gameData['team2_name'] ? gameData['team2_name'].split(' ')[0] : '');
    }
    return marketData ? <LargeMarket setBetsData={setBetsData} isTennis={true}
                                     marketDisabled={appContext.tennisUpdateState.get === 12}
                                     marketExternalData={marketData} playerNamesList={[...playerNamesList]}/> : null;
  }

  const parseData = res => {
    const response = res.data.data.game;
    TENNIS_DATA.subID = res.data.subid;

    if (response) {
      const firstKey = Utils.firstKey(response);
      const data = response[firstKey];

      initMarketRequest(data.id);
      setGameData(data);
    }
  }

  const getGameDataBlock = () => {
    return gameData ? <GameInfo gameData={gameData}/> : null;
  }

  const initMarketRequest = gameID => {
    MARKET_DATA.params.where.game.id = parseInt(gameID);

    const sportID = activeSportData.id;
    const regionID = activeSportData.region_id;

    MARKET_DATA.params.where.sport.id = parseInt(sportID);
    MARKET_DATA.params.where.region.id = parseInt(regionID);

    socket.send(JSON.stringify(MARKET_DATA));
    socket.addEventListener(RequestManager.MARKET_DATA_EVENT, response => parseMarketData(response.detail), {once: true});
    socket.addEventListener(RequestManager.AUTO_TENNIS_UPDATE, response => parseMarketUpdate(response.detail), {once: true});
    socket.addEventListener(RequestManager.AUTO_REMOVE_TENNIS_DATA, response => checkTennisUpdate(response.detail));
  }

  const checkTennisUpdate = response => {
    let data;
    // TODO remove current game.
    if (response && Utils.firstKey(response) === 'sport' && JSON.stringify(response).includes('competition')) {

      data = response[Utils.firstKey(response)];
      data = data[Utils.firstKey(data)].region;
      data = data[Utils.firstKey(data)].competition;
      data = data[Utils.firstKey(data)].game;
      data = data[Utils.firstKey(data)].market;
      appContext.deletedTennisMarket.set(data);
    }
  }

  const parseMarketUpdate = response => {
    const updateStateValue = getTennisUpdateState(response);

    if (updateStateValue) {
      updateTennisStatus(updateStateValue);
      appContext.tennisUpdateState.set(updateStateValue);
      appContext.tennisForceUpdate.set(!appContext.tennisForceUpdate.get);
    }
  }

  const updateTennisStatus = (updateStateValue) => {
    let needUpdate = false;
    const newBetsData = [];
    const currentBetsData = Utils.readFromStorage('betsData');

    currentBetsData && currentBetsData.map(item => {
      if (item['stadium_name'] === "Virtual Tennis") {
        item['status'] = updateStateValue === 12 ? 'suspend' : updateStateValue === 13 ? 'success' : updateStateValue === 4 ? 'failed' : 'failed';
        needUpdate = true;
      }
      newBetsData.push(item);
    });

    if (needUpdate) {
      Utils.writeStorage('betsData', newBetsData);
      appContext.betsData.set(newBetsData);
    }
  }

  const getTennisUpdateState = data => {
    const updateKey = 'update_state';
    const stringData = JSON.stringify(data);
    const startIndex = stringData.indexOf(updateKey) + updateKey.length + 2;

    return parseInt(stringData.substring(startIndex, startIndex + 2));
  }

  const parseMarketData = response => {
    MARKET_DATA.subID = response.data.subid;
    const responseData = response.data.data;
    const updateStateValue = getTennisUpdateState(response);
    setMarketData(responseData);
    appContext.tennisUpdateState.set(updateStateValue);
  }

  return <div className='tennis-market'>
    {getGameDataBlock()}
    {getMarket()}
  </div>
}

export default TennisMarket;