import React, {memo, useCallback, useContext, useEffect, useState} from 'react';
import './LargeMarket.scss';
import UpcomingGames from "../UpcomingGames/UpcomingGames";
import MarketTabMenu from "./MarketTabMenu/MarketTabMenu";
import Utils from "../../../utils/Utils";
import LargeMarketElement from "./LargeMarketElement/LargeMarketElement";
import { BET_CHECK_DATA, LOGIN_DATA } from '../../../services/RequestDatas';
import RequestManager from "../../../services/RequestManager";
import {AppContext} from "../../../App";
import {useTranslation} from "react-i18next";


function LargeMarket({
                       gameTimesData,
                       isFootballLeague = false,
                       isTennis,
                       marketDisabled,
                       playerNamesList,
                       marketExternalData,
                     }) {
  const [marketData, setMarketData] = useState([]);
  const [gameId, setGameId] = useState(null);
  const [selectedPriceData, setSelectedPriceData] = useState({});
  const [activeGroupName, setActiveGroupName] = useState('All');
  const [stadiumName, setStadiumName] = useState('');
  const [sportName, setSportName] = useState('');
  const [eventTime, setEventTime] = useState();
  const webSocket = RequestManager.getInstance().webSocket;
  const appContext = useContext(AppContext);
  const {t} = useTranslation();

  useEffect(() => {
    if (appContext.betsData.get.length) setSelectedPriceData({});
  }, [activeGroupName, eventTime, appContext.betsData.get])


  const parseMarketData = (data, isTennisOrLeague = false) => {
    if (Object.keys(data['sport']).length === 0) {
      setMarketData(null);
      return;
    }
    const eventTime = data['event_time'];
    let sport_name = data['sportName'];
    data = data.sport[Utils.firstKey(data.sport)].region;
    data = data[Utils.firstKey(data)].competition;
    const stadiumName = data[Utils.firstKey(data)].name;

    data = data[Utils.firstKey(data)].game;
    data = data[Utils.firstKey(data)];
    if (isTennisOrLeague) {
      sport_name = data['team1_name'] + " VS " + data['team2_name'];
    }

    const marketID = data['id'];

    // Add market elements to array
    data = Object.keys(data['market']).map((key) => {
      return data['market'][key];
    });


    data = data.sort((a, b) => parseFloat(a.order) - parseFloat(b.order));

    // Make sorted market elements array based on "Market Type".
    const createdMarkets = [];
    const sortedData = [];

    data.map((item) => {
      const marketType = item['market_type'];
      const typeIndex = createdMarkets.indexOf(marketType);

      if (typeIndex === -1) {
        if (Object.keys(item['event']).length) {
          createdMarkets.push(marketType);
          sortedData.push(item);
        }
      } else if (typeIndex >= 0) {
        const foundIndex = sortedData.findIndex(element => element.market_type === marketType);
        Object.keys(item.event).map(key => {
          sortedData[foundIndex].event[key] = item.event[key];
        });
      }
    });

    if (JSON.stringify(sortedData) !== JSON.stringify(marketData)) {
      setEventTime(eventTime);
      setMarketData(sortedData);
      setGameId(marketID);
      setStadiumName(stadiumName);
      setSportName(sport_name);
    }
  }

  const changeGroup = useCallback((groupName) => {
    setActiveGroupName(groupName);
  }, [activeGroupName])

  const updateMarketData = (response, gameId) => {
    const responseJson = JSON.stringify(response);

    if (responseJson.includes(gameId) && responseJson.includes('null')) {
      setMarketData(null);
    }
  }

  const groupData = useCallback(() => {
    const groupNames = ['Bookmarks', 'All'];
    const marketIdList = [];
    let groupName, groupIdes;

    marketData && marketData.length && marketData.map((item) => {
      groupName = item['group_name'];
      groupIdes = item['group_id'];
      if (groupName && groupNames.indexOf(groupName) === -1) {
        groupNames.push(groupName);
        marketIdList.push(groupIdes);
      }
    });
    return {names: groupNames, ides: marketIdList}
  }, [marketData, activeGroupName])

  const getElementsGroup = (name) => {
    if (name === 'Bookmarks') {
      const userBookmarkedMarkets = appContext.bookMarks.get[LOGIN_DATA.params.user_id]
      const marketTypeList = (userBookmarkedMarkets && userBookmarkedMarkets[appContext.activeGameID.get])
        ? userBookmarkedMarkets[appContext.activeGameID.get] : [];

      if (marketTypeList?.length) {
        const bookmarkedItems = [];
        for (let element of marketTypeList) {
          const findItem = marketData.filter(item => item['market_type'] === element);
          if (findItem.length) {
            bookmarkedItems.push(findItem[0]);
          }
        }
        return bookmarkedItems;
      }
    }
    return name === 'All' ? marketData : marketData.filter(item => item['group_name'] === name);
  }

  const addRemoveBookmarks = elementData => {
    const marketType = elementData['market_type']
    const gameId = appContext.activeGameID.get;

    const currentUserBookmarks = { ...appContext.bookMarks.get };
    const currentBookmarks = { ...currentUserBookmarks[LOGIN_DATA.params.user_id] };
    const sportBookmarks = currentBookmarks[gameId] || [];
    const marketIndex = sportBookmarks.indexOf(marketType);

    if (marketIndex === -1) {
      sportBookmarks.push(marketType);
    } else {
      sportBookmarks.splice(marketIndex, 1);
    }

    currentBookmarks[gameId] = sportBookmarks;
    currentUserBookmarks[LOGIN_DATA.params.user_id] = currentBookmarks
    appContext.bookMarks.set(currentUserBookmarks);
    Utils.writeStorage('bookmarkedMarkets', currentUserBookmarks, 5400);
  }


  const openSignInPopUp = () => {
    window.parent.postMessage({ action: 'openSlider', tab: 'login' }, '*');
  }

  const selectPrice = (itemData, isActiveItem, marketName, betType) => {
    if (!appContext.userLoggedIn.get) {
      openSignInPopUp()
      return;
    }
    if (isActiveItem) {
      const betsData = appContext.betsData.get;
      const filteredItems = betsData.filter(item => item.id !== itemData.id);

      Utils.writeStorage('betsData', filteredItems, 3600);
      appContext.betsData.set(filteredItems);
      setSelectedPriceData({});
      return;
    }

    const activeGameName = appContext.sportsData.get.find(data => data.id === appContext.activeGameID.get)['name'];
    itemData['stadium_name'] = stadiumName;
    itemData['sportName'] = sportName;
    itemData['event_time'] = eventTime;
    itemData['game_id'] = +gameId;
    itemData['market_name'] = marketName;
    itemData['bet_type'] = betType;
    itemData['game_name'] = activeGameName;

    // Request to check bets before adding to current bets list.
    let eventIDs = [];
    let gameIDs = [];
    let marketIDs = [];

    appContext.betsData.get.map(item => {
      eventIDs.push(parseInt(item['id']));
      gameIDs.push(parseInt(item['game_id']));
      marketIDs.push(parseInt(item['market_id']));
    });

    eventIDs.push(itemData['id']);
    gameIDs.push(itemData['game_id']);
    marketIDs.push(itemData['market_id']);

    BET_CHECK_DATA.params.where.game.id['@in'] = [...new Set(gameIDs)];
    BET_CHECK_DATA.params.where.event.id['@in'] = [...new Set(eventIDs)];
    BET_CHECK_DATA.params.where.market.id['@in'] = [...new Set(marketIDs)];

    webSocket.send(JSON.stringify(BET_CHECK_DATA));
    webSocket.addEventListener(RequestManager.BET_CHECKED_EVENT,
      response => parseBetData(response.detail, itemData), {once: true});

    setSelectedPriceData(itemData);
  }

  const parseBetData = (response, selectedData) => {
    if (response && response.data) {
      let currentBets = [...appContext.betsData.get];
      const successBet = Object.keys(response.data.data.game).length;
      selectedData['subID'] = BET_CHECK_DATA.subID = response.data.subid;

      currentBets = currentBets.filter(item => {
        if (item['game_id'] !== selectedData['game_id']) {
          return item;
        }
      });

      if (!isTennis) {
        selectedData['status'] = successBet ? 'success' : 'failed';
      } else {
        selectedData['status'] = appContext.tennisUpdateState.get === 12 ? 'suspend' : appContext.tennisUpdateState.get === 13 ||
        (appContext.tennisData.get && appContext.tennisData.get.Type === 1) ? 'success' : 'failed';
      }

      currentBets.push(selectedData);
      Utils.writeStorage('betsData', currentBets, 3600);

      appContext.betsData.set(currentBets);
      setSelectedPriceData(selectedData);
    }
  }


  const getMarketData = () => {
    if (isTennis && dataIsEmpty()) {
      return matchIsAlreadyFinishedInfo();
    }

    if (dataIsEmpty()) {
      return matchIsAlreadyRunningInfo();
    }

    const groupedElements = getElementsGroup(activeGroupName);

    return groupedElements.map((item, index) => {
      return <LargeMarketElement elementData={item} key={index}
                                 selectedID={selectedPriceData.id}
                                 selectCallback={selectPrice}
                                 playerNamesList={playerNamesList}
                                 addRemoveBookmarksCallback={addRemoveBookmarks}
                                 isTennis={isTennis}
                                 index={activeGroupName}
      />
    });
  }

  const dataIsEmpty = () => {
    return marketData ? marketData.length === 0 : true
  }

  const getMarketTabs = () => {

    return dataIsEmpty() ? null :
      <MarketTabMenu getElementsGroup={getElementsGroup} activeGroupName={activeGroupName}
                     groupNames={groupData().names}
                     groupIds={groupData().ides}
                     isTennis={isTennis}
                     gameId={gameId}
                     changeGroupCallback={changeGroup}

      />;
  }

  const getTopBlock = () => {
    if (isFootballLeague) {
      return getMarketTabs();
    } else if (isTennis) {
      return getMarketTabs()
    }

    return <div>
      <UpcomingGames gameTimesData={gameTimesData} marketDataParser={parseMarketData}
                     updateMarketData={updateMarketData}/>
      {getMarketTabs()}
    </div>
  }

  const matchIsAlreadyRunningInfo = () => {
    return <p className='match-running-info'>{t('match running')}</p>
  }

  const matchIsAlreadyFinishedInfo = () => {
    return <p className='match-running-info'>Starting new game</p>
  }

  if (marketExternalData) {
    parseMarketData(marketExternalData, isTennis);
  }

  return <div className={marketDisabled ? 'large-market disable' : 'large-market'}>
    {getTopBlock()}
    <div className='large-market-container'>
      {getMarketData()}
    </div>
  </div>
}

export default memo(LargeMarket);