import React, { useContext, useEffect, useRef, useState } from 'react';
import './MiniBetSlip.scss';
import { useTranslation } from 'react-i18next';
import Keyboard from '../../Keyboard/Keyboard';
import { AppContext } from '../../../App';
import Utils from '../../../utils/Utils';
import { MAX_BET_DATA, PLACE_BETS_DATA, RE_CAPTCHA_DATA, RE_CAPTCHA_MAX_DATA } from '../../../services/RequestDatas';
import RequestManager from '../../../services/RequestManager';

function MiniBetSlip({ hideMiniBetSlip, addBetItemAnimationEnd }) {
  const { t } = useTranslation();
  const context = useContext(AppContext);
  const itemData = context.betsData.get[0];
  const socket = RequestManager.getInstance().webSocket;

  const currency = context.currency.get;
  const minBetValue = context.partnerConfig.get['min_bet_stakes'][currency];

  const [ betValue, setBetValue ] = useState(0);
  const [ warnings, setWarnings ] = useState([]);
  const [ placeProcess, setPlaceProcess ] = useState(false);
  const [ showSuccess, setShowSuccess ] = useState(false);
  const [ showKeyboard, setShowKeyboard ] = useState(false);
  const [ showTicketAnimation, setShowTicketAnimation ] = useState(false);

  const inputRef = useRef(null);
  const stakeInput = context.activeStakeInput.get;


  useEffect(() => {
    context.betsData.get[0]['mini_bet_slip_value'] = betValue;
    context.activeStakeInput.set(inputRef.current);
  }, [])

  useEffect(() => {
    if (context.currency.get) {
      console.log(context.currency.get)
      setBetValue(minBetValue * 2);
    }
  }, [context.currency.get])

  useEffect(() => {
    if (warnings.length) {
      setTimeout(() => setWarnings([]), 2500);
    }
  }, [ warnings ])

  useEffect(() => {
    if (showSuccess) {
      setTimeout(clearBets, 4000);
    }
  }, [ showSuccess ])

  const getSingleBetItem = () => {
    if (!itemData) {
      return;
    }

    const price = itemData['price'] && itemData['price'].toString().substring(0, 4);
    const currency = context.userLoggedIn.get ? context.currency.get : context.partnerConfig.get['currency'];

    return <div className="bet-item">
      <div className="bet-item-header">
        <p className="bet-stadium-name">{ itemData['sportName'] ? itemData['sportName'] : itemData['stadium_name'] }</p>
        <p className="bet-time">{ itemData['event_time'] }</p>
        <div className="remove-item" onClick={ clearBets }>&#10006;</div>
      </div>
      <div className="bet-type">{ itemData['game_name'] + ' / ' + itemData['bet_type'] }</div>
      <div className="price-info">
        <p className="bet-player-name">{ itemData['type'] + ' ' + (itemData['base'] ? itemData['base'] : '') }</p>
        <p className="info price">{ t('odd') + ': ' } <span>{Utils.getFormattedCoef(itemData['price'], context.coefType.get)}</span></p>
      </div>
      <div className="potential-winning-container">
        <p className="potential-win-title">{ t('possible win') }:</p>
        <p className="potential-win-value">{ (price * Number(betValue)).toFixed(2) + ' ' + currency }</p>
      </div>
    </div>
  }

  const activateInput = e => {
    context.activeStakeInput.set(e.currentTarget);

    if (showKeyboard === false) {
      setShowKeyboard(true);
    }
  }

  const doubleUp = () => {
    if (stakeInput && stakeInput.value) {
      stakeInput.value = Number(stakeInput.value) * 2;
      updateBetValue(stakeInput.value);
    }
  }

  const divideValue = () => {
    if (stakeInput && stakeInput.value && (stakeInput.value / 2 >= minBetValue)) {
      stakeInput.value = Number(stakeInput.value) / 2;
      updateBetValue(stakeInput.value);
    }
  }

  const getWarnings = () => {
    return warnings.length ? <div className="warning">
      {
        warnings[0]
      }
    </div> : null
  }

  const handleReCaptchaScriptLoaded = () => {
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(context.reCaptchaData.get.siteKey,
        { action: 'do_bet' }).then(token => RE_CAPTCHA_DATA.params.g_recaptcha_response = token);
    })

    socket.send(JSON.stringify(RE_CAPTCHA_DATA));
    socket.addEventListener(RequestManager.RE_CAPTCHA_EVENT, placeBet, { once: true });
  }

  const initReCaptchaScript = () => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?render=' + context.reCaptchaData.get.siteKey;
    script.addEventListener('load', handleReCaptchaScriptLoaded);

    document.body.appendChild(script);
  }

  const checkRecaptcha = () => {
    const reCaptchaData = context.reCaptchaData.get;

    if (reCaptchaData && reCaptchaData['version'] === 3) {
      initReCaptchaScript();
    } else {
      placeBet();
    }
  }

  const placeBet = () => {
    PLACE_BETS_DATA.params.type = 1;
    PLACE_BETS_DATA.params.amount = stakeInput.value;
    PLACE_BETS_DATA.params.sys_bet = 0;
    PLACE_BETS_DATA.params.bets = [ { event_id: context.betsData.get[0].id, price: context.betsData.get[0].price } ];

    socket.send(JSON.stringify(PLACE_BETS_DATA));
    socket.addEventListener(RequestManager.PLACE_BETS_EVENT,
      response => handlePlaceBetsResponse(response.detail), { once: true });
  }

  const handlePlaceBetsResponse = ({ data }) => {
    setPlaceProcess(false);

    const successfulBet = data.result && data.result === 'OK';
    const resultText = data['result_text'];

    if (successfulBet) {
      setShowSuccess(true);
    } else {
      setWarnings([ t(resultText) ]);
    }
  }

  const clearBets = () => {
    Utils.writeStorage('betsData', []);
    context.betsData.set([]);
  }

  const prepareToPlaceBet = () => {
    // CHECK MIN BET CASE
    const currency = context.currency.get;
    const minBetValue = context.partnerConfig.get['min_bet_stakes'][currency];
    const betValue = stakeInput.value;

    if (betValue < minBetValue) {
      const minBetWarning = `${ t('min bet case') + ' (' + minBetValue + currency + ')' }`;
      setWarnings([ minBetWarning ]);
    } else {
      setPlaceProcess(true);
      checkRecaptcha();
    }
  }

  const prepareToGetMaxBetValue = () => {
    const reCaptchaData = context.reCaptchaData.get;
    if (reCaptchaData && reCaptchaData['version'] === 3) {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js?render=' + context.reCaptchaData.get.siteKey;
      script.addEventListener('load', handleMaxReCaptchaScriptLoaded);

      document.body.appendChild(script);
    } else {
      getMaxBetValue();
    }
  }

  const handleMaxReCaptchaScriptLoaded = () => {
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(context.reCaptchaData.get.siteKey, { action: 'get_max_bet' }).then(token => RE_CAPTCHA_MAX_DATA.params.g_recaptcha_response = token)
    })

    socket.send(JSON.stringify(RE_CAPTCHA_MAX_DATA));
    socket.addEventListener(RequestManager.RE_CAPTCHA_MAX_EVENT, getMaxBetValue, { once: true });
  }

  const getMaxBetValue = () => {
    MAX_BET_DATA.params.type = 1;
    MAX_BET_DATA.params.events = [ context.betsData.get[0].id ];

    socket.send(JSON.stringify(MAX_BET_DATA));
    socket.addEventListener(RequestManager.MAX_BET_EVENT, response => updateMaxBetValue(response.detail), { once: true });
  }

  const updateBetValue = value => {
    value = Utils.validInputValue(value);

    setBetValue(value);
    context.betsData.get[0]['mini_bet_slip_value'] = value;
  }

  const updateMaxBetValue = ({ data }) => {
    if (data && data.details.amount) {
      stakeInput.value = Number(data.details.amount);
      updateBetValue(stakeInput.value);
    }
  }

  const getMinBetValue = () => {
    stakeInput.value = Number(minBetValue);
    updateBetValue(stakeInput.value);
  }

  const minusMin = () => {
    if (stakeInput.value > minBetValue) {
      stakeInput.value -= Number(minBetValue);
      updateBetValue(stakeInput.value);
    }
  }

  const plusMin = () => {
    stakeInput.value = Number(Number(stakeInput.value) + minBetValue);
    updateBetValue(stakeInput.value);
  }

  const openSignInPopUp = () => {
    window.parent.postMessage({ action: 'openSlider', tab: 'login' }, '*');
  }

  const openRegistrationPopUp = () => {
    window.parent.postMessage({ action: 'openSlider', tab: 'register' }, '*');
  }

  return showSuccess
    ? <div className="mini-bet-slip">
      <div className="success">
        { t('bet accepted') }
      </div>
    </div>
    : <div className="mini-bet-slip">
      { getSingleBetItem() }
      <div className="stake-block">

        <div className="stake-block-content">
          <div className="stake-button max-button" onClick={ getMinBetValue }>Min</div>
          <div className="stake-button" onClick={ divideValue }>1/2</div>
          <div className="stake-button minus" onClick={ minusMin }>-</div>
          <input type="text" ref={ inputRef } placeholder={ t('stake') } readOnly onClick={ activateInput }
                 value={ betValue }/>
          <div className="stake-button plus" onClick={ plusMin }>+</div>
          <div className="stake-button" onClick={ doubleUp }>x2</div>
          <div className="stake-button min-button" onClick={ prepareToGetMaxBetValue }>Max</div>
        </div>

      </div>
      {
        context.userLoggedIn.get ? null : <div className="login-hint">
          <div className="bet-login-warning"> { t('to place bets') }
            <span className="text-button" onClick={ openSignInPopUp }> { t('sign in').toUpperCase() }</span>
            <span> { t('or') } </span>
            <span className="text-button"
                  onClick={ openRegistrationPopUp }>  { t('register').toUpperCase() }</span></div>
        </div>
      }
      <div className="place-bet-container">
        {
          context.userLoggedIn.get
            ? <div onClick={ () => prepareToPlaceBet() } className={ betValue ? 'place-bet' : 'place-bet disable' }>
              { t('place bet').toUpperCase() }
            </div> : null
        }

        {
          context.userLoggedIn.get
            ? <div className="keep-in" onClick={ () => {
              hideMiniBetSlip();
              setShowTicketAnimation(true)
            } }>
              <div className="keep-in-container">
                <img src={ process.env.PUBLIC_URL + './assets/icons/betslip.svg' } alt=""/>
                { showTicketAnimation ? <img className="hover"
                                             onAnimationEnd={ addBetItemAnimationEnd }
                                             src={ process.env.PUBLIC_URL + './assets/icons/betslip.svg' }
                                             alt=""/> : null }
                <p>+</p>
              </div>
            </div> : null
        }
      </div>
      { getWarnings() }
      {
        showKeyboard ? <div className="keyboard-container">
          <Keyboard activeInput={ stakeInput } changeCallBack={ value => updateBetValue(value) }/>
        </div> : null
      }
      { placeProcess ? <div className="place-process">
        <div className="loader"/>
      </div> : null }
    </div>
}

export default MiniBetSlip;