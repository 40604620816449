import React, {useContext, useState} from 'react';
import './GameInfo.scss';
import PlayerStatistics from "./PlayerStatistic";
import {AppContext} from "../../../../App";
import NextPointTimer from "./NextPointTimer";

function GameInfo({gameData}) {
  const [selectedPlayerIndex, setSelectedPlayerIndex] = useState(null);
  const appContext = useContext(AppContext);
  const iconBaseURL = process.env.PUBLIC_URL + './assets/icons/';
  const flagsBaseURL = process.env.PUBLIC_URL + './assets/flags/';
  const imgBaseURL = process.env.PUBLIC_URL + './assets/tennis/';
  const iconURL1 = iconBaseURL + (selectedPlayerIndex === 0 ? 'statistic_2.png' : 'statistic.png');
  const iconURL2 = iconBaseURL + (selectedPlayerIndex === 1 ? 'statistic_2.png' : 'statistic.png');
  const ballIcon = iconBaseURL + 'ball.png'
  const player1Data = gameData['info']['virtual'][0];
  const player2Data = gameData['info']['virtual'][1];

  const changeSelectedPlayer = index => {
    if (index === selectedPlayerIndex) {
      setSelectedPlayerIndex(null);
      return;
    }
    setSelectedPlayerIndex(index);
  }

  const getScoreInfo = () => {
    const player = appContext.tennisData.get;

    if (!player) return;

    const maxScore = 40;
    let gameScoreHome = player['GameScore'] && player['TieBreakScore'] ? player['GameScore']['Home'] + player['TieBreakScore']['Home'] : 0;
    let gameScoreAway = player['GameScore'] && player['TieBreakScore'] ? player['GameScore']['Away'] + player['TieBreakScore']['Away'] : 0;

    const firstName1 = player['Player1Name'] ? player['Player1Name'].split(" ")[0] : '';
    const firstName2 = player['Player2Name'] ? player['Player2Name'].split(" ")[0] : '';

    if (gameScoreHome > maxScore) {
      gameScoreHome = 'AD';
      gameScoreAway = '';
    } else if (gameScoreAway > maxScore) {
      gameScoreAway = 'AD';
      gameScoreHome = '';
    }

    return (
      player && Object.keys(player).length ?
        <div className='score-info'>
          <div className='score-item'>
            <div className='player-info-row'>
              <div className='player-item pointer' onClick={() => changeSelectedPlayer(0)}>
                <img src={flagsBaseURL + player1Data['AnimalName'].toLowerCase() + '.png?d=' + Date.now()} alt=''/>
                <div className='player-name'>
                  {player1Data['PlayerName'].split(' ')[0][0] + '. '}{player1Data['PlayerName'].split(' ')[1]}</div>
                <div className='player-img-small'
                     style={{backgroundImage: `url(${imgBaseURL + firstName1.toLowerCase() + '.png'})`}}/>
                <img className="statistic-icon" src={iconURL1} alt='' onClick={() => changeSelectedPlayer(0)}/>
              </div>
              <div className='matchScore-ball'
                   style={{backgroundImage: !player['Player'] && player['MatchScore'] ? ` url('${ballIcon}')` : null}}/>
            </div>
            {
              player['MatchScore'] && player['SetScore'] && player['GameScore'] ?
                <div className='scores'>
                  <div className='score first'>
                    {player['MatchScore'] ? player['MatchScore']['Home'] : ''}</div>
                  <div className='score'>{player['SetScore'] ? player['SetScore']['Home'] : ''}</div>
                  <div className='score'>{gameScoreHome}</div>
                </div>
                : null
            }
          </div>
          <div className='score-item'>
            <div className='player-info-row'>
              <div className='player-item pointer' onClick={() => changeSelectedPlayer(1)}>
                <img src={flagsBaseURL + player2Data['AnimalName'].toLowerCase() + '.png?d=' + Date.now()} alt=''/>
                <div
                  className='player-name'>{player2Data['PlayerName'].split(' ')[0][0] + '. '}{player2Data['PlayerName'].split(' ')[1]}</div>
                <div className='player-img-small'
                     style={{backgroundImage: `url(${imgBaseURL + firstName2.toLowerCase() + '.png'})`}}/>

                <img className="statistic-icon" src={iconURL2} alt='' onClick={() => changeSelectedPlayer(1)}/>
              </div>
              <div className='matchScore-ball'
                   style={{backgroundImage: player['Player'] && player['MatchScore'] ? ` url('${ballIcon}')` : null}}/>
            </div>
            {
              player['MatchScore'] && player['SetScore'] && player['GameScore'] ?
                <div className='scores'>
                  <div className='score first'>
                    {player['MatchScore'] ? player['MatchScore']['Away'] : ''}</div>
                  <div className='score'>{player['SetScore'] ? player['SetScore']['Away'] : ''}</div>
                  <div className='score'>{gameScoreAway}</div>
                </div> : null}
          </div>
        </div> : null)

  }

  const getPlayerStatistic = () => {
    const playerData = gameData['info']['virtual'][selectedPlayerIndex];
    return selectedPlayerIndex === null ? null : <PlayerStatistics playerData={playerData}/>
  }

  return <div className='game-data-block'>
    <NextPointTimer/>
    <div className='game-date-header'>
      {getScoreInfo()}
    </div>
    {getPlayerStatistic()}
  </div>
}

export default React.memo(GameInfo)