import React, {useContext, useState} from 'react';
import Utils from "../../../../utils/Utils";
import {AppContext} from "../../../../App";
import { LOGIN_DATA } from '../../../../services/RequestDatas';

function LargeMarketElement({
                              isTennis,
                              elementData,
                              index,
                              playerNamesList,
                              selectedID,
                              selectCallback,
                              addRemoveBookmarksCallback,
                            }) {

  const [isOpen, setIsOpen] = useState(true);
  const marketListData = elementData['event'];
  const marketName = elementData['name'];
  const appContext = useContext(AppContext);
  const elementKeys = Object.keys(marketListData);
  const showCashOutIcon = elementData['cashout'];
  const pricesClasses = isOpen ? 'large-market-element-item-prices' : 'large-market-element-item-prices closed';
  const types = [];
  let currentType;

  const userBookmarkedMarkets = appContext.bookMarks.get[LOGIN_DATA.params.user_id]
  const bookmarkedMarkets = userBookmarkedMarkets && userBookmarkedMarkets[appContext.activeGameID.get];
  const isBookmarked = bookmarkedMarkets?.includes(elementData['market_type']);
  const bookmarkClasses = isBookmarked ? 'start-bookmark bookmarked' : 'start-bookmark own';


  // Get all types from data.
  elementKeys.map(key => {
    currentType = marketListData[key]['type_1'];

    if (types.indexOf(currentType) === -1) {
      types.push(currentType);
    }
  });

  const openCloseElement = () => {
    setIsOpen(!isOpen);
  }

  const getCashOutIcon = () => {
    return showCashOutIcon ? <div className="cash-out-icon">&#36;</div> : null;
  }

  const getInfoItemStyle = (isLastItem) => {
    const multiTypes = types.length >= 3;
    const count = multiTypes ? 2 : types.length;
    let percentValue = 100 / count;

    if (isLastItem && multiTypes && elementKeys.length % 2 === 1) {
      percentValue = 100;
    }
    return `0 1 calc(${percentValue}% - 1.2px)`;
  }

  const addRemoveToBookmarks = (event, elementData) => {
    event.preventDefault();
    event.stopPropagation();
    addRemoveBookmarksCallback(elementData);
  }

  const getFormattedPrice = (price) => {
    return Utils.getFormattedCoef(price, appContext.coefType.get);
  }

  const getMarketItems = () => {

    return elementKeys.map(function (key, index) {
      const currentListItem = marketListData[key];
      const price = getFormattedPrice(currentListItem['price']);
      const betType = elementData.name;

      const name = playerNamesList && playerNamesList.length ?
        currentListItem.name.includes(playerNamesList[0]) ?
          currentListItem.name.replace(playerNamesList[0], playerNamesList[0][0] + '. ') :
          currentListItem.name.includes(playerNamesList[1]) ?
            currentListItem.name.replace(playerNamesList[1], playerNamesList[1][0] + '. ') :
            currentListItem.name : currentListItem.name;
      const isLastItem = index === elementKeys.length - 1;
      const isSelectedBefore = appContext.betsData.get.filter((el) => el.id === currentListItem.id);
      const isActiveItem = selectedID && selectedID === currentListItem.id || isSelectedBefore.length;
      let className = 'large-market-info-item list-info-item price';
      className += isActiveItem ? ' active' : '';

      return <div className={className} style={{flex: getInfoItemStyle(isLastItem)}} key={index}
                  onClick={isTennis ? appContext.tennisUpdateState.get !== 12 ?
                    () => selectCallback(currentListItem, isActiveItem, marketName, betType) :
                    null : () => selectCallback(currentListItem, isActiveItem, marketName, betType)}>
        <p className='large-market-info-item-type'>{name} {currentListItem.base}</p>
        <p className='large-market-info-item-price'>{price}</p>
      </div>;
    })
  }

  return <div className='large-market-element' key={index}>
    <div className='large-market-element-item list-title' onClick={openCloseElement}>
      {playerNamesList && playerNamesList.length ?
        elementData.name.includes(playerNamesList[0]) ?
          elementData.name.replace(playerNamesList[0], playerNamesList[0][0] + '. ') :
          elementData.name.includes(playerNamesList[1]) ?
            elementData.name.replace(playerNamesList[1], playerNamesList[1][0] + '. ') :
            elementData.name :
        elementData.name}
      <div className='market-element-options'>
        {getCashOutIcon()}
        {appContext.userLoggedIn.get && (
          <span className={bookmarkClasses} onClick={e => addRemoveToBookmarks(e, elementData)}>&#9733;</span>
        )}
          <span className={isOpen ? 'market-item-open-close-arrow up' : 'market-item-open-close-arrow down'}/>


      </div>
    </div>
    <div className={pricesClasses}>
      {getMarketItems()}
    </div>
  </div>
}

export default React.memo(LargeMarketElement);